import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Stopwatch from "../../components/StopWatch";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetPatientsCarePlanMutation } from "../../app/api/patientsApi";
import { useUpdateCarePlanMutation } from "../../app/api/patientsApi";
import classNames from "classnames";
import SelectComponent from "../../components/SelectComponent";
import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";
import Download from "./DownloadCareTeam";
import { generalHandleValueChanges } from "./helperfunctions";
import CareBodyComponent from "./CareBodyComponent";
import FloatingNotifications from "../../utilities/FloatingNotifications";
import PatientInteractionPage from "../../schedule/patientInteractionSelection/PatientInteractionPage";
import "../../sass/scrollBar.scss";
import "./CareTeam.scss";

const CareTeam = () => {
  const navigate = useNavigate();

  const DownloadPDF = () => {
    // ReactPDF.render(<Download />, `example.pdf`);
    // ReactPDF.renderToStream(<Download />);
    // ReactPDF.render(Download);
    ReactPDF.renderToStream(<Download />);
  };
  const [callPatientPopUp, setCallPatientPopUp] = useState(false);

  const location = useLocation();
  const id = location?.state?.id;

  const [selectedPatient, setSelectedPatient] = useState();
  const [carePlanCallFn, { data: Patientdetails }] =
    useGetPatientsCarePlanMutation();

  const [timerNotification, setTimerNotification] = useState(true);
  const [startTimer, setStartTimer] = useState(false);
  const [programName, setProgramName] = useState({
    programName: "",
  });
  const [updateDatafn, { data: updatedData }] = useUpdateCarePlanMutation();

  const updateFunction = (value) => {
    updateDatafn(value);
    carePlanCallFn({
      PatientId: id,
    });
  };
  const goToCallingPage = () => {
    // navigate("/patients/careplanCall", { state: { id: id } });
    setCallPatientPopUp(true);
  };

  useEffect(() => {
    carePlanCallFn({
      PatientId: id,
    });
  }, [carePlanCallFn, id]);

  const [totalTime, setTotalTime] = useState(
    selectedPatient?.TotalTime ?? "00:00:00"
  );
  useEffect(() => {
    if (selectedPatient) {
      setTotalTime(selectedPatient?.TotalTime);
    }
  }, [selectedPatient]);
  // const [data,setData]=useState({ "PatientId":id,
  // })

  useEffect(() => {
    if (!Patientdetails) return;
    setSelectedPatient(Patientdetails);
  }, [Patientdetails]);

  useEffect(() => {
    if (!updatedData) return;
    carePlanCallFn({
      PatientId: id,
    });
  }, [updatedData]);

  useEffect(() => {
    if (!id) {
      navigate("/patients/careplan");
    }
  }, []);

  const getDate = (value) => {
    let date = `${new Date(value)}`;
    let words = date.split(" ");
    let updatedWords = words[1] + " " + words[2] + " " + words[3];
    return updatedWords;
  };
  const dob = selectedPatient
    ? `${getDate(selectedPatient["Date Of Birth"])}`
    : "";

  const timersettingFn = (val) => {
    if (!programName.programName) {
      FloatingNotifications("error", "Select a program to continue");
      return;
    }
    if (val) {
      setStartTimer(true);
    } else {
      setStartTimer(false);
    }
    setTimerNotification(false);
  };

  return (
    <>
      {timerNotification && selectedPatient && (
        <div className="timerPopUp">
          <div className="timerContentPopUp">
            <p className="content-text">
              Do you wish to start the timer for
              <span>
                <SelectComponent
                  OptionsArray={selectedPatient?.programs}
                  fn={generalHandleValueChanges}
                  valueData={programName.programName}
                  setvalue={setProgramName}
                  name={"programName"}
                />
              </span>
            </p>
            <p className="content-text">
              Program with Service Name as "Care Plan Entry"?
            </p>
            <div className="dialog">
              <button className="btn " onClick={() => timersettingFn(true)}>
                Ok
              </button>
              <button className="btn " onClick={() => timersettingFn(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {callPatientPopUp && (
        <PatientInteractionPage
          // eventData={eventData}
          setCallPatientPopUp={setCallPatientPopUp}
          // setEventData={setEventData}
          id={id}
        />
      )}
      {selectedPatient && (
        <div className="carePlanWrap">
          <div className="careTeamContainer">
            <p className="pageTitle"></p>
            <div className="headerSection">
              <div className="colSpan1 element element1">
                <div className="profile-icon-wrap">
                  <Icon
                    className="profile"
                    icon="iconamoon:profile-fill"
                    width="30"
                    height="30"
                    color="#eee"
                  />
                </div>
                <div className="details">
                  <p className="name">{selectedPatient["Name"]}</p>
                  <p className="sub">
                    ( {selectedPatient["Gender"]}| {selectedPatient["Age"]}yrs
                    old | {dob} )
                  </p>
                </div>
              </div>
              <div className="colSpan1 element element2">
                <p className="sub">Care Plan Physician:</p>
                <p className="name">
                  {selectedPatient?.ProviderId?.ProviderName}
                </p>
              </div>
              <div className="colSpan1 element element3">
                <p className="sub">Primary Care Coordinator:</p>
                <p className="name">
                  {selectedPatient?.PrimaryCoordinator?.ClinicianName}
                </p>
              </div>
              <div className="colSpan1 element element4">
                <p className="sub">Facility:</p>
                <p className="name">
                  {selectedPatient?.FacilityId?.FacilityName}
                </p>
              </div>
              <div className="colSpan1 element element6">
                {/* <button onClick={DownloadPDF}>Download Careplan</button> */}
                <div className="download-button">
                  <PDFDownloadLink
                    document={<Download data={Patientdetails} />}
                    fileName={`${selectedPatient["Name"]}_careplansummary.pdf`}
                  >
                    {({ blob, url, loading, error }) => "Summary!"}
                  </PDFDownloadLink>
                </div>
                <div className="download-button">
                  <button onClick={goToCallingPage}>
                    <Icon icon="ion:call" width="25" />
                  </button>
                </div>
              </div>
              <div className="colSpan1 "></div>
              <div className="colSpan1  element element7">
                <div className="time">Total Time Spent</div>
                <p className="time">{totalTime}</p>
              </div>
              <div className="colSpan1  element element8">
                <div className="stopwatch">
                  <Stopwatch
                    id={id}
                    setTimerNotification={setTimerNotification}
                    startTimer={startTimer}
                    setStartTimer={setStartTimer}
                    programName={programName?.programName}
                    serviceName={"Care Plan Entry"}
                    totalTime={totalTime}
                    updateFunction={updateFunction}
                  />
                </div>
              </div>
            </div>

            <CareBodyComponent
              id={id}
              selectedPatient={selectedPatient}
              updateDatafn={updateDatafn}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CareTeam;
