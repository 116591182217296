import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import "./defaultGoals.scss";
const Goals = {
  I10: {
    "Body mass index": ["BMI of < 25.00"],
    "Walk every day": ["Walking or physical exercise at least 20 min perday"],
    "Quit Smoking": [""],
    "Limit Alcohol": [""],
    "Reduce recreational drugs like Caffeine": [
      "Limiting recreational drugs such as caffeine to lower stress",
    ],
    "Low Sodium diet (Greens, Broccoli, Cauliflower)": [""],
    "Stress Management": [
      "Reducing frequency, intensity and duration of anxiety due to stress relative activities",
    ],
  },
  E119: {
    "Hemoglobin A1C": [" Hemoglobin A1C < 6.00%"],
    " Walk every day": ["Walking or physical exercise at least 20 min perday"],
    "Drink water every day": ["Drink 6- 8 glasses of water daily"],
    "Eat Healthy diet (Beans, Dark green leafy vegetables, Fish, whole grains, Fat free Dairy products)":
      [""],
  },
  E1165: {
    "Hemoglobin A1C": [" Hemoglobin A1C < 6.00%"],
    " Walk every day": ["Walking or physical exercise at least 20 min perday"],
    "Drink water every day": ["Drink 6- 8 glasses of water daily"],
    "Eat Healthy diet (Beans, Dark green leafy vegetables, Fish, whole grains, Fat free Dairy products)":
      [""],
  },
  E118: {
    "Hemoglobin A1C": [" Hemoglobin A1C < 6.00%"],
    " Walk every day": ["Walking or physical exercise at least 20 min perday"],
    "Drink water every day": ["Drink 6- 8 glasses of water daily"],
    "Eat Healthy diet (Beans, Dark green leafy vegetables, Fish, whole grains, Fat free Dairy products)":
      [""],
  },
  E785: {
    Exercise: ["Increase physical activity by 20-30 min per week"],
  },
  E7849: {
    "Exercise - Walking": [
      "Walking or physical exercise at least 20 min perday",
    ],
  },
};
const Lifestyle = {
  I10: {
    Smoking: [""],
    "Stress management": [
      "Managing stress involves several activities including: healthy eating, sticking to a sleep routien and getting active such as walking or other physical activities to improve mood and relieve tension.",
    ],
    "Healthy Eating/Diet - Hypertension": [
      "A healthy diet can reduce the risk of diet-related diseases and improve your mood. You can try to eat complex carbohydrates, lean proteins, and fatty acids. You can also try to eat foods with antioxidants, which can help protect your cells from damage caused by chronic stress",
    ],
    Fitness: [
      "Physical activity can help relieve stress. Start small and build up to 2.5 hours of physical activity per week.",
    ],
    Alcohol: [
      "Limit your alcohol and recreational drugs like caffeine.  These can exacerbate stress which may have a counteractive effect towards your health journey.",
    ],
    "Recreational Drugs": [
      "Limit your alcohol and recreational drugs like caffeine.  These can exacerbate stress which may have a counteractive effect towards your health journey.",
    ],
    Weight: [""],
  },
  E119: {
    Smoking: [""],
    "Stress management": [
      "Managing stress involves several activities including: healthy eating, sticking to a sleep routien and getting active such as walking or other physical activities to improve mood and relieve tension.",
    ],
    "Healthy Eating/Diet - Diabetes": [
      "A healthy diet can reduce the risk of diet-related diseases and improve your mood. You can try to eat complex carbohydrates, lean proteins, and fatty acids. You can also try to eat foods with antioxidants, which can help protect your cells from damage caused by chronic stress",
    ],
    Fitness: [
      "Physical activity can help relieve stress. Start small and build up to 2.5 hours of physical activity per week.",
    ],
    Alcohol: [
      "Limit your alcohol and recreational drugs like caffeine.  These can exacerbate stress which may have a counteractive effect towards your health journey.",
    ],
    "Recreational Drugs": [
      "Limit your alcohol and recreational drugs like caffeine.  These can exacerbate stress which may have a counteractive effect towards your health journey.",
    ],
  },
  E1165: {
    Smoking: [""],
    "Stress management": [
      "Managing stress involves several activities including: healthy eating, sticking to a sleep routien and getting active such as walking or other physical activities to improve mood and relieve tension.",
    ],
    "Healthy Eating/Diet - Diabetes": [
      "A healthy diet can reduce the risk of diet-related diseases and improve your mood. You can try to eat complex carbohydrates, lean proteins, and fatty acids. You can also try to eat foods with antioxidants, which can help protect your cells from damage caused by chronic stress",
    ],
    Fitness: [
      "Physical activity can help relieve stress. Start small and build up to 2.5 hours of physical activity per week.",
    ],
    Alcohol: [
      "Limit your alcohol and recreational drugs like caffeine.  These can exacerbate stress which may have a counteractive effect towards your health journey.",
    ],
    "Recreational Drugs": [
      "Limit your alcohol and recreational drugs like caffeine.  These can exacerbate stress which may have a counteractive effect towards your health journey.",
    ],
  },
  E118: {
    Smoking: [""],
    "Stress management": [
      "Managing stress involves several activities including: healthy eating, sticking to a sleep routien and getting active such as walking or other physical activities to improve mood and relieve tension.",
    ],
    "Healthy Eating/Diet - Diabetes": [
      "A healthy diet can reduce the risk of diet-related diseases and improve your mood. You can try to eat complex carbohydrates, lean proteins, and fatty acids. You can also try to eat foods with antioxidants, which can help protect your cells from damage caused by chronic stress",
    ],
    Fitness: [
      "Physical activity can help relieve stress. Start small and build up to 2.5 hours of physical activity per week.",
    ],
    Alcohol: [
      "Limit your alcohol and recreational drugs like caffeine.  These can exacerbate stress which may have a counteractive effect towards your health journey.",
    ],
    "Recreational Drugs": [
      "Limit your alcohol and recreational drugs like caffeine.  These can exacerbate stress which may have a counteractive effect towards your health journey.",
    ],
  },
  E785: {
    "Healthy Eating/Diet": [
      " A healthy diet can reduce the risk of diet-related diseases and improve your mood. You can try to eat complex carbohydrates, lean proteins, and fatty acids. You can also try to eat foods with antioxidants, which can help protect your cells from damage caused by chronic stress",
    ],
  },
  E782: {
    "Healthy Eating/Diet": [
      " A healthy diet can reduce the risk of diet-related diseases and improve your mood. You can try to eat complex carbohydrates, lean proteins, and fatty acids. You can also try to eat foods with antioxidants, which can help protect your cells from damage caused by chronic stress",
    ],
  },
  E7849: {
    "Healthy Eating/Diet": [
      " A healthy diet can reduce the risk of diet-related diseases and improve your mood. You can try to eat complex carbohydrates, lean proteins, and fatty acids. You can also try to eat foods with antioxidants, which can help protect your cells from damage caused by chronic stress",
    ],
  },
};
const AddFavComp = ({ addDefaultFn, type, code }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!code) return;
    if (type === "Goals") {
      if (!Goals[code]) return;
      setOptions(Object.keys(Goals[code]));
    } else if (type === "Lifestyle") {
      if (!Lifestyle[code]) return;
      setOptions(Object.keys(Lifestyle[code]));
    }
  }, [type, code]);

  const onClick = (option) => {
    if (type === "Goals") {
      addDefaultFn(option, Goals[code][option][0]);
    } else if (type === "Lifestyle") {
      addDefaultFn(option, Lifestyle[code][option][0]);
    }
  };
  return (
    <div className="addDefaultgoals">
      {options.length > 0 ? (
        <ul className="options" size="5">
          {options?.map((option, index) => (
            <li key={index} onClick={() => onClick(option)}>
              <p>{option}</p>
            </li>
          ))}
        </ul>
      ) : (
        <div className="empty ">
          <p>No recomendations found for this ICD code</p>
        </div>
      )}
    </div>
  );
};

export default AddFavComp;
