import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import SelectWithSearch from "../../searchandselect component/SearchAndSelect";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";
const LifeStyleRecommendationList = [
  "Alcohol",
  "Exercise",
  "Exercise - Arthritis",
  "Fitness",
  "Healthy Eating/Diet",
  "Healthy Eating/Diet - Diabetes",
  "Healthy Eating/Diet - Hypertension",
  "Keeping Mind Active",
  "Medication",
  "Oxygen",
  "Recreational Drugs",
  "Relaxation",
  "Safety",
  "Activ Sleep",
  "Smoking",
  "Social Support",
  "Stress Management",
  "Vaccination",
  "Weight",
];
const LifeStyleRecomentation = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [lifestyleRecommendationsWindow, setLifestyleRecommendationsWindow] =
    useState("");
  const addLifestyleRecommendationsDetail = () => {
    setLifestyleRecommendationsWindow("addDetails");
    resetfn(lifestyleRecommendations, setLifestyleRecommendations);
  };
  const [lifestyleRecommendationsArray, setLifestyleRecommendationsArray] =
    useState([]);
  const [editArray, setEditArray] = useState();
  const [active, setActive] = useState("Active");

  const [lifestyleRecommendations, setLifestyleRecommendations] = useState({
    Type: "",
    RecordedDate: "",
    EndDate: "",
    Description: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(lifestyleRecommendations)) {
      generalSaveButton(
        lifestyleRecommendationsWindow,
        setLifestyleRecommendationsWindow,
        lifestyleRecommendations,
        setLifestyleRecommendationsArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (selectedPatient) {
      setLifestyleRecommendationsArray(selectedPatient.LifeStyle);
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (
      basicValidation(lifestyleRecommendations) &&
      !areArraysSame(selectedPatient?.LifeStyle, lifestyleRecommendationsArray)
    ) {
      updateDatafn({
        PatientId: id,
        LifeStyle: lifestyleRecommendationsArray,
      });
    }
  }, [lifestyleRecommendationsArray]);
  return (
    <div>
      <DropDownContent title={"Lifestyle Recommendations"}>
        {" "}
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              iconsList={[
                {
                  value: "ic:twotone-plus",
                  onClickFn: addLifestyleRecommendationsDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    lifestyleRecommendationsWindow === "addDetails"
                      ? () => onClickFn()
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () =>
                    cancleOperationFn(setLifestyleRecommendationsWindow),
                },
              ]}
            >
              {" "}
              <div>
                {lifestyleRecommendationsWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">
                        Lifestyle Recommendation Informations
                      </p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">Type*</label>
                          <SelectWithSearch
                            options={LifeStyleRecommendationList}
                            valuedata={lifestyleRecommendations.Type}
                            fn={generalHandleValueChanges}
                            setvalue={setLifestyleRecommendations}
                            name={"Type"}
                          />
                          {/* <select
                       className="display-element-Gnrl"
                       value={lifestyleRecommendations.Type}
                       onChange={(event) =>
                         generalHandleValueChanges(
                           event,
                           setLifestyleRecommendations,
                           "Type"
                         )
                       }
                     >
                       <option className="default" value=""></option>
                       {LifeStyleRecommendationList.map((el) => (
                         <option value={el}>{el}</option>
                       ))}
                     </select> */}

                          <label className="display-element-Gnrl">
                            Description*
                          </label>
                          <div className="displayDescription">
                            <textarea
                              className="display-element-Gnrl"
                              value={lifestyleRecommendations.Description}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setLifestyleRecommendations,
                                  "Description"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Recorded Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={lifestyleRecommendations.RecordedDate.slice(
                              0,
                              16
                            )}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setLifestyleRecommendations,
                                "RecordedDate"
                              )
                            }
                          />
                          <label className="display-element-Gnrl">
                            End Date & Time
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={lifestyleRecommendations?.EndDate?.slice(
                              0,
                              16
                            )}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setLifestyleRecommendations,
                                "EndDate"
                              )
                            }
                            min={lifestyleRecommendations.RecordedDate ?? ""}
                          />
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient.LifeStyle)
                      ?.length === 0 &&
                    statusfinderFn("active", selectedPatient.LifeStyle)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn’t have any Lifestyle Recommendations yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"LifeStyle"}
                        array={lifestyleRecommendationsArray}
                        id={id}
                        careplanview={careplanview}
                        values={[
                          setLifestyleRecommendationsWindow,
                          setLifestyleRecommendations,
                        ]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient.LifeStyle
                        )}
                        dataSet={selectedPatient?.LifeStyle ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          ["Type:", "Type", "colSpan12"],
                          ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                          ["End Date & Time:", "EndDate", "colSpan6"],
                          ["Description:", "Description", "colSpan12"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              {" "}
              <>
                {" "}
                {statusfinderFn("history", selectedPatient.LifeStyle)
                  ?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn("history", selectedPatient.LifeStyle)}
                    contentArray={[
                      ["Type:", "Type", "colSpan12"],
                      ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                      ["End Date & Time:", "EndDate", "colSpan6"],
                      ["Description:", "Description", "colSpan12"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default LifeStyleRecomentation;
