import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import SelectWithSearchGeneral from "../../searchandselect component/SearchAndSelectGeneral";
import TabElement from "../../../components/TabElement";
import TabWidget from "../../../components/TabWidget";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import { useGetMedicationMutation } from "../../../app/api/patientsApi";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const frequencyList = [
  "Once a day",
  "Twice a day",
  "Three times a day",
  "Four times a day",
  "Every 2 hour",
  "Every 4 hour",
  "Every 8 hour",
  "Every Alternative day",
  "Once a week",
  "Once a month",
  "Every 3 month",
  "Every 6 month",
  "As needed",
  "As directed",
];

const Medication = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [medicationWindow, setMedicationWindow] = useState("");
  const addmedicationDetail = () => {
    setMedicationWindow("addDetails");
    resetfn(medication, setMedication);
  };
  const [editArray, setEditArray] = useState();

  const [medicationArray, setMedicationArray] = useState([]);
  const [getMedication, { data: medicationData }] = useGetMedicationMutation();
  const [active, setActive] = useState("Active");

  const [medication, setMedication] = useState({
    MedicationCode: "",
    Medication: "",
    RecordedDate: "",
    EndDate: "",
    Frequency: "",
    Quantity: "",
    Notes: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(medication)) {
      generalSaveButton(
        medicationWindow,
        setMedicationWindow,
        medication,
        setMedicationArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (
      basicValidation(medication) &&
      !areArraysSame(selectedPatient?.Medications ?? [], medicationArray)
    ) {
      updateDatafn({ PatientId: id, Medications: medicationArray });
    }
  }, [medicationArray]);
  useEffect(() => {
    setMedicationArray(selectedPatient.Medications);
    // console.log(selectedPatient.Medications)
  }, [selectedPatient]);

  // useEffect(() => {
  //   const now = new Date();
  //   console.log(now);
  // }, []);
  return (
    <div>
      <DropDownContent title={"Medication"}>
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              iconsList={[
                { value: "solar:settings-bold", onClickFn: "" },
                { value: "ic:twotone-plus", onClickFn: addmedicationDetail },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    medicationWindow === "addDetails"
                      ? () => onClickFn()
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () => cancleOperationFn(setMedicationWindow),
                },
              ]}
            >
              <div>
                {medicationWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">Medication Information</p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Search by Medication Name*
                          </label>
                          <SelectWithSearchGeneral
                            options={medicationData}
                            Apicallfn={getMedication}
                            type={"Medication"}
                            setvalue={setMedication}
                            name={"Medication"}
                            medicationCode={"MedicationCode"}
                            fn={generalHandleValueChanges}
                          />
                          {/* <SelectWithSearch
                         options={medicationData}
                         valuedata={medication.MedicationCode}
                         setvalue={setMedication}
                         name={"MedicationCode"}
                         decription={"Medication"}
                         ApiOption={true}
                         Apicallfn={getMedication}
                         selectedPatient={selectedPatient}
                       /> */}

                          <label className="display-element-Gnrl">
                            Medication Name*
                          </label>
                          <div className="displayDescription-color">
                            <textarea
                              className="display-element-Gnrl"
                              value={medication?.Medication}
                              disabled={true}
                            />
                          </div>
                          <label className="display-element-Gnrl">Notes*</label>

                          <textarea
                            className="display-element-Gnrl"
                            value={medication?.Notes}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setMedication,
                                "Notes"
                              )
                            }
                          />
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Frequency*
                          </label>
                          <select
                            className="display-element-Gnrl"
                            value={medication?.Frequency}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setMedication,
                                "Frequency"
                              )
                            }
                          >
                            <option className="default" value=""></option>
                            {frequencyList.map((el) => (
                              <option value={el}>{el} </option>
                            ))}
                          </select>

                          <label className="display-element-Gnrl">
                            Recorded Date & Time*
                          </label>
                          <input
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={medication?.RecordedDate?.slice(0, 16)}
                            className="display-element-Gnrl "
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setMedication,
                                "RecordedDate"
                              )
                            }
                          />
                          <label className="display-element-Gnrl">
                            End Date & Time
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={medication?.EndDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setMedication,
                                "EndDate"
                              )
                            }
                            min={medication?.RecordedDate ?? ""}
                          />
                          <label className="display-element-Gnrl">
                            Quantity*
                          </label>
                          <input
                            type="text"
                            className="display-element-Gnrl "
                            value={medication?.Quantity}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setMedication,
                                "Quantity"
                              )
                            }
                          />
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient?.Medications)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn't have any Medications yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"Medications"}
                        array={medicationArray}
                        id={id}
                        careplanview={careplanview}
                        values={[setMedicationWindow, setMedication]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient?.Medications
                        )}
                        dataSet={selectedPatient?.Medications ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          ["Medication", "Medication", "colSpan12"],
                          ["Recorded Date & Time:", "RecordedDate", "colSpan4"],
                          ["End Date & Time:", "EndDate", "colSpan3"],
                          ["Frequency:", "Frequency", "colSpan3"],
                          ["Quantity:", "Quantity", "colSpan2"],
                          ["Notes:", "Notes", "colSpan2"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              <>
                {statusfinderFn("history", selectedPatient?.Medications)
                  ?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn(
                      "history",
                      selectedPatient.Medications
                    )}
                    contentArray={[
                      ["Medication", "Medication", "colSpan12"],
                      ["Recorded Date & Time:", "RecordedDate", "colSpan4"],
                      ["End Date & Time:", "EndDate", "colSpan3"],
                      ["Frequency:", "Frequency", "colSpan3"],
                      ["Quantity:", "Quantity", "colSpan2"],
                      ["Notes:", "Notes", "colSpan2"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default Medication;
