import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import SelectWithSearch from "../../searchandselect component/SearchAndSelect";
import CarePlanErrorMessage from "../CarePlanErrorMessage";
const goalList = [
  "2-hour postprandial glucose",
  "Ability to walk to my mailbox daily",
  "Acquiring assertion skills",
  "Adherence to appointment schedules - Adhering to appointment schedules and actively participating in their recovery",
  "Adherence to medical treatment for depression - Patient adheres to medicaltreatment (takes their medication, continues therapy etc)",
  "Adherence to medical treatment for SUD/Addiction Disorders - 1.All drugsprescribed are present in screen to prevent diversion 2.Lowest dose and least number of medications needed to prevent relapse",
  "Average pain level",
  "Avoid smoke and air pollution",
  "Body mass index",
  "Clear your airways",
  "Cognitive change - 1.Stating less than a 10% belief in assumption of need for perfection 2.modifying schema of worthlessness:",
  "Completing exposure to all avoided situations",
  "Control your breathing",
  "Coping skills for managing depression - Patient will verbalize to therapist at least 3 coping skills for managing depression",
  "Development of coping skills - Develop Coping skills for recognizing and dealing with symptoms of bipolar depression",
  "Diastolic blood pressure",
  "Drink water every day - Litres",
  "Drinking water",
  "Eat Healthy diet (Beans, Dark green leafy vegetables, Fish, whole grains, Fat free Dairy products)",
  "Eat healthy foods",
  "Eliminating all depressive symptoms (Beck Depression Inventory–II score under 10 for 1 month)",
  "Eliminating intrusive memories of trauma",
  "Eliminating panic attacks",
  "End-state goals - 1.Eliminating all depressive symptoms (Beck Depression Inventory–II score under 10 for 1 month) 2.Engaging in all previously avoided activities 3.Eliminating panic attacks",
  "Engaging in all previously avoided activities",
  "Engaging in one pleasurable/rewarding activity daily",
  "Enhance cognition, mood and behavior",
  "Exercise",
  "Exercise - Aerobic",
  "Exercise - Basketball",
  "Exercise - Bicycling",
  "Exercise - Climbing",
  "Exercise - Running",
  "Exercise - Skipping",
  "Exercise - Soccer",
  "Exercise - Strength Training",
  "Exercise - Swimming",
  "Exercise - Total Step Count",
  "Exercise - Walking",
  "Exercise - Yoga",
  "Exercise regularly",
  "Fasting plasma glucose",
  "Foster a safe environment",
  "HDL cholesterol",
  "Healthy Eating / Diet",
  "Hemoglobin A1C",
  "LDL cholesterol",
  "Learn and Implement coping skills - 1.Learn and practice at least 2 anxiety management techniques with goal of decreasing anxiety symptoms to less than 3 times per week 2.work with therapist/counselor to help expose and extinguish irrational thoughts that contribute to anxiety",
  "Learn coping skills to deal with substance use triggers - 1.AA 2.Group meetings 3.Exercise",
  "Less Isolation - Patient initiates 2 or more social contacts per week for 4 weeks",
  "Limit Alcohol",
  "Low Sodium diet (Greens, Broccoli, Cauliflower)",
  "Maximize function in daily activities",
  "Medication Management for depression - 1.Adherence to Medical treatment 2.Reduction in number of different meds needed to control the symptoms",
  "Medication Management for Schizophrenia - 1.Adherence to medical treatment 2.Smallest number of medications needed to control symptoms",
  "Modifying schema of worthlessness",
  "O2 Saturation",
  "Peak Expiratory Flow Rate",
  "Promote social engagement, as appropriate",
  "Pulse",
  "Quit Smoking",
  "Reduce Overdose and Relapse - 1.MAT 2.Therapy/Meetings",
  "Reduce recreational drugs like Caffeine",
  "Reduced impairment - 1.Bringing grades up to prior level (A’s and B’s) 2.Resuming all household activities 3.Beginning to date 4.Finding appropriate employment",
  "Reducing self-critical ideation",
  "Reduction in Symptoms - Less frequent and less intense hallucinations or symptoms",
  "Reduction of frequency, Intensity and duration of the anxiety - Reduce overall Frequency, Intensity, and duration of the anxiety so that daily functioning is not impaired",
  "Reduction of Manic-Depressive episodes - 1.Improved sleep schedules, exercise schedules 2.Adherence to therapy",
  "Reduction of Panic Attacks by 50% - 1.Panic attacks will be reduced from 6 times per week currently to 3 times per week or less 2.Patient will not experience any panic attacks for 3 consecutive weeks, per self-report",
  "Reporting anxiety below 2 on a scale from 0 to 10 in business meetings",
  "Respiration Rate",
  "Stating less than a 10% belief in assumption of need for perfection",
  "Stress Management",
  "Symptoms relief - 1.Eliminating intrusive memories of trauma 2.Reducing self-critical ideation 3.Reporting anxiety below 2 on a scale from 0 to 10 in business meetings",
  "Systolic blood pressure",
  "Task Completion - 1.Completing exposure to all avoided situations 2.Engaging in one pleasurable/rewarding activity daily 3.Acquiring assertion skills",
  "Temperature",
  "Therapy engagement - 1.Better sleep schedule 2.Exercise routine 3.Doesn’t miss appointments",
  "Triglycerides",
  "Understanding about Illness - Able to understand more about their Illness in order to better participate in their treatment",
  "Uninterrupted sleep",
  "Walk every day - Minutes",
  "Weight",
  "Other",
];
const Goals = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [active, setActive] = useState("Active");

  const [goalWindow, setGoalWindow] = useState("");
  const addGoalDetail = () => {
    setGoalWindow("addDetails");
    resetfn(goal, setGoal);
  };
  const [goalArray, setGoalArray] = useState([]);
  const [editArray, setEditArray] = useState();
  const [goal, setGoal] = useState({
    Goal: "",
    StartDate: "",
    EndDate: "",
    Description: "",
    Status: true,
  });

  useEffect(() => {
    if (selectedPatient) {
      setGoalArray(selectedPatient.GoalInformation);
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (
      basicValidation(goal) &&
      !areArraysSame(selectedPatient?.GoalInformation, goalArray)
    ) {
      updateDatafn({ PatientId: id, GoalInformation: goalArray });
    }
  }, [goalArray]);
  return (
    <div>
      <DropDownContent title={"Goals"}>
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              iconsList={[
                { value: "solar:settings-bold", onClickFn: "" },
                {
                  value: "ic:twotone-plus",
                  onClickFn: addGoalDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    basicValidation(goal) && goalWindow === "addDetails"
                      ? () =>
                          generalSaveButton(
                            goalWindow,
                            setGoalWindow,
                            goal,
                            setGoalArray,
                            editArray,
                            setEditArray
                          )
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () => cancleOperationFn(setGoalWindow),
                },
              ]}
            >
              <div>
                {goalWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">Goal Information</p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Search Goal*
                          </label>
                          <SelectWithSearch
                            options={goalList}
                            valuedata={goal.Goal}
                            fn={generalHandleValueChanges}
                            setvalue={setGoal}
                            name={"Goal"}
                          />
                          {/* <select
                        className="display-element-Gnrl"
                        value={goal.Goal}
                        onChange={(event) =>
                          generalHandleValueChanges(event, setGoal, "Goal")
                        }
                      >
                        <option className="default" value=""></option>
                        {goalList.map((el) => (
                          <option value={el}>{el}</option>
                        ))}
                      </select> */}

                          <label className="display-element-Gnrl">Goal*</label>
                          <div className="displayDescription-color">
                            <textarea
                              disabled={true}
                              className="display-element-Gnrl"
                              // placeholder={goal.Goal}
                              value={goal.Goal ?? ""}
                            />
                          </div>
                          <label className="display-element-Gnrl">
                            Description*
                          </label>
                          {/* <div className="displayDescription-color"> */}
                          <textarea
                            className="display-element-Gnrl"
                            value={goal.Description}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setGoal,
                                "Description"
                              )
                            }
                          />
                          {/* </div> */}
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Start Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={goal.StartDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setGoal,
                                "StartDate"
                              )
                            }
                          />
                          <label className="display-element-Gnrl">
                            End Date & Time
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={goal?.EndDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setGoal,
                                "EndDate"
                              )
                            }
                            min={goal.StartDate ?? ""}
                          />
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient.GoalInformation)
                      ?.length === 0 &&
                    statusfinderFn("active", selectedPatient.GoalInformation)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn’t have any Goals yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"GoalInformation"}
                        array={goalArray}
                        id={id}
                        careplanview={careplanview}
                        values={[setGoalWindow, setGoal]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient.GoalInformation
                        )}
                        dataSet={selectedPatient?.GoalInformation ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          ["Goal", "Goal", "colSpan12"],
                          ["Start Date & Time:", "StartDate", "colSpan4"],
                          ["End Date & Time:", "EndDate", "colSpan4"],
                          // ["Goals:", "Goals", "colSpan4"],
                          ["Description:", "Description", "colSpan12"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              <>
                {" "}
                {statusfinderFn("history", selectedPatient.GoalInformation)
                  ?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn(
                      "history",
                      selectedPatient.GoalInformation
                    )}
                    contentArray={[
                      ["Goal", "Goal", "colSpan12"],
                      ["Start Date & Time:", "StartDate", "colSpan4"],
                      ["End Date & Time:", "EndDate", "colSpan4"],
                      // ["Goals:", "Goals", "colSpan4"],
                      ["Description:", "Description", "colSpan12"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default Goals;
