import React, { useEffect, useState } from "react";
import SelectComponent from "../components/SelectComponent";
import SelectComponent2 from "../components/SelectCompoent2";

import {
  generalHandleValueChanges,
  basicValidation,
} from "../patients/Careteam/helperfunctions";
import LoaderComponent from "../components/LoaderComponent";
import { useRescheduleApiMutation } from "../app/api/scheduleCallApi";
import FloatingNotifications from "../utilities/FloatingNotifications";
import { useGetPatientsAllProfilesQuery } from "../app/api/patientsApi";
import { useGetClinicianAllProfilesQuery } from "../app/api/clinicianApi";
import { useGetProviderAllProfilesQuery } from "../app/api/providerApi";
import SelectWithSearch from "../patients/searchandselect component/SearchAndSelect";
import "./addShedule.scss";
const AppointmentTypeOptions = [
  "Abnormal Results",
  "After Sclerotherapy",
  "Annual Exam",
  "Biopsy Procedure",
  "Routine Care",
  "EEG",
  "EMG",
  "EVLT",
  "Follow-Up",
  "Injection",
  "Laser Treatment",
  "Medical Clearance",
  "New Patient",
  "None",
  "Office Visit",
  "Pap Results",
  "Pap smear",
  "Physical Exam",
  "Post Op",
  "Pre Op",
  "Procedure",
  "Radiofrequency",
  "Results",
  "Sclerotherapy",
  "Walk-in",
];

const AddShedule = ({
  sheduleDate,
  setSheduleData,
  createScheduleFn,
  cancelfn,
  upper,
  lower,
  sheduleData,
  programName,
  updateSheduleData,
  resheduleFn,
}) => {
  const { data: ClinicianListLatest } = useGetClinicianAllProfilesQuery();
  const { data: physicianListLatest } = useGetProviderAllProfilesQuery();
  const { data: dataFromApi } = useGetPatientsAllProfilesQuery();
  const [patients, setPatients] = useState();
  const [clinicianList, setClinicianList] = useState();
  const [physicianList, setPhysicianList] = useState();
  const [popUpSave, setPopUpSave] = useState(false);
  const initialState = {
    // Program: "CCM",
    scheduleType: "",
    patientId: "",
    appointmentType: "",
    serviceProviderProfileId: "",
    callPurpose: "",
    Date: "",
    time: lower ?? "",
    callDuration: 10,
    patientInstructions: "",
  };
  const [data, setData] = useState({
    // Program: "CCM",
    scheduleType: "",
    patientId: "",
    appointmentType: "",
    serviceProviderProfileId: "",
    serviceProviderUser: "",
    callPurpose: "",
    Date: sheduleDate ?? "",
    time: lower ?? "",
    callDuration: 10,
    patientInstructions: "",
  });
  const [booked, setBooked] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);

  const createStartDateTime = (date, time) => {
    const [timePart, ampm] = time.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);

    let hours24 = hours;
    if (ampm === "PM" && hours !== 12) {
      hours24 += 12;
    } else if (ampm === "AM" && hours === 12) {
      hours24 = 0;
    }

    const [year, month, day] = date.split("-").map(Number);

    const startDateTime = new Date(
      `${date}T${hours24.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:00.000Z`
    );

    const isoString = startDateTime.toISOString();
    return isoString;
  };

  const SlotCheckFn = (time, callDuration) => {
    const startTime = time.getTime();

    const endTime = new Date(
      new Date(time).getTime() + callDuration * 60 * 1000
    );
    let currentTime = startTime;
    let SlotsArray = [];
    while (currentTime < endTime) {
      const timeSlot = currentTime;
      SlotsArray.push(
        new Date(timeSlot).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
      currentTime += 5 * 60 * 1000;
    }
    return SlotsArray.some((item) => booked.includes(item));
  };

  const generateTimeSlots = () => {
    const startTime = new Date().setHours(0, 0, 0, 0);

    const endTime = new Date().setHours(24, 0, 0, 0);

    const timeSlotsArray = [];
    let currentTime = startTime;

    while (currentTime < endTime) {
      const timeSlot = new Date(currentTime);
      timeSlotsArray.push(
        timeSlot.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
      currentTime += data.callDuration * 60 * 1000;
    }

    setTimeSlots(timeSlotsArray);
  };

  const save = () => {
    if (!basicValidation(data)) {
      FloatingNotifications(
        "error",
        "To proceed, all fields must be filled out."
      );
      return;
    }
    setPopUpSave(true);
  };
  const onSaveConfirmed = () => {
    let StartDateTime = createStartDateTime(data.Date, data.time);
    let { Date, time, ...DataUp } = data;
    if (updateSheduleData) {
      resheduleFn({
        scheduleId: updateSheduleData?.scheduleId,
        ...DataUp,
        StartDateTime,
        programName: updateSheduleData?.programName,
      });
      setPopUpSave(false);
      if (cancelfn) cancelfn();
      return;
    }
    createScheduleFn({
      ...DataUp,
      StartDateTime,
      programName: programName,
    });
    setPopUpSave(false);
    if (cancelfn) cancelfn();
  };

  const cancel = () => {
    if (cancelfn) cancelfn();
    setData(initialState);
  };
  useEffect(() => {
    generateTimeSlots();
  }, [data.callDuration]);

  useEffect(() => {
    if (!dataFromApi) return;
    let patients = dataFromApi.map((p) => [p.Name, p.PatientId]);
    setPatients(patients);
  }, [dataFromApi]);

  useEffect(() => {
    if (!programName) return;
    generalHandleValueChanges(
      `${programName} General Follow Up`,
      setData,
      "callPurpose"
    );
  }, [programName]);
  useEffect(() => {
    if (!updateSheduleData) return;
    setData({
      scheduleType: updateSheduleData?.scheduleType,
      patientId: updateSheduleData?.patientId._id,
      callPurpose: updateSheduleData?.callPurpose,
      serviceProviderUser: updateSheduleData?.serviceProviderUser,
      appointmentType: updateSheduleData?.appointmentType,
      serviceProviderProfileId: `${updateSheduleData?.serviceProviderProfileId?._id}`,
      Date: updateSheduleData?.Date ?? "",
      time: updateSheduleData?.time ?? "",
      callDuration: updateSheduleData?.callDuration,
      patientInstructions: updateSheduleData?.patientInstructions,
    });
  }, [updateSheduleData]);
  // useEffect(() => {
  //   if (!sheduleData) return;
  //   let bookedslots = sheduleData
  //     ?.filter((el) => el.Date === sheduleDate)
  //     ?.map((el) => [el.time, el.callDuration]);
  //   let i = 0;
  //   if (!bookedslots) return;
  //   while (bookedslots[i]) {
  //     if (!bookedslots[i]) return;
  //     let [hourString, minPeriod] = bookedslots[i][0]?.split(":");
  //     let [min, period] = minPeriod?.split(" ");
  //     let adjustedHour = parseInt(hourString);

  //     if (period === "PM" && adjustedHour !== 12) {
  //       adjustedHour += 12;
  //     } else if (period === "AM" && adjustedHour === 12) {
  //       adjustedHour = 0;
  //     }
  //     let date = new Date(sheduleDate).setHours(
  //       adjustedHour,
  //       parseInt(min),
  //       0,
  //       0
  //     );
  //     date = new Date(date);
  //     let EndDate = new Date(
  //       new Date(date).getTime() + bookedslots[i][1] * 60 * 1000
  //     );
  //     const startTime = date.getTime();

  //     const endTime = EndDate.getTime();
  //     let currentTime = startTime;
  //     let SlotsArray = [];
  //     while (currentTime < endTime) {
  //       const timeSlot = currentTime;
  //       SlotsArray.push(
  //         new Date(timeSlot).toLocaleTimeString([], {
  //           hour: "2-digit",
  //           minute: "2-digit",
  //         })
  //       );
  //       currentTime += 5 * 60 * 1000;
  //     }
  //     setBooked((prev) => [...prev, ...SlotsArray]);
  //     i = i + 1;
  //   }
  // }, [sheduleData, sheduleDate]);
  const getServiceProviderList = () => {
    if (data?.serviceProviderUser === "clinician") {
      return clinicianList;
    } else if (data?.serviceProviderUser === "provider") {
      return physicianList;
    } else {
      return [];
    }
  };
  useEffect(() => {
    if (!ClinicianListLatest) return;
    let clinicianList = [];
    ClinicianListLatest.map((el) =>
      clinicianList.push([el.Name, el.ClinicianId])
    );
    setClinicianList(clinicianList);
  }, [ClinicianListLatest]);
  useEffect(() => {
    if (!physicianListLatest) return;
    let physicianList = [];
    physicianListLatest.map((el) =>
      physicianList.push([el.Name, el.ProviderId])
    );
    setPhysicianList(physicianList);
  }, [physicianListLatest]);
  return (
    <div className="addshedule">
      {popUpSave && (
        <div className="popUpDelete">
          <div className="popupContent">
            <p>Are you sure you want to add this shedule?</p>
            <div className="popupActions">
              <button onClick={() => onSaveConfirmed()}>Save</button>
              <button onClick={() => setPopUpSave(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <p className="title">
        {updateSheduleData
          ? `Reshedule for ${updateSheduleData?.patientId?.PatientName}`
          : "Create Schedule"}
      </p>
      <div className="contai">
        <div className="elementsSides">
          {/* <div className="Elements"> */}
          {/* <p className="ElementsLabel">Select Program *</p> */}
          {/* <SelectComponent
              OptionsArray={["CCM", "RPM"]}
              valueData={data.Program}
              fn={generalHandleValueChanges}
              setvalue={setData}
              name={"Program"}
            /> */}
          {/* </div> */}
          {updateSheduleData ? (
            <></>
          ) : (
            <div className="Elements">
              <p className="ElementsLabel">Patient Name *</p>
              <SelectWithSearch
                optionsDict={patients}
                // valuedata={goal.Goal}
                fn={generalHandleValueChanges}
                setvalue={setData}
                name={"patientId"}
              />
            </div>
          )}

          <div className="Elements">
            <p className="ElementsLabel">Schedule Type *</p>
            <SelectComponent
              OptionsArray={["Phone call", "In-person", "Video call"]}
              valueData={data.scheduleType}
              fn={generalHandleValueChanges}
              setvalue={setData}
              name={"scheduleType"}
            />
          </div>
          <div className="Elements">
            <p className="ElementsLabel">Assignee Role*</p>

            <SelectComponent
              OptionsArray={["clinician", "provider"]}
              valueData={data?.serviceProviderUser}
              fn={generalHandleValueChanges}
              setvalue={setData}
              name={"serviceProviderUser"}
            />
          </div>
          <div className="Elements">
            <p className="ElementsLabel">Assignee Name *</p>
            <select
              type="text"
              disabled={data?.serviceProviderUser === ""}
              className="field"
              value={data.serviceProviderProfileId}
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setData,
                  "serviceProviderProfileId"
                )
              }
            >
              <option value={""}></option>
              {getServiceProviderList()?.map((el) => (
                <option value={el[1]}>{el[0]}</option>
              ))}
            </select>
          </div>
          <div className="Elements">
            <p className="ElementsLabel">Appointment Type *</p>
            <SelectComponent
              OptionsArray={AppointmentTypeOptions}
              valueData={data?.appointmentType}
              fn={generalHandleValueChanges}
              setvalue={setData}
              name={"appointmentType"}
            />
          </div>

          <div className="Elements">
            <p className="ElementsLabel">Purpose of the call*</p>
            <input
              className="elementInput"
              type="text"
              value={data.callPurpose}
              onChange={(event) =>
                generalHandleValueChanges(event, setData, "callPurpose")
              }
            />
            {/* <SelectComponent
              OptionsArray={["Phone call", "In-person", "Video call"]}
              valueData={data.callPurpose}
              fn={generalHandleValueChanges}
              setvalue={setData}
              name={"callPurpose"}
            /> */}
          </div>
          <div className="Elements">
            <p className="ElementsLabel">
              Schedule date, time & duration in assignee time zone *
            </p>
            <div className="dateTime">
              <input
                type="date"
                value={data?.Date}
                // disabled={true}
                min={new Date().toISOString().slice(0, 10)}
                onChange={(event) =>
                  generalHandleValueChanges(event, setData, "Date")
                }
              />
              <SelectComponent
                OptionsArray={timeSlots}
                valueData={data.time}
                fn={generalHandleValueChanges}
                setvalue={setData}
                name={"time"}
              />
              {/* <select
                value={data.time}
                onChange={(event) =>
                  generalHandleValueChanges(event, setData, "time")
                }
              >
                {timeSlots.map((ele) => (
                  <option>{ele}</option>
                ))}
              </select> */}
              <select
                value={data.callDuration}
                onChange={(event) =>
                  generalHandleValueChanges(event, setData, "callDuration")
                }
              >
                <option>10</option>
                <option>15</option>
                <option>20</option>
                <option>30</option>
                <option>45</option>
                <option>60</option>
              </select>
            </div>
          </div>
          <div className="Elements">
            <p className="ElementsLabel">
              Before Visit Instruction To Patient *
            </p>
            <input
              type="textarea"
              value={data.patientInstructions}
              onChange={(event) =>
                generalHandleValueChanges(event, setData, "patientInstructions")
              }
            />
          </div>
        </div>
      </div>
      <div className="sheduleButton">
        <>
          <button onClick={save}>Save</button>
          <button onClick={cancel}>Cancel</button>
        </>
      </div>
    </div>
  );
};

export default AddShedule;
