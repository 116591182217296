import React, { useState } from "react";
import "./patientDemgraphicsComponent.scss";
import TabWidget from "../../components/TabWidget";
import TabElement from "../../components/TabElement";
import Important from "./Important";
import Demographics from "./Demographics";
import Insurance from "./Insurance";
import Program from "./Program";
import PopUp from "./PopUp";
import AddPatient from "../parientsPreviewPage/addPatientComponent/AddPatient";
const tabChildrenList = [
  "Important",
  "Demographics",
  "Program",
  "Insurances1",
  "Insurances2",
  "Insurances3",
];
const PatientDemographicsComponent = ({
  selectedData,
  id,
  updatePatientfn,
  updatedData,
}) => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [updateCallValue, setUpdateValue] = useState("");
  const [triggerEffect, setTriggerEffect] = useState(false);

  const [currentTab, setCurrentTab] = useState("Important");
  const updatebtnCall = (value) => {
    setUpdateValue(value);
    setTriggerEffect(true);

    setPopUpOpen(true);
  };
  return (
    <div className="tabWidgetContainer">
      <AddPatient
        selectedData={selectedData}
        updatePatientfn={updatePatientfn}
        id={id}
        triggerEffect={triggerEffect}
        setTriggerEffect={setTriggerEffect}
        updateCallValue={updateCallValue}
        setUpdateValue={setUpdateValue}
        popUpOpen={popUpOpen}
        setPopUpOpen={setPopUpOpen}
      />
      <TabWidget>
        {tabChildrenList.map((el) => (
          <TabElement
            key={`patients_${el}`}
            title={el}
            active={currentTab === el}
            onClick={() => setCurrentTab(el)}
          />
        ))}
      </TabWidget>
      {currentTab === "Important" && (
        <Important
          selectedData={selectedData}
          updatePatientfn={updatePatientfn}
          id={id}
          updatedData={updatedData}
        />
      )}{" "}
      {currentTab === "Demographics" && (
        <Demographics
          selectedData={selectedData}
          updatePatientfn={updatePatientfn}
          id={id}
          updatedData={updatedData}
        />
      )}
      {currentTab === "Program" && (
        <Program
          selectedData={selectedData}
          updatePatientfn={updatePatientfn}
          id={id}
          updatedData={updatedData}
          setPopUpOpen={setPopUpOpen}
          updatebtnCall={updatebtnCall}
        />
      )}
      {currentTab === "Insurances1" && (
        <Insurance
          insuranceType={"firstInsuranceData"}
          selectedData={selectedData}
          updatePatientfn={updatePatientfn}
          id={id}
          updatedData={updatedData}
        />
      )}
      {currentTab === "Insurances2" && (
        <Insurance
          insuranceType={"secondInsuranceData"}
          selectedData={selectedData}
          updatePatientfn={updatePatientfn}
          id={id}
          updatedData={updatedData}
        />
      )}
      {currentTab === "Insurances3" && (
        <Insurance
          insuranceType={"thirdInsuranceData"}
          selectedData={selectedData}
          updatePatientfn={updatePatientfn}
          id={id}
          updatedData={updatedData}
        />
      )}
    </div>
  );
};

export default PatientDemographicsComponent;
