import React from "react";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import SelectWithSearch from "../../../searchandselect component/SearchAndSelect";
import AddFavComp from "./AddFavComp";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  generalHandleValueChanges,
  generalSaveButton,
  getCurrentDateAndTime,
  areObjectsEqual,
} from "../../helperfunctions";
import CarePlanErrorMessage from "../../CarePlanErrorMessage";
const LifeStyleRecommendationList = [
  "Alcohol",
  "Exercise",
  "Exercise - Arthritis",
  "Fitness",
  "Healthy Eating/Diet",
  "Healthy Eating/Diet - Diabetes",
  "Healthy Eating/Diet - Hypertension",
  "Keeping Mind Active",
  "Medication",
  "Oxygen",
  "Recreational Drugs",
  "Relaxation",
  "Safety",
  "Activ Sleep",
  "Smoking",
  "Social Support",
  "Stress Management",
  "Vaccination",
  "Weight",
];
const options = [
  "Smoking",
  "Stress management",
  "Healthy Eating/Diet - Hypertension",
  "Fitness",
  "Alcohol",
  "Recreational Drugs",
  "Weight",
  "Healthy Eating/Diet",
];
const DiagnosticLifeStyle = (props) => {
  const {
    selectedPatient,
    id,
    creatnewID,
    updateDatafn,
    Icd,
    changeWindow,
    uniqueId,
  } = props;
  const [lifeStyleArray, setLifeStyleArray] = useState([]);
  const [lifeStyleWindow, setLifeStyleWindow] = useState(false);
  const [arrayForEdit, setArrayForEdit] = useState();

  const [lifeStyle, setLifeStyle] = useState({
    Type: "",
    RecordedDate: getCurrentDateAndTime(),
    EndDate: "",
    Description: "",
    Diagnosis_id: uniqueId,

    Status: true,
    // "_id": props.data.PatientId
  });
  const deleteObjectFromArray = (object, array, setArray) => {
    let newArray = array?.filter((el) => !areObjectsEqual(el, object));
    setArray(newArray);
    updateDatafn({ PatientId: id, LifeStyle: newArray });
  };

  const addLifeStyleDetail = (noreset) => {
    setLifeStyleWindow((prev) => !prev);
    if (noreset) return;
    resetfn(lifeStyle, setLifeStyle, uniqueId);
  };
  const arrayForEditFn = (value) => {
    let { _id, ...updatedLife } = value;

    let r = selectedPatient?.LifeStyle?.filter(
      (el) => !areObjectsEqual(el, value)
    );
    let updatedArray = r.map((obj) => {
      const { _id, ...rest } = obj;
      return rest;
    });
    setArrayForEdit(updatedArray);
    setLifeStyle(updatedLife);
  };
  useEffect(() => {
    if (selectedPatient) {
      let re = selectedPatient.LifeStyle?.filter(
        (el) => el.Diagnosis_id !== undefined
      );
      setLifeStyleArray(re.filter((el) => el.Diagnosis_id === uniqueId));
    }
  }, [selectedPatient]);
  const saveButton = () => {
    if (!basicValidation(lifeStyle)) return;
    setLifeStyleArray((prev) => [...prev, lifeStyle]);
    setLifeStyleWindow(false);
  };
  const addDefaultLifeStyleFn = (option, desc) => {
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 16);
    let lifeStyle = {
      Type: option,
      RecordedDate: formattedDate,
      EndDate: "",
      Description: desc,
      Diagnosis_id: uniqueId,
      Status: true,
    };
    setLifeStyle(lifeStyle);
    setLifeStyleArray((prev) => [...prev, lifeStyle]);
  };
  useEffect(() => {
    if (arrayForEdit) {
      if (!basicValidation(lifeStyle)) return;
      setLifeStyleArray([...arrayForEdit, lifeStyle]);
      setArrayForEdit();
      setLifeStyleWindow(false);
      return;
    }
    let filteredData = selectedPatient?.LifeStyle?.filter(
      (el) => el.Diagnosis_id !== uniqueId
    );
    if (
      basicValidation(lifeStyle) &&
      !areArraysSame(selectedPatient?.LifeStyle, [
        ...filteredData,
        ...lifeStyleArray,
      ])
    ) {
      updateDatafn({
        PatientId: id,
        LifeStyle: [...filteredData, ...lifeStyleArray],
      });
    }
  }, [lifeStyleArray]);

  return (
    <div>
      <p className="title">
        Lifestyle Recommendations for {"<<"}
        {Icd}
        {">>"}
      </p>

      <div className="addDetailsToggle">
        {lifeStyleWindow ? (
          <button className="btnGoalsToggle" onClick={addLifeStyleDetail}>
            {/* <Icon icon="ph:eye-bold" width="20" height="20" /> */}
            View
          </button>
        ) : (
          <>
            <button
              className="btnGoalsToggle"
              onClick={changeWindow.bind(null, 2)}
            >
              Prev
            </button>
            <button className="btnGoalsToggle" onClick={creatnewID.bind(null)}>
              Next
            </button>
            <button
              className="btnGoalsToggle"
              onClick={() => addLifeStyleDetail(false)}
            >
              {/* <Icon icon="ic:twotone-plus" width="20" height="20" />  */}
              Add New
            </button>
          </>
        )}
      </div>
      <div
        className="goalDisplay"
        style={{ display: "grid", gridTemplateColumns: "80% 20%" }}
      >
        {lifeStyleWindow ? (
          <div className="details-sec">
            <div className="detail-content">
              <label className="details-element">Type*</label>
              <SelectWithSearch
                options={LifeStyleRecommendationList}
                valuedata={lifeStyle["Type"]}
                fn={generalHandleValueChanges}
                setvalue={setLifeStyle}
                name={"Type"}
              />

              <label className="details-element">Description*</label>
              <textarea
                className="details-element "
                onChange={(event) =>
                  generalHandleValueChanges(event, setLifeStyle, "Description")
                }
                value={lifeStyle["Description"]}
              />
            </div>
            <div className="detail-content">
              <label className="details-element">Recorded Date & Time*</label>
              <input
                className="details-element "
                type="datetime-local"
                id="meeting-time"
                name="meeting-time"
                value={lifeStyle.RecordedDate.slice(0, 16)}
                onChange={(event) =>
                  generalHandleValueChanges(event, setLifeStyle, "RecordedDate")
                }
              />
              <label className="details-element">End Date & Time</label>
              <input
                className="details-element "
                type="datetime-local"
                id="meeting-time"
                name="meeting-time"
                value={lifeStyle?.EndDate?.slice(0, 16)}
                onChange={(event) =>
                  generalHandleValueChanges(event, setLifeStyle, "EndDate")
                }
                min={lifeStyle.RecordedDate ?? ""}
              />
            </div>
            <button onClick={saveButton}>Add</button>
          </div>
        ) : (
          <>
            <div className="showGoalsContainer">
              {lifeStyleArray && lifeStyleArray.length === 0 ? (
                <CarePlanErrorMessage msg="Patient doesn't have any LifeStyle yet!" />
              ) : (
                lifeStyleArray
                  ?.slice()
                  .reverse()
                  .map((el) => (
                    <div className="goalsDisplay">
                      <div className="goalsText">
                        <div className="hash">#</div> {el.Type}
                      </div>
                      <div className="goalsBtnCont">
                        <div className="goalsBtnWrap">
                          <Icon
                            className="goalsBtn"
                            icon="uil:edit"
                            width="20"
                            height="20"
                            onClick={() => {
                              arrayForEditFn(el);
                              addLifeStyleDetail(true);
                            }}
                          />
                        </div>
                        <div className="goalsBtnWrap">
                          <Icon
                            className="goalsBtn"
                            icon="material-symbols:delete-outline"
                            width="20"
                            height="20"
                            onClick={deleteObjectFromArray.bind(
                              null,
                              el,
                              lifeStyleArray,
                              setLifeStyleArray
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>
            <AddFavComp
              addDefaultFn={addDefaultLifeStyleFn}
              options={options}
              type={"Lifestyle"}
              code={
                selectedPatient?.Diagnosis?.find(
                  (el) => el.Diagnosis_id === uniqueId
                )?.ICD_Code
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DiagnosticLifeStyle;
