import React, { useState, useEffect, useCallback, useRef } from "react";
import TabWidget from "../../components/TabWidget";
import TabElement from "../../components/TabElement";
import InputTextField1 from "../../widgets/InputTextField1";
import InputSelectorField1 from "../../widgets/InputSelectorField1";
import InputTextArea1 from "../../widgets/InputTextArea1";
import DatePickerField1 from "../../widgets/DatePickerField1";
import ButtonComponent from "../../components/ButtonComponent";
import { useFacilityCreationMutation } from "../../app/api/facilityApi";
import FloatingNotifications from "../../utilities/FloatingNotifications";
import { tabChildrenList } from "../../app/features/facilityDataSlice";
import TechnicianAlotmentTab from "../../components/TechnicianAlotmentTab";
import { useGetAccessLevelQuery } from "../../app/api/accessLevelApi";
import { useLocation } from "react-router-dom";
import { useGetCityAndStateMutation } from "../../app/api/zipCityState";
import {
  isNotEmpty,
  alwaysValidated,
  noCharValidateCheck,
  zipValidity,
  zipCharValidate,
  dateValidateCheck,
  phNoValidation,
  phNoCharValidation,
  selectorValidateCheck,
  faxCharValidation,
} from "../../utilities/validationFunction";
import { facilityDataActions } from "../../app/features/facilityDataSlice";
import { useSelector, useDispatch } from "react-redux";

import {
  useGetSelectedFacilityMutation,
  useUpdateFacilityMutation,
} from "../../app/api/facilityApi";

import "./facility.scss";

const error_message = {
  NPI: "NPI should not be empty",
  FacilityName: "FacilityName should not be empty",
  FacilityType: "FacilityType should not be empty",
  BusinessType: "BusinessType should not be empty",
  phone: "phone should not be empty",
  TimeZone: "TimeZone should not be empty",
  Fax: "Fax should not be empty",
  EffectiveDate: "EffectiveDate should not be empty",
  WorkingDays: "WorkingDays should not be empty",
  Notes: "Notes should not be empty",
  street1: "street1 should not be empty",
  street2: "street2 should not be empty",
  postalCode: "postalCode should not be empty",
  city: "city should not be empty",
  state: "state should not be empty",
};

const Facility = () => {
  const location = useLocation();
  const [id, setId] = useState(location?.state?.id);
  const [type, setType] = useState(location?.state?.type);
  const storedData = localStorage?.getItem("RLH_user");
  const createdDateAndTime = new Date();
  const dispatch = useDispatch();
  const facilityData = useSelector((state) => state.facilityData);
  const [accessLevelData, setAccessLevelData] = useState({});
  const [accessDataProvider, setAccessDataProvider] = useState();
  const [accessDataClinician, setAccessDataClinician] = useState();
  const { data: rawAccessLevelData } = useGetAccessLevelQuery();
  const [getCityState, { data: cityState }] = useGetCityAndStateMutation();

  const [createFacility, { data, error, isLoading }] =
    useFacilityCreationMutation();
  const [selectedFacilityCallFn, { data: selectedFacility }] =
    useGetSelectedFacilityMutation();

  const [
    updateselectedFacilityFn,
    {
      data: updatedselectedFacility,
      error: updateError,
      isLoading: updateisLoading,
    },
  ] = useUpdateFacilityMutation();
  const { data: inputData, basicDataValidation, currentTab } = facilityData;

  const [resetRefs, setResetRefs] = useState({
    NPI: useRef(null),
    FacilityName: useRef(null),
    FacilityType: useRef(null),
    BusinessType: useRef(null),
    phone: useRef(null),
    TimeZone: useRef(null),
    Fax: useRef(null),
    EffectiveDate: useRef(null),
    WorkingDaysStart: useRef(null),
    WorkingDaysEnd: useRef(null),
    WorkingDaysStartTime: useRef(null),
    WorkingDaysEndTime: useRef(null),
    Notes: useRef(null),
    street1: useRef(null),
    street2: useRef(null),
    postalCode: useRef(null),
    city: useRef(null),
    state: useRef(null),
  });

  const storeData = useCallback((fieldName, value, validityStatus) => {
    if (Array.isArray(fieldName)) {
      const name = fieldName[0];
      const index = fieldName[1];
      dispatch(
        facilityDataActions.updateWorkingData({
          name,
          index,
          value,
          validityStatus,
        })
      );
    } else
      dispatch(
        facilityDataActions.updateData({ fieldName, value, validityStatus })
      );
  }, []);

  useEffect(() => {
    if (!error || !updateError) return;
    if (error) {
      FloatingNotifications("error", error?.data?.message);
    } else {
      FloatingNotifications("error", updateError?.data?.message);
    }
  }, [error, updateError]);
  const InitData = () => {
    Object.keys(resetRefs).forEach((value) => {
      if (selectedFacility[value]) {
        resetRefs[value].current?.init_data?.(selectedFacility[value]);
      }
    });
    // setAccessDataClinician(selectedFacility?.ClinicianList);
    // setAccessDataProvider(selectedFacility?.ProviderList);
  };
  const onClickReset = () => {
    resetBasicInfo();
  };

  const onClick = () => {
    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value].current.submit();
    });
    let accessDataProviderUpdated = accessDataProvider.filter(
      (object) => object.Selected === true
    );
    let accessDataClinicianUpdated = accessDataClinician.filter(
      (object) => object.Selected === true
    );
    if (selectedFacility && basicDataValidation) {
      updateselectedFacilityFn({
        FacilityId: id,
        ...inputData,
        ProviderList: accessDataProvider,
        ClinicianList: accessDataClinician,
      });
      return;
    }
    if (!basicDataValidation) return;
    createFacility({
      ...inputData,
      ProviderList: accessDataProviderUpdated,
      ClinicianList: accessDataClinicianUpdated,
      OfficeName: "dkjfkjhfkuh",
    });
  };
  // useEffect(() => {
  //   if (!selectedFacility) return;
  //   setAccessDataClinician(selectedFacility.ProviderList);
  //   setAccessDataProvider(selectedFacility.ClinicianList);
  // }, [selectedFacility]);

  useEffect(() => {
    return () => {
      dispatch(facilityDataActions.resetData());
    };
  }, []);

  useEffect(() => {
    if (!selectedFacility) return;
    Object.keys(resetRefs).forEach((value) => {
      if (selectedFacility[value]) {
        resetRefs[value]?.current?.init_data?.(selectedFacility[value]);
      }
    });
    setAccessDataClinician(selectedFacility.ClinicianList);
    setAccessDataProvider(selectedFacility.ProviderList);
  }, [resetRefs, selectedFacility]);

  const resetBasicInfo = () => {
    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value].current.reset_data();
    });
    setAccessDataClinician(accessLevelData["ClinicianList"]);
    setAccessDataProvider(accessLevelData["ProviderList"]);
    resetRefs["EffectiveDate"]?.current?.init_data?.(new Date());
  };

  useEffect(() => {
    if (!data) return;
    FloatingNotifications("success", "Facility creation successful");
    resetBasicInfo();
  }, [data]);

  useEffect(() => {
    if (!updatedselectedFacility) return;
    FloatingNotifications("success", "Facility updation successful");
    resetBasicInfo();
  }, [updatedselectedFacility]);

  useEffect(() => {
    if (!rawAccessLevelData) return;
    setAccessLevelData(rawAccessLevelData);
    // console.log(rawAccessLevelData);
  }, [rawAccessLevelData]);

  useEffect(() => {
    if (!error) return;
    FloatingNotifications("error", error);
  }, [error]);
  useEffect(() => {}, [basicDataValidation]);

  // for facility previewPage

  useEffect(() => {
    if (!id) return;
    selectedFacilityCallFn({
      _id: id,
    });
  }, [id, selectedFacilityCallFn]);

  useEffect(() => {
    getCityState({ zip: inputData.postalCode });
  }, [inputData.postalCode]);

  useEffect(() => {
    if (selectedFacility) return;
    setAccessDataClinician(accessLevelData["ClinicianList"]);
    setAccessDataProvider(accessLevelData["ProviderList"]);
  }, [accessLevelData]);

  useEffect(() => {
    if (!cityState) return;
    resetRefs["city"]?.current?.init_data?.(cityState.city);
    resetRefs["state"]?.current?.init_data?.(cityState.state_name);
  }, [cityState]);

  // useEffect(() => {
  //   if (!selectedFacility) return;
  //   InitData();
  // }, [selectedFacility]);

  useEffect(() => {
    resetRefs["EffectiveDate"]?.current?.init_data?.(new Date());
  }, []);

  return (
    <div className="facility">
      <div className="facilityContainer">
        <div className="pageTitle">Facility</div>

        <>
          <div className="tabWidgetContainer">
            <TabWidget>
              {tabChildrenList.map((el) => (
                <TabElement
                  key={`facilityTab_${el}`}
                  title={el}
                  active={currentTab === el}
                  onClick={() => {
                    dispatch(facilityDataActions.updateCurrentTab(el));
                  }}
                />
              ))}
            </TabWidget>
          </div>
          {tabChildrenList[0] === currentTab && (
            <>
              <div className="facilityBasicInfoContainer1">
                <InputTextField1
                  disabled={type === "View"}
                  tabIndex="1"
                  element={"NPI"}
                  ref={resetRefs["NPI"]}
                  containerClassName="colSpan2"
                  title="NPI"
                  autoFocus={true}
                  validation={alwaysValidated}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["NPI"]}
                  storeData={storeData.bind(null, "NPI")}
                />
                <InputTextField1
                  disabled={type === "View"}
                  tabIndex="1"
                  element={"FacilityName"}
                  ref={resetRefs["FacilityName"]}
                  containerClassName="colSpan4"
                  title="Facility Name"
                  superTitle="*"
                  validation={isNotEmpty}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["FacilityName"]}
                  storeData={storeData.bind(null, "FacilityName")}
                />
                <InputSelectorField1
                  disabled={type === "View"}
                  element={"FacilityType"}
                  tabIndex="1"
                  ref={resetRefs["FacilityType"]}
                  containerClassName="colSpan3"
                  title="Facility Type"
                  superTitle="*"
                  optionsArray={[
                    "General",
                    "Rural Health Clinic (RHC)",
                    "Federally Qualified Health Center (FQHC)",
                  ]}
                  validation={selectorValidateCheck}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["FacilityType"]}
                  storeData={storeData.bind(null, "FacilityType")}
                />

                <InputSelectorField1
                  disabled={type === "View"}
                  element={"BusinessType"}
                  tabIndex="1"
                  ref={resetRefs["BusinessType"]}
                  containerClassName="colSpan3"
                  title="BusinessType"
                  optionsArray={[
                    "Solo Practice",
                    "Group Practice",
                    "Hospital",
                    "Call Center",
                    "Nursing Home",
                    "Home Care",
                    "Senior Resident Community",
                    "Other Healthcare Center",
                  ]}
                  superTitle="*"
                  validation={selectorValidateCheck}
                  error_message={error_message["BusinessType"]}
                  storeData={storeData.bind(null, "BusinessType")}
                />
                <InputTextField1
                  disabled={type === "View"}
                  element={"phone"}
                  tabIndex="1"
                  ref={resetRefs["phone"]}
                  containerClassName="colSpan2"
                  title="Phone Number"
                  superTitle="*"
                  validation={phNoValidation}
                  charValidate={phNoCharValidation}
                  error_message={error_message["phone"]}
                  storeData={storeData.bind(null, "phone")}
                />
                {/* <InputTextField1
                  disabled={type === "View"}
                  element={"TimeZone"}
                  tabIndex="1"
                  ref={resetRefs["TimeZone"]}
                  containerClassName="colSpan2"
                  title="Time Zone"
                  superTitle="*"
                  validation={isNotEmpty}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["TimeZone"]}
                  storeData={storeData.bind(null, "TimeZone")}
                /> */}
                <InputSelectorField1
                  disabled={type === "View"}
                  element={"TimeZone"}
                  tabIndex="1"
                  ref={resetRefs["TimeZone"]}
                  containerClassName="colSpan2"
                  title="TimeZone"
                  optionsArray={[
                    "Eastern Time",
                    "Central Time",
                    "Mountain Time",
                    "Arizona Time(No Daylight Saving)",
                    "Pacific Time",
                    "Alaska Time",
                    "Hawai Time",
                  ]}
                  validation={alwaysValidated}
                  error_message={error_message["TimeZone"]}
                  storeData={storeData.bind(null, "TimeZone")}
                />
                <InputTextField1
                  disabled={type === "View"}
                  element={"Fax"}
                  tabIndex="1"
                  ref={resetRefs["Fax"]}
                  containerClassName="colSpan2"
                  title="Fax"
                  validation={alwaysValidated}
                  charValidate={faxCharValidation}
                  error_message={error_message["Fax"]}
                  storeData={storeData.bind(null, "Fax")}
                />
                <DatePickerField1
                  disabled={type === "View"}
                  element={"EffectiveDate"}
                  tabIndex="1"
                  ref={resetRefs["EffectiveDate"]}
                  containerClassName="colSpan2"
                  title="Effective Date"
                  superTitle="*"
                  validation={dateValidateCheck}
                  error_message={error_message["EffectiveDate"]}
                  storeData={storeData.bind(null, "EffectiveDate")}
                />
                {/* <div className="colSpan2 workingDaysContainer">
                <p className="workingTitle colSpan2">Working Days</p> */}

                <InputSelectorField1
                  disabled={type === "View"}
                  element={"WorkingDays"}
                  tabIndex="1"
                  ref={resetRefs["WorkingDaysStart"]}
                  containerClassName="colSpan1"
                  title="From"
                  superTitle="*"
                  optionsArray={["MON", "TUE", "WED", "THU", "FRI"]}
                  validation={selectorValidateCheck}
                  // initIdentifier={"_id"}
                  error_message={error_message["WorkingDays"]}
                  storeData={storeData.bind(null, "WorkingDaysStart")}
                />
                <InputSelectorField1
                  disabled={type === "View"}
                  element={"WorkingDays"}
                  tabIndex="1"
                  ref={resetRefs["WorkingDaysEnd"]}
                  containerClassName="colSpan1"
                  title="To"
                  superTitle="*"
                  optionsArray={["MON", "TUE", "WED", "THU", "FRI"]}
                  validation={selectorValidateCheck}
                  error_message={error_message["WorkingDays"]}
                  storeData={storeData.bind(null, "WorkingDaysEnd")}
                />
                {/* </div> */}
                {/* <div className="colSpan2 workingTimeContainer">
                <p className="workingTitle colSpan2">Working Time</p> */}

                <InputSelectorField1
                  disabled={type === "View"}
                  element={"WorkingDays"}
                  tabIndex="1"
                  ref={resetRefs["WorkingDaysStartTime"]}
                  containerClassName="colSpan1"
                  title="From"
                  superTitle="*"
                  optionsArray={[
                    "01:00AM",
                    "02:00AM",
                    "03:00AM",
                    "04:00AM",
                    "05:00AM",
                    "06:00AM",
                    "07:00AM",
                    "08:00AM",
                    "09:00AM",
                    "10:00AM",
                    "11:00AM",
                    "12:00AM",
                    "01:00PM",
                    "02:00PM",
                    "03:00PM",
                    "04:00PM",
                    "05:00PM",
                    "06:00PM",
                    "07:00PM",
                    "08:00PM",
                    "09:00PM",
                    "10:00PM",
                    "11:00PM",
                    "12:00PM",
                  ]}
                  validation={selectorValidateCheck}
                  error_message={error_message["WorkingDays"]}
                  storeData={storeData.bind(null, "WorkingDaysStartTime")}
                />
                <InputSelectorField1
                  disabled={type === "View"}
                  element={"WorkingDays"}
                  tabIndex="1"
                  ref={resetRefs["WorkingDaysEndTime"]}
                  containerClassName="colSpan1"
                  title="To"
                  superTitle="*"
                  optionsArray={[
                    "01:00AM",
                    "02:00AM",
                    "03:00AM",
                    "04:00AM",
                    "05:00AM",
                    "06:00AM",
                    "07:00AM",
                    "08:00AM",
                    "09:00AM",
                    "10:00AM",
                    "11:00AM",
                    "12:00AM",
                    "01:00PM",
                    "02:00PM",
                    "03:00PM",
                    "04:00PM",
                    "05:00PM",
                    "06:00PM",
                    "07:00PM",
                    "08:00PM",
                    "09:00PM",
                    "10:00PM",
                    "11:00PM",
                    "12:00PM",
                  ]}
                  validation={selectorValidateCheck}
                  error_message={error_message["WorkingDays"]}
                  storeData={storeData.bind(null, "WorkingDaysEndTime")}
                />
                {/* </div> */}
                <InputTextArea1
                  disabled={type === "View"}
                  element={"Notes"}
                  tabIndex="1"
                  ref={resetRefs["Notes"]}
                  containerClassName="colSpan6 rowSpan2"
                  title="Notes"
                  heightMultiplier={3}
                  validation={alwaysValidated}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["Notes"]}
                  storeData={storeData.bind(null, "Notes")}
                />
                <div className="colSpan6 rowSpan2 ">
                  <div className="created">
                    <p>createdBy: {storedData}</p>
                    <p>
                      createdDate: {createdDateAndTime?.toString().slice(0, 24)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="facilityBasicInfoContainer2">
                <InputTextField1
                  disabled={type === "View"}
                  element={"street1"}
                  tabIndex="1"
                  ref={resetRefs["street1"]}
                  containerClassName="colSpan4"
                  title="Address Line 1"
                  superTitle="*"
                  validation={isNotEmpty}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["street1"]}
                  storeData={storeData.bind(null, "street1")}
                />
                <InputTextField1
                  disabled={type === "View"}
                  element={"street2"}
                  tabIndex="1"
                  ref={resetRefs["street2"]}
                  containerClassName="colSpan4"
                  title="Address Line 2"
                  validation={alwaysValidated}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["street2"]}
                  storeData={storeData.bind(null, "street2")}
                />
                <InputTextField1
                  disabled={type === "View"}
                  element={"postalCode"}
                  tabIndex="1"
                  ref={resetRefs["postalCode"]}
                  containerClassName="colSpan2"
                  title="Zip Code"
                  superTitle="*"
                  validation={zipValidity}
                  charValidate={zipCharValidate}
                  error_message={error_message["postalCode"]}
                  storeData={storeData.bind(null, "postalCode")}
                />
                <InputTextField1
                  disabled={true}
                  element={"city"}
                  tabIndex="1"
                  ref={resetRefs["city"]}
                  containerClassName="colSpan2"
                  title="City"
                  superTitle="*"
                  validation={isNotEmpty}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["city"]}
                  storeData={storeData.bind(null, "city")}
                />
                <InputTextField1
                  disabled={true}
                  element={"state"}
                  tabIndex="1"
                  ref={resetRefs["state"]}
                  containerClassName="colSpan2"
                  title="State"
                  superTitle="*"
                  validation={isNotEmpty}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["state"]}
                  storeData={storeData.bind(null, "state")}
                />
              </div>
              <div className="accessLevelComponenets">
                <div className="accessLevelContainers">
                  <TechnicianAlotmentTab
                    title="Physician"
                    dataFromApi={accessLevelData["ProviderList"] ?? []}
                    idKey={"ProviderId"}
                    accessData={accessDataProvider}
                    setAccessData={setAccessDataProvider}
                    nameKey={"ProviderName"}
                    disabled={type === "View" ? true : false}
                  />
                </div>
                <div className="accessLevelContainers">
                  <TechnicianAlotmentTab
                    title="Clinicians"
                    accessData={accessDataClinician}
                    setAccessData={setAccessDataClinician}
                    dataFromApi={accessLevelData["ClinicianList"] ?? []}
                    idKey={"ClinicianId"}
                    nameKey={"ClinicianName"}
                    disabled={type === "View" ? true : false}
                  />
                </div>
              </div>
              <div className="btnWrap">
                <ButtonComponent
                  tabIndex="2"
                  containerClassName="buttonContainer"
                  text="Save"
                  onClick={onClick}
                  disabled={isLoading}
                />
                <ButtonComponent
                  tabIndex="2"
                  containerClassName="buttonContainer"
                  text="Reset"
                  onClick={onClickReset}
                  // disabled={isLoading}
                />
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default Facility;
