import React, { useState, useEffect } from "react";
import { ReactComponent as Dashboard } from "../images/sideBar/dashboard.svg";
import { ReactComponent as Schedule } from "../images/sideBar/schedule.svg";
import { ReactComponent as Clinic } from "../images/sideBar/clinical.svg";
import { ReactComponent as Patients } from "../images/sideBar/patient.svg";
import { ReactComponent as Billing } from "../images/sideBar/billing.svg";
import { ReactComponent as Account } from "../images/sideBar/account.svg";
import { ReactComponent as Reports } from "../images/sideBar/report.svg";
import { ReactComponent as Market } from "../images/sideBar/market.svg";
import { ReactComponent as Help } from "../images/sideBar/help.svg";
import { ReactComponent as Hospital } from "../images/sideBar/hospital.svg";
import { ReactComponent as CallTaskList } from "../images/sideBar/outline-content-paste.svg";
import SideBarSectionContainer from "./SideBarSectionContainer";
import SideBarElements from "./SideBarElements";
import Menu from "../images/topBar/menu.svg";
import Logo from "../images/logo.svg";
import "./sideBar.scss";
import classNames from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { navigationActions, FOLD } from "../app/features/navigationSlice";

const sideBarStruct = [
  // {
  //   elementType: "SideBarSectionContainer",
  //   title: "Dashboard",
  //   allowed: ["admin"],
  //   Icon: Dashboard,

  //   child: [
  //     {
  //       elementType: "SideBarElements",
  //       name: "Dashboard_1_1",
  //       allowed: ["admin"],
  //     },
  //     {
  //       elementType: "SideBarElements",
  //       name: "Dashboard_1_2",
  //       allowed: ["admin"],
  //     },
  //     {
  //       elementType: "SideBarElements",
  //       name: "Dashboard_1_3",
  //       allowed: ["admin"],
  //     },
  //   ],
  // },

  {
    elementType: "SideBarSectionContainer",
    title: "Dashboard",
    allowed: ["admin", "provider", "clinician"],
    Icon: Dashboard,
    child: [
      {
        elementType: "SideBarElements",
        name: "RPM Dashboard",
        allowed: ["admin", "provider", "clinician"],
        navigateTo: "/rpm-DashBoard",
      },
      {
        elementType: "SideBarElements",
        name: "CCM DashBoard",
        allowed: ["admin", "provider", "clinician"],
        navigateTo: "/",
      },
    ],
  },
  {
    elementType: "SideBarSectionContainer",
    title: "Schedule",
    allowed: ["admin", "clinician", "provider"],
    Icon: Schedule,
    child: [
      // {
      //   elementType: "SideBarElements",
      //   name: "Schedule Calls",
      //   allowed: ["admin"],
      //   navigateTo: "/scheduleCalls",
      // },
      {
        elementType: "SideBarElements",
        name: "View Calendar",
        allowed: ["admin", "clinician", "provider"],
        navigateTo: "/viewCalendar",
      },
      {
        elementType: "SideBarElements",
        name: "Call Task List",
        allowed: ["admin", "clinician", "provider"],
        navigateTo: "/callTask",
      },
    ],
  },
  {
    elementType: "SideBarSectionContainer",
    title: "Clinical",
    allowed: ["admin"],
    Icon: Clinic,
    child: [
      {
        elementType: "SideBarElements",
        name: "Facility",
        allowed: ["admin"],
        navigateTo: "/facility-preview",
      },
      {
        elementType: "SideBarElements",
        name: "Provider",
        allowed: ["admin"],
        navigateTo: "/provider-preview",
      },
      {
        elementType: "SideBarElements",
        name: "clinician",
        allowed: ["admin"],
        navigateTo: "/clinician-preview",
      },
    ],
  },

  {
    elementType: "SideBarSectionContainer",
    title: "Administration",
    allowed: ["admin"],
    Icon: Hospital,
    child: [
      {
        elementType: "SideBarElements",
        name: "Resend Login Credentials",
        allowed: ["admin"],
        navigateTo: "resend-Login-Credentials",
      },
      {
        elementType: "SideBarElements",
        name: "Activate & Deactivate user",
        allowed: ["admin"],
        navigateTo: "user-Actions",
      },
      {
        elementType: "SideBarElements",
        name: "Transfer Patients",
        allowed: ["admin"],
        navigateTo: "/transfer-patients",
      },
      {
        elementType: "SideBarElements",
        name: "Admin",
        allowed: ["admin"],
        navigateTo: "/admin",
      },
    ],
  },
  {
    elementType: "SideBarSectionContainer",
    title: "Patients",
    allowed: ["admin", "provider", "clinician"],
    Icon: Patients,
    child: [
      {
        elementType: "SideBarElements",
        name: "List Eligible Patients & Program",
        allowed: ["admin", "provider", "clinician"],
        navigateTo: "/patients/list-Eligible-Patients",
      },
      {
        elementType: "SideBarElements",
        name: "Load Eligible Patients & Program",
        allowed: ["admin", "provider", "clinician"],
        navigateTo: "/patients/load-Eligible-Patients",
      },

      {
        elementType: "SideBarElements",
        name: "All Patients",
        allowed: ["admin", "provider", "clinician"],
        navigateTo: "/patients",
        navigateState: { state: { type: "All Patients" } },
      },
    ],
  },

  // {
  //   elementType: "SideBarElements",
  //   name: "Billing",
  //   allowed: ["admin"],
  //   Icon: Billing,
  // },
  {
    elementType: "SideBarSectionContainer",
    title: "Billing",
    allowed: ["admin", "provider"],
    Icon: Billing,
    child: [
      {
        elementType: "SideBarElements",
        name: "Service Time Tracker",
        allowed: ["admin", "provider"],
        navigateTo: "/billing",
      },
    ],
  },
  // {
  //   elementType: "SideBarSectionContainer",
  //   title: "RPM ",
  //   allowed: ["admin"],
  //   Icon: Hospital,
  //   child: [
  //     {
  //       elementType: "SideBarElements",
  //       name: "RPM Device List",
  //       allowed: ["admin"],
  //       navigateTo: "rpm-device-list",
  //     },
  //     {
  //       elementType: "SideBarElements",
  //       name: "RPM Vital Reading List",
  //       allowed: ["admin"],
  //       navigateTo: "rpm-vital-reading-list",
  //     },
  //   ],
  // },
  // {
  //   elementType: "SideBarElements",
  //   name: "Account",
  //   allowed: ["admin"],
  //   Icon: Account,
  // },
  // {
  //   elementType: "SideBarElements",
  //   name: "Call Task List",
  //   allowed: ["admin"],
  //   Icon: CallTaskList,
  //   navigateTo: "/callTask",
  // },
  {
    elementType: "SideBarElements",
    name: "Service Log",
    allowed: ["admin"],
    Icon: CallTaskList,
    navigateTo: "/service-Log",
  },

  // {
  //   elementType: "SideBarElements",
  //   name: "Reports",
  //   allowed: ["admin"],
  //   Icon: Reports,
  // },
  // {
  //   elementType: "SideBarElements",
  //   name: "Market",
  //   allowed: ["admin"],
  //   Icon: Market,
  // },
  // {
  //   elementType: "SideBarElements",
  //   name: "Help",
  //   allowed: ["admin"],
  //   Icon: Help,
  // },
];
const SideBar = () => {
  const dispatch = useDispatch();
  const desg = localStorage.getItem("RLH_userType");
  // const [openedSection, setOpenedSection] = useState({});
  const [sideBarStructure, setSideBarStructure] = useState([]);

  const navBarState = useSelector((state) => state.navigationData.navBarOpen);
  const openedSection = useSelector(
    (state) => state.navigationData.openedSection
  );

  // console.log(openedSection);

  // const updateOpenedSection = (type, data) => {
  //   console.log(type, data);
  //   if (type === FOLD[1]) {
  //     setOpenedSection({ [FOLD[1]]: data });
  //   } else if (type == FOLD[2]) {
  //     setOpenedSection((prevData) => ({
  //       [FOLD[1]]: prevData[FOLD[1]],
  //       [FOLD[2]]: data,
  //     }));
  //   } else if (type == FOLD[3]) {
  //     setOpenedSection((prevData) => ({
  //       [FOLD[1]]: prevData[FOLD[1]],
  //       [FOLD[2]]: prevData[FOLD[2]],
  //       [FOLD[3]]: data,
  //     }));
  //   }
  // };
  const evaluateArray = (array, fold) => {
    // console.log(array);
    const val = array
      .filter((el) => el.allowed.includes(desg))
      .map((el) => {
        // console.log(el);
        if (el.elementType === "SideBarSectionContainer") {
          return (
            <SideBarSectionContainer
              title={el.title}
              // onClick={updateOpenedSection}
              openedSection={openedSection}
              type={FOLD[fold]}
              Icon={el.Icon}
              // className={el.className}
              key={`${el.title} ${fold}`}
            >
              {evaluateArray(el.child, fold + 1)}
            </SideBarSectionContainer>
          );
        } else {
          return (
            <SideBarElements
              title={el.name}
              // onClick={updateOpenedSection}
              openedSection={openedSection}
              type={FOLD[fold]}
              key={`${el.title} ${fold}`}
              Icon={el.Icon}
              navigateTo={el.navigateTo}
              navigateState={el.navigateState}
            />
          );
        }
      });
    // console.log(val1, desg);
    return val;
  };
  const onMenuClick = () => {
    dispatch(navigationActions.closeNavBar());
  };

  useEffect(() => {
    setSideBarStructure(sideBarStruct);
  }, []);
  // console.log(navBarState);
  return (
    <div className={classNames("sideBar", { hidden: !navBarState })}>
      <div className="header">
        <img src={Menu} alt="" className="menu" onClick={onMenuClick} />
        <img src={Logo} alt="" className="logo" />
      </div>
      {evaluateArray(sideBarStructure, 1)}
    </div>
  );
};

export default SideBar;
