import React from "react";
import { useState, useEffect } from "react";
import FloatingNotifications from "../utilities/FloatingNotifications";
import SelectComponent from "../components/SelectComponent";
import { useAddBulkServiceMutation } from "../app/api/billingApi";
import { useTimerfinalapiMutation } from "../app/api/timerapifinal";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi";
import {
  generalHandleValueChanges,
  basicValidation,
} from "../patients/Careteam/helperfunctions";
import "./addBulkService.scss";
import TimeSpentInput from "../components/SelectTimeSpentComp";

const AddBulkService = ({ data, cancelfn, getBillingHistory }) => {
  const [addBulkServiceFn, { data: bulkData, error }] =
    useAddBulkServiceMutation(undefined, {
      refetchOnMountOrArgChange: true,
    });
  const initialState = {
    serviceName: "",
    programName: "CCM",
    serviceType: "Manual",
    startTime: "",
    acutalTimeSpent: "00:20:00",
    serviceProviderProfileId: "",
    serviceProviderUser: "clinician",
    remark: "",
  };
  const [service, setService] = useState(initialState);

  const { data: fpcList } = useGetPatientsOptionsQuery();
  const FacilityList =
    fpcList?.FacilityList?.map((facility) => [
      facility.FacilityName,
      facility.FacilityId,
    ]) ?? [];
  FacilityList.unshift(["All", ""]);
  const ProviderList =
    fpcList?.ProviderList?.map((provider) => [
      provider.ProviderName,
      provider.ProviderId,
    ]) ?? [];
  const ClinicianList =
    fpcList?.ClinicianList?.map((clinician) => [
      clinician.ClinicianName,
      clinician.ClinicianId,
    ]) ?? [];

  const getServiceProviderList = () => {
    if (service?.serviceProviderUser === "clinician") {
      return ClinicianList;
    } else if (service?.serviceProviderUser === "provider") {
      return ProviderList;
    } else {
      return [];
    }
  };
  const saveProgram = async () => {
    if (!basicValidation(service)) {
      FloatingNotifications(
        "error",
        "To proceed, all fields must be filled out."
      );
      return;
    }
    let endTime = findEndServiceTimeFn(
      service?.startTime,
      service.acutalTimeSpent
    );

    addBulkServiceFn({
      ...service,
      PatientIds: data.map((patient) => patient?.PatientId),
      endTime: endTime,
      // callStatus: "Call completed",
    });
  };
  const getCurrentDateTimeLocal = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0"); // Months are zero-based
    const day = `${now.getDate()}`.padStart(2, "0");
    const hours = "00";
    const minutes = "00";
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  useEffect(() => {
    if (!bulkData) return;
    setService(initialState);
    FloatingNotifications("success", "Successfully added Services ");

    if (getBillingHistory) {
      getBillingHistory();
    }
    cancelfn();
  }, [bulkData]);

  useEffect(() => {
    if (error) {
      FloatingNotifications("error", "Error adding services");
    }
  }, [error]);
  const findEndServiceTimeFn = (originalTimeString, duration) => {
    const startTime = new Date(originalTimeString);

    // Parse the total time spent string into hours, minutes, and seconds
    const totalTimeSpent = duration;
    const [totalHours, totalMinutes, totalSeconds] = totalTimeSpent
      .split(":")
      .map(Number);

    // Calculate the total duration in milliseconds
    const totalDuration =
      (totalHours * 60 * 60 + totalMinutes * 60 + totalSeconds) * 1000;

    // Calculate the end time
    const endTime = new Date(startTime.getTime() + totalDuration);

    return endTime.toISOString();
  };
  return (
    <div className="addBulkServices">
      <p className="popTitle">{`Add Service `}</p>
      <div className="addContainer">
        <div className=" popElementWide">
          <div className="tableDisplay">
            <div className="head">
              <div className="headElements">S.no</div>
              <div className="headElements">Patient Name</div>
              <div className="headElements">Date of Birth</div>
            </div>
            <div className="contentSec">
              {data?.map((patient, index) => (
                <div className="contentBox">
                  <div className="contentElements">{index + 1}</div>
                  <div className="contentElements">
                    {patient?.patientInfo?.PatientName}
                  </div>
                  <div className="contentElements">
                    {patient?.patientInfo?.DOB.slice(0, 10)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className="popElement">
            <p className="popLabel">Program Name *</p>
            <SelectComponent
              OptionsArray={["CCM", "RPM"]}
              valueData={service.programName}
              fn={generalHandleValueChanges}
              setvalue={setService}
              name={"programName"}
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Service Name *</p>
            <SelectComponent
              OptionsArray={[
                "Scheduling Care Management Call",
                "Non Complex CCM Service",
                "Complex CCM Service",
                "Complex CCM Add on",
                "Prepare Call Summary Notes",
                "Counsel Patient",
                "E-Mail Communication to Patient",
                "Communicating Physician Appointment Requests",
                "Care Plan Entry",
                "Get Encounter Details From EMR for Care Plan Data Entry",
                "Review Care Plan",
                "Review and Add Notes to Care Summary",
                "Review Medication and Modify Prescriptions",
                "Medium to Complex Medical Decision Making",
                "Referral to Other Physicians",
                "Appointment Scheduling for In Person Visit",
                "Phone Communication to Patient Tracked Manually",
                "Call Summary Recommendations",
                "Communicating Appointment Schedule",
                "Directions for Patient Interaction",
                "Communicate Over Phone",
                "Communicate Over E-Mail",
                "Care Plan Entry and Edit",
                "Phone Call With Physician About Patient",
                "E-Mail Communication With Physician Patient",
                "Call from Pharmacy to Discuss Medication Allergies Reported By Patient",
                "Approve Prescription Refill",
                "Share Care Plan",
                "Obtain Encounter Details for Patient Visits",
                "Phone Call With Physician",
                "E-Mail Communication to Physician",
                "Order Lab Test",
                "Review Lab Reports",
                "Conversation About Patient",
                "E-Mail Communication to Care Givers",
                "Phone Communication With Care Givers",
                "Review of Consultant’s Recommendation",
                "Review of Home Health Care Plan",
                "Durable Medical Equipment Review",
                "Review of PT/OT Plan",
                "Patient Vital Reading Measurement and Review",
                "Patient Diet Analysis",
                "Other",
              ]}
              valueData={service.serviceName}
              fn={generalHandleValueChanges}
              setvalue={setService}
              name={"serviceName"}
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Service Provider *</p>
            <SelectComponent
              OptionsArray={["clinician", "provider"]}
              valueData={service.serviceProviderUser}
              fn={generalHandleValueChanges}
              setvalue={setService}
              name={"serviceProviderUser"}
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Assignee Name *</p>
            <select
              type="text"
              className="field"
              value={service.serviceProviderProfileId}
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setService,
                  "serviceProviderProfileId"
                )
              }
            >
              <option value={""}></option>
              {getServiceProviderList()?.map((el) => (
                <option value={el[1]}>{el[0]}</option>
              ))}
            </select>
          </div>
          <div className="popElement">
            <p className="popLabel">Service Date *</p>
            <input
              type="datetime-local"
              id="meeting-time"
              name="meeting-time"
              max={getCurrentDateTimeLocal()}
              value={service?.startTime}
              className="viewElement "
              onChange={(event) =>
                generalHandleValueChanges(event, setService, "startTime")
              }
            />
          </div>
        </div>
        <div>
          <div className="popElement">
            <p className="popLabel">Time Spent (HH:MI:SS) *</p>
            {/* <input
              id="appt-time"
              type="time"
              name="appt-time"
              step="2"
              value={service?.acutalTimeSpent}
              className="viewElement"
              onChange={(event) =>
                generalHandleValueChanges(event, setService, "acutalTimeSpent")
              }
            /> */}
            <TimeSpentInput
              value={service?.acutalTimeSpent}
              setService={setService}
              flagName={"acutalTimeSpent"}
            />
          </div>
          <div className="popElement">
            <p className="popLabel">Remark *</p>
            <textarea
              type="textarea"
              value={service.remark}
              className="viewElement "
              onChange={(event) =>
                generalHandleValueChanges(event, setService, "remark")
              }
            />
          </div>
        </div>
      </div>
      <div className="popBtn">
        <button onClick={() => saveProgram()}>Save</button>
        {/* <button onClick={cancelfn}>Cancel</button> */}
      </div>
    </div>
  );
};

export default AddBulkService;
