import React from "react";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import PopUp from "../patients/patientViewPage/PopUp";
import AddShedule from "./AddShedule";
import Weekcomp from "./Weekcomp";
import Daycomp from "./Daycomp";
import FloatingNotifications from "../utilities/FloatingNotifications";
import { useCreateScheduleMutation } from "../app/api/scheduleCallApi";
import PatientInteractionPage from "./patientInteractionSelection/PatientInteractionPage";
import EventViewer from "./EventViewer";
import SelectComponent from "../components/SelectComponent";
import { useRescheduleApiMutation } from "../app/api/scheduleCallApi";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
import { useGetScheduleListMutation } from "../app/api/scheduleCallApi";
import "./Calendar.scss";
import LoaderComponent from "../components/LoaderComponent";
import {
  startOfWeek,
  endOfWeek,
  getMonth,
  addDays,
  format,
  parseISO,
  startOfMonth,
  subMonths,
  endOfMonth,
  isSameMonth,
  getYear,
  isEqual,
  addMonths,
  subWeeks,
  addWeeks,
  isAfter,
  subDays,
} from "date-fns";
import { useState } from "react";
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Calendar = () => {
  const [window, setWindow] = useState("month");
  const [filter, setFilter] = useState({ programName: "CCM" });

  let presentDay = new Date();
  const [getScheduleListFn, { data: scheduleList, isLoading: loadingList }] =
    useGetScheduleListMutation();
  const [
    resheduleFn,
    {
      data: reshuledStatus,
      isLoading: resheduleIsLoading,
      error: errorResheduling,
    },
  ] = useRescheduleApiMutation();
  const [addSchedule, { data: scheduleDataLatest, isLoading, error }] =
    useCreateScheduleMutation();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [monthYear, setMonthYear] = useState();
  const [shedulePopUp, setShedulePopUp] = useState(false);
  const [monthDates, setMonthDates] = useState([]);
  const [sheduleDate, setSheduleDate] = useState([]);
  const [sheduleData, setSheduleData] = useState([]);
  const [viewEvent, setViewEvent] = useState();
  const [updateSheduleData, setUpdateSheduleData] = useState();

  const changeDate = (val) => {
    if (window === "month") {
      switch (val) {
        case "previous":
          setCurrentDate(subMonths(currentDate, 1));
          break;
        case "next":
          setCurrentDate(addMonths(currentDate, 1));
          break;
        default:
          return;
      }
    } else if (window === "week") {
      switch (val) {
        case "previous":
          setCurrentDate(subWeeks(currentDate, 1));
          break;
        case "next":
          setCurrentDate(addWeeks(currentDate, 1));
          break;
        default:
          return;
      }
    } else if (window === "day") {
      switch (val) {
        case "previous":
          setCurrentDate(subDays(currentDate, 1));
          break;
        case "next":
          setCurrentDate(addDays(currentDate, 1));
          break;
        default:
          return;
      }
    }
  };

  const onClickReshedule = (sheduleInfo) => {
    setViewEvent();
    setShedulePopUp(true);
    setUpdateSheduleData(sheduleInfo);
  };
  const createScheduleFn = (payload) => {
    addSchedule(payload);
  };
  const [callPatientPopUp, setCallPatientPopUp] = useState(false);
  const [eventData, setEventData] = useState("");

  const onClickCallPatient = () => {
    setCallPatientPopUp(true);
    closeFn();
  };

  const closeFn = () => {
    setViewEvent();
    getShedule();
  };
  const cancelfn = () => {
    setShedulePopUp(false);
    setUpdateSheduleData();
  };
  const clickForShedule = (day) => {
    if (day) {
      if (isAfter(parseISO(format(presentDay, "yyyy-MM-dd")), parseISO(day)))
        return;
      setSheduleDate(day);
      setShedulePopUp(true);
    } else {
      setShedulePopUp(true);
    }
  };
  useEffect(() => {
    if (!reshuledStatus) return;
    FloatingNotifications(
      "success",
      "The call hase been resheduled successfully"
    );
    getShedule();
  }, [reshuledStatus]);
  useEffect(() => {
    if (!errorResheduling) return;
    FloatingNotifications("error", "Somethingh went wrong");
  }, [errorResheduling]);
  useEffect(() => {
    let startDateOfMonth = startOfMonth(currentDate);
    let endDateOfMonth = endOfMonth(currentDate);
    endDateOfMonth = endOfWeek(endDateOfMonth);
    startDateOfMonth = startOfWeek(startDateOfMonth);
    let year = getYear(currentDate);
    let MonthName = monthNames[getMonth(currentDate)];
    setMonthYear(`${MonthName} ${year}`);
    let week = [];
    let date = startDateOfMonth;
    while (date <= endDateOfMonth) {
      //   week.push(date);
      week.push(format(date, "yyyy-MM-dd"));
      date = addDays(date, 1);
    }
    setMonthDates(week);
  }, [currentDate]);
  const viewEventFn = (e, event) => {
    e.stopPropagation();
    setEventData(event);
    setViewEvent(event);
  };
  const getShedule = () => {
    getScheduleListFn({ programName: filter.programName });
  };
  useEffect(() => {
    getShedule();
  }, [scheduleDataLatest, filter]);

  const formatTimeTo12Hour = (timeString) => {
    let date = new Date(timeString);

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    let formattedHours = hours % 12;
    formattedHours = formattedHours === 0 ? 12 : formattedHours;

    const formattedTime = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;

    return formattedTime;
  };

  const ChangeDate = (element) => {
    let d = element.StartDateTime.split("T");
    let [date, time] = d;
    const formattedTime = formatTimeTo12Hour(element.StartDateTime);

    const modifiedElement = { ...element };
    modifiedElement.time = formattedTime;
    modifiedElement.Date = date;
    delete modifiedElement.StartDateTime;
    return modifiedElement;
  };
  useEffect(() => {
    if (!scheduleList) return;
    let scheduleListupdated = scheduleList.map((el) => ChangeDate(el));
    scheduleListupdated = scheduleListupdated.filter(
      (el) => el.callStatus !== "canceled"
    );
    setSheduleData(scheduleListupdated);
  }, [scheduleList]);

  useEffect(() => {
    if (!scheduleDataLatest) return;
    FloatingNotifications("success", "Event added successfully");
    setSheduleData((prev) => [...prev, scheduleDataLatest]);
  }, [scheduleDataLatest]);
  return (
    <div className="calMainCont">
      <LoaderComponent
        isLoading={resheduleIsLoading || isLoading || loadingList}
      />
      {shedulePopUp && (
        <PopUp cancelfn={cancelfn} width={"80vw"}>
          <AddShedule
            createScheduleFn={createScheduleFn}
            sheduleDate={sheduleDate}
            setSheduleData={setSheduleData}
            cancelfn={cancelfn}
            sheduleData={sheduleData}
            programName={filter.programName}
            updateSheduleData={updateSheduleData}
            resheduleFn={resheduleFn}
          />
        </PopUp>
      )}
      {viewEvent && (
        <EventViewer
          viewEvent={viewEvent}
          closeFn={closeFn}
          onClickCallPatient={onClickCallPatient}
          onClickReshedule={onClickReshedule}
        />
      )}
      {callPatientPopUp && (
        <PatientInteractionPage
          eventData={eventData}
          setCallPatientPopUp={setCallPatientPopUp}
          setEventData={setEventData}
          id={eventData?.patientId?._id}
        />
      )}
      <div className="advanceSearchBox">
        <div className="box">
          <p className="head">Program</p>
          <SelectComponent
            OptionsArray={["CCM", "RPM", "BHI"]}
            fn={generalHandleValueChanges}
            valueData={filter.programName}
            setvalue={setFilter}
            name={"programName"}
          />
        </div>
      </div>
      <div className="createButton">
        <button
          className="createSheduleButton"
          onClick={() => clickForShedule()}
        >
          <Icon icon="simple-line-icons:calender" />
          <p>Create Shedule</p>
        </button>
      </div>
      <div className="calenderHeader">
        <div className="calenderIcon">
          <button onClick={() => changeDate("previous")}>
            <Icon icon="iconamoon:arrow-left-2-bold" />
          </button>
          <button onClick={() => changeDate("next")}>
            <Icon icon="iconamoon:arrow-left-2-bold" rotate={2} />
          </button>
        </div>
        <p className="monthTitle">{monthYear}</p>
        <div className="navCalender">
          <p
            className={classNames("ele", { active: window === "month" })}
            onClick={() => setWindow("month")}
          >
            month
          </p>
          <p
            className={classNames("ele", { active: window === "week" })}
            onClick={() => setWindow("week")}
          >
            week
          </p>
          <p
            className={classNames("ele", { active: window === "day" })}
            onClick={() => setWindow("day")}
          >
            day
          </p>
          {/* <p
            className={classNames("ele", {
              active: window === "August Month Untill Today",
            })}
            onClick={() => setWindow("August Month Untill Today")}
          >
            August Month Untill Today
          </p> */}
        </div>
      </div>
      {window === "month" && (
        <div className="calendarBody">
          <div className="day">Sunday</div>
          <div className="day">Monday</div>
          <div className="day">Tuesday</div>
          <div className="day">Wednesday</div>
          <div className="day">Thursday</div>
          <div className="day"> Friday</div>
          <div className="day"> Saturday</div>

          {monthDates.map((day) => (
            <div
              className={classNames(
                "dayComponent",
                {
                  isMonth: !isSameMonth(
                    parseISO(format(currentDate, "yyyy-MM-dd")),
                    parseISO(day)
                  ),
                },
                {
                  present: isEqual(
                    parseISO(format(presentDay, "yyyy-MM-dd")),
                    parseISO(day)
                  ),
                }
              )}
              onClick={() => clickForShedule(day)}
            >
              <p>{day.slice(-2)}</p>
              <div className="eventComp">
                {sheduleData &&
                  sheduleData
                    ?.filter((ele) => ele.Date === day)
                    ?.map((event) => (
                      <p
                        className="eventTitle"
                        onClick={(e) => viewEventFn(e, event)}
                      >
                        {event.callPurpose}
                      </p>
                    ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {window === "week" && (
        <Weekcomp
          day={currentDate}
          sheduleData={sheduleData}
          setSheduleData={setSheduleData}
          setCallPatientPopUp={setCallPatientPopUp}
          setEventData={setEventData}
        />
      )}
      {window === "day" && (
        <Daycomp
          day={currentDate}
          sheduleData={sheduleData}
          setSheduleData={setSheduleData}
          setCallPatientPopUp={setCallPatientPopUp}
          setEventData={setEventData}
        />
      )}
    </div>
  );
};

export default Calendar;
