import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Icon } from "@iconify/react";
import classNames from "classnames";
import "./../sass/selectComponent.scss";
import { useEffect, useMemo } from "react";

const SelectSearch = ({
  disabled,
  OptionsArray,
  OptionsDict,
  valueData,
  fn,
  setvalue,
  name,
  func,
  placeholder,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option, displayName) => {
    setIsOpen(false);
    setSelectedOption(option);
    if (func) {
      func(option);
      return;
    }
    if (displayName) {
      setDisplayName(displayName);
    }
    fn(option, setvalue, name);
  };
  const firstClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  console.log(valueData);
  useEffect(() => {
    console.log(valueData);
    if (valueData === "") {
      setDisplayName("All");
    }
    if (!valueData) return;
    setSelectedOption(valueData);
  }, [valueData]);

  return (
    <div
      className={classNames("selectsearch", disabled ? "disabledSelector" : "")}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="dropFlex">
          <div
            className="spanElementdiv"
            onClick={!disabled ? firstClick : null}
          >
            <div className="selected">
              {placeholder && !selectedOption
                ? placeholder
                : displayName
                ? displayName
                : selectedOption}
            </div>
            <Icon className="icon" icon="ep:arrow-down-bold" />
            {isOpen && (
              <div className="dropdown">
                <ul className="options">
                  {OptionsArray?.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        handleOptionClick(option);
                      }}
                    >
                      {option}
                    </li>
                  ))}
                  {OptionsDict?.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        handleOptionClick(option[1], option[0]);
                      }}
                    >
                      {option[0]}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

const SelectComponent2 = React.memo(SelectSearch);
export default SelectComponent2;
