import React from "react";
import { useState, useEffect } from "react";
import { generalHandleValueChanges } from "../Careteam/helperfunctions";
import SelectComponent from "../../components/SelectComponent";
import { useGetCityAndStateMutation } from "../../app/api/zipCityState";
import FloatingNotifications from "../../utilities/FloatingNotifications";
const genderList = ["Male", "Female", "Other", "Unknown"];
const ethnicityList = [
  "Hispanic or Latino",
  "Not Hispanic or Not Latino",
  "Patient declined to specify",
  "No Ethnicity ",
];
const stateList = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const Demographics = ({ selectedData, updatePatientfn, id }) => {
  const [dataObject, setDataObject] = useState({
    Gender: "",
    DOB: "",
    // TimeZone: "",
    Ethnicity: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    PreferredLanguage: "",
    createdAt: "",
    ZipCode: "",
    MedicareEnrolmentDate: "",
  });
  const [getCityState, { data: cityState }] = useGetCityAndStateMutation();

  const Updatebtn = () => {
    if (dataObject.DOB === "" || dataObject.street1 === "") {
      FloatingNotifications("error", "Fill required fields to proceed");

      return;
    }
    const { ZipCode, createdAt, updatedAt, ...res } = dataObject;
    updatePatientfn({
      PatientId: id,
      ...res,
      postalCode: ZipCode,
      MedicareEnrolmentDate: dataObject?.MedicareEnrolmentDate,
    });
  };
  useEffect(() => {
    getCityState({ zip: dataObject.ZipCode });
  }, [dataObject.ZipCode]);

  useEffect(() => {
    if (!cityState) return;
    setDataObject((prev) => ({
      ...prev,
      state: cityState.state_name,
      city: cityState.city,
    }));
  }, [cityState]);

  useEffect(() => {
    if (!selectedData) return;
    let r = Object.keys(dataObject);
    r.map((el) => {
      setDataObject((prev) => ({
        ...prev,
        [el]: selectedData?.[el],
      }));
    });
    setDataObject((prev) => ({
      ...prev,
      ZipCode: selectedData?.postalCode,
      DOB: selectedData?.DOB.split("T")[0],
      MedicareEnrolmentDate: selectedData?.MedicareEnrolmentDate,
    }));
  }, [selectedData]);

  return (
    <>
      <p className="TitleElement">
        <p></p>
        <button className="Updatebtn" onClick={Updatebtn}>
          Save
        </button>
      </p>
      <div className="container">
        <div className="grid">
          <div className="lineElement">
            <label className="label">Gender</label>
            <div className="field2">
              <SelectComponent
                OptionsArray={genderList}
                fn={generalHandleValueChanges}
                valueData={dataObject.Gender}
                setvalue={setDataObject}
                name={"Gender"}
              />
            </div>
          </div>
          <div className="lineElement">
            <label className="label">DOB*</label>
            <input
              type="date"
              className="field"
              max={new Date().toISOString().split("T")[0]}
              value={dataObject?.DOB}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "DOB")
              }
            />
          </div>

          <div className="lineElement">
            <label className="label">Preferred Language</label>
            <div className="field2">
              <SelectComponent
                OptionsArray={["English", "Spanish"]}
                fn={generalHandleValueChanges}
                valueData={dataObject.PreferredLanguage}
                setvalue={setDataObject}
                name={"PreferredLanguage"}
              />
            </div>
          </div>

          <div className="lineElement">
            <label className="label">Patient Creation Date</label>
            <input
              type="date"
              className="field"
              value={dataObject?.createdAt.slice(0, 10)}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "createdAt")
              }
            />
          </div>
          <div className="lineElement">
            <label className="label">Medicare Enrolment Date</label>
            <input
              type="text"
              className="field"
              value={dataObject?.MedicareEnrolmentDate}
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setDataObject,
                  "MedicareEnrolmentDate"
                )
              }
            />
          </div>
        </div>
        <div className="grid">
          <div className="lineElement">
            <label className="label">Address Street 1*</label>
            <input
              type="text"
              className="field"
              value={dataObject.street1}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "street1")
              }
            />
          </div>{" "}
          <div className="lineElement">
            <label className="label">Street 2</label>
            <input
              type="text"
              className="field"
              value={dataObject.street2}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "street2")
              }
            />
          </div>
          {/* <div className="lineElement">
            <label className="label">Street 1</label>
            <div className="field2">
              <SelectComponent
                OptionsArray={stateList}
                fn={generalHandleValueChanges}
                valueData={dataObject.street2}
                setvalue={setDataObject}
                name={"street1"}
              />
            </div>
          </div> */}
          {/* <div className="lineElement">
            <label className="label">Street 2</label>
            <div className="field2">
              <SelectComponent
                OptionsArray={ethnicityList}
                fn={generalHandleValueChanges}
                valueData={dataObject.street2}
                setvalue={setDataObject}
                name={"street2"}
              />
            </div>
          </div> */}
          <div className="lineElement">
            <label className="label">ZipCode</label>
            <input
              type="number"
              className="field"
              value={dataObject.ZipCode}
              onChange={(event) =>
                generalHandleValueChanges(event, setDataObject, "ZipCode")
              }
            />
          </div>
          <div className="lineElement">
            <label className="label">City</label>
            <div className="field2">
              <SelectComponent
                disabled={true}
                OptionsArray={stateList}
                fn={generalHandleValueChanges}
                valueData={dataObject.city}
                setvalue={setDataObject}
                name={"city"}
              />
            </div>
          </div>
          <div className="lineElement">
            <label className="label">State</label>
            <div className="field2">
              <SelectComponent
                disabled={true}
                OptionsArray={stateList}
                fn={generalHandleValueChanges}
                valueData={dataObject.state}
                setvalue={setDataObject}
                name={"state"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Demographics;
