/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useRef, useCallback, useEffect, useMemo } from "react";
import TabWidget from "../components/TabWidget";
import TabElement from "../components/TabElement";
import { useSelector, useDispatch } from "react-redux";
import { tabChildrenList } from "../app/features/patientsDataSlice";
import { patientsDataActions } from "../app/features/patientsDataSlice";
import { patientsInsuranceDataActions } from "../app/features/patientsInsuranseDataSlice";
import InputCheckField1 from "../widgets/InputCheckField1";
import InputTextArea1 from "../widgets/InputTextArea1";
import InputSelectorField1 from "../widgets/InputSelectorField1";
import InputSelectorField2 from "../widgets/InputSelectorField2";
import InputTextField1 from "../widgets/InputTextField1";
import DatePickerField1 from "../widgets/DatePickerField1";
import ButtonComponent from "../components/ButtonComponent";
import PatientsInsurance from "./patientsInsurance/PatientsInsurance";
import FloatingNotifications from "../utilities/FloatingNotifications";
import { usePatientsAddDetailsMutation } from "../app/api/patientsApi";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi";
import { useGetAccessLevelQuery } from "../app/api/accessLevelApi";
import { useLocation } from "react-router-dom";
import { useGetPatientSelectedProfileMutation } from "../app/api/patientsApi";
import AddPatient from "./parientsPreviewPage/addPatientComponent/AddPatient";
import { useUpdatePatientsMutation } from "../app/api/patientsApi";
import Program from "./patientViewPage/Program";
import { useGetCityAndStateMutation } from "../app/api/zipCityState";
import {
  isNotEmpty,
  alwaysValidated,
  noCharValidateCheck,
  zipValidity,
  zipOrEmptyValidity,
  zipCharValidate,
  dateValidateCheck,
  fileSizeValidation,
  selectorValidateCheck,
  phNoValidation,
  phNoCharValidation,
  emailValidation,
  alternateEmailValidation,
  faxCharValidation,
  phNoOrEmptyValidationorEmpty,
} from "../utilities/validationFunction";

import "./patients.scss";

const error_message = {
  FacilityId: "Facility should not be empty",
  ProviderId: "Provider should not be empty",
  PrimaryCoordinator: "Primary Coordinator should not be empty",
  DOB: "Date of Birth should not be empty",
  FirstName: "FirstName should not be empty",
  LastName: "LastName should not be empty",
  Gender: "Gender should not be empty",
  phone: "Phone number should not be empty",
  street1: "  Address should not be empty",
};
const Patients = () => {
  const location = useLocation();
  const [id, setId] = useState(location?.state?.id);
  // console.log(id);
  const type = location?.state?.type;
  const [selectedProfilefn, { data: selectedData }] =
    useGetPatientSelectedProfileMutation();
  const [getCityState, { data: cityState }] = useGetCityAndStateMutation();

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [enrolledData, setEnrolledData] = useState([]);
  const [resetRefs, setResetRefs] = useState({
    // PatientId: useRef(null),
    FacilityId: useRef(null),
    ProviderId: useRef(null),
    PatientEHRId: useRef(null),
    PrimaryCoordinator: useRef(null),
    SSN: useRef(null),
    MedicareEnrolmentDate: useRef(null),
    FirstName: useRef(null),
    MiddleName: useRef(null),
    LastName: useRef(null),
    Suffix: useRef(null),
    DOB: useRef(null),
    maritalStatus: useRef(null),
    email: useRef(null),
    emailAlternative: useRef(null),
    phone: useRef(null),
    homePhone: useRef(null),
    workPhone: useRef(null),
    Ethnicity: useRef(null),
    PreferredLanguage: useRef(null),
    PreferredCommunication: useRef(null),
    street1: useRef(null),
    street2: useRef(null),
    city: useRef(null),
    state: useRef(null),
    country: useRef(null),
    postalCode: useRef(null),
    Gender: useRef(null),
    emergencyContactName: useRef(null),
    emergencyContactPhone: useRef(null),
    emergencyContactRelation: useRef(null),
    patientPhoto: useRef(null),
  });
  // const [notAllowEmptyUpdate,setNotAllowEmptyUpdate] = useState(true);

  const dispatch = useDispatch();
  const patientsData = useSelector((state) => state.patientsData);
  const patientsInsusanceData = useSelector(
    (state) => state.patientsInsuranceData
  );

  // for patients selector option
  const { data: fpcList } = useGetPatientsOptionsQuery();
  // console.log(fpcList);

  const FacilityList = fpcList?.FacilityList ?? [];
  const ProviderList = fpcList?.ProviderList ?? [];
  const ClinicianList = fpcList?.ClinicianList ?? [];

  const [createPatient, { data, error }] = usePatientsAddDetailsMutation();
  const { data: inputData, basicDataValidation, currentTab } = patientsData;
  const [
    updatePatientfn,
    {
      data: updatedData,
      error: updatedDataError,
      isLoading: updatedataLoading,
    },
  ] = useUpdatePatientsMutation();
  const [triggerEffect, setTriggerEffect] = useState(false);

  const [updateCallValue, setUpdateValue] = useState();

  const storeData = useCallback((fieldName, value, validityStatus) => {
    dispatch(
      patientsDataActions.updateData({ fieldName, value, validityStatus })
    );
  }, []);

  const updatebtnCall = (value) => {
    setUpdateValue(value);
    setTriggerEffect(true);
  };
  const onClickFn = () => {
    const payload = {
      ...inputData,
      firstInsuranceData: {
        ...patientsInsusanceData.insurance.firstInsuranceData,
      },
      secondInsuranceData: {
        ...patientsInsusanceData.insurance.secondInsuranceData,
      },
      thirdInsuranceData: {
        ...patientsInsusanceData.insurance.thirdInsuranceData,
      },
      enrolledPrograms: enrolledData ? enrolledData : "",
    };
    if (selectedData) {
      let updatedProgram = payload.enrolledPrograms.map((program) => {
        const { _id, ...restOfProgram } = program; // Remove _id property
        return restOfProgram;
      });
      // console.log(updatedPayload);
      updatePatientfn({
        PatientId: id,
        ...payload,
        enrolledPrograms: updatedProgram,
      });
    } else {
      createPatient(payload);
    }
  };

  const onClickcheck = () => {
    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value]?.current?.submit?.();
    });

    if (!basicDataValidation) {
      console.log(basicDataValidation);

      return;
    }
    // createPatient(inputData);
    dispatch(patientsDataActions.updateCurrentTab("Insurance"));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) {
      FloatingNotifications("error", "File should be less than 5 MB");
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // The result contains the base64 string
        dispatch(patientsDataActions.updatePatientPhoto(reader.result));
      };
      reader.readAsDataURL(file);
    }
  };

  const resetBasicInfo = () => {
    // console.log('Resetting info')
    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value]?.current?.reset_data?.();
    });
  };

  useEffect(() => {
    return () => {
      dispatch(patientsDataActions.resetData());
      dispatch(patientsInsuranceDataActions.resetData());
    };
  }, []);

  useEffect(() => {
    if (!data) return;
    // resetBasicInfo();
    dispatch(patientsDataActions.resetData());
    dispatch(patientsInsuranceDataActions.resetData());
    setEnrolledData([]);
    FloatingNotifications("success", "Patient details added successfully");
  }, [data]);

  useEffect(() => {
    if (!updatedData) return;
    // resetBasicInfo();
    dispatch(patientsDataActions.resetData());
    dispatch(patientsInsuranceDataActions.resetData());
    setEnrolledData([]);
    FloatingNotifications("success", "Patient details updated successfully");
  }, [updatedData]);

  const InitData = () => {
    Object.keys(resetRefs).forEach((value) => {
      // if (value === "insuranceCompany") return;

      resetRefs[value].current?.init_data?.(inputData[value]);
    });
  };
  useEffect(() => {
    getCityState({ zip: inputData.postalCode });
  }, [inputData.postalCode]);

  useEffect(() => {
    if (!cityState) return;
    resetRefs["city"]?.current?.init_data?.(cityState.city);
    resetRefs["state"]?.current?.init_data?.(cityState.state_name);
  }, [cityState]);

  useEffect(() => {
    if (patientsData.currentTab === tabChildrenList[0]) {
      InitData();
    }
  }, [patientsData.currentTab]);

  useEffect(() => {
    if (error) {
      FloatingNotifications("error", error?.data?.message);
    } else if (updatedDataError) {
      FloatingNotifications("error", updatedDataError?.data?.message);
    }
  }, [error, updatedDataError]);

  useEffect(() => {
    if (!id) return;
    selectedProfilefn({
      PatientId: id,
    });
  }, [id, selectedProfilefn]);

  useEffect(() => {
    if (!selectedData) return;
    Object.keys(resetRefs).forEach((value) => {
      if (selectedData[value]) {
        resetRefs[value]?.current?.init_data?.(selectedData[value]);
      }
    });
  }, [resetRefs, selectedData]);

  // useEffect(() => {
  //   if (selectedData) return;
  //   resetRefs["PatientId"]?.current?.default_data?.(uuidv4());
  // }, [resetRefs, selectedData]);

  useEffect(() => {
    if (!selectedData) return;
    setEnrolledData(selectedData?.enrolledPrograms);
    dispatch(
      patientsDataActions.updatePatientPhoto(selectedData?.patientPhoto)
    );
  }, [selectedData]);

  return (
    <div className="patients">
      <AddPatient
        selectedData={selectedData}
        updateCallValue={updateCallValue}
        triggerEffect={triggerEffect}
        setTriggerEffect={setTriggerEffect}
        updatePatientfn={updatePatientfn}
        id={id}
        popUpOpen={popUpOpen}
        setPopUpOpen={setPopUpOpen}
        enrolledData={enrolledData}
        setEnrolledData={setEnrolledData}
      />
      <div className="patientsContainer">
        <div className="pageTitle">Patient</div>
        <div className="tabWidgetContainer">
          <TabWidget>
            {tabChildrenList?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={currentTab === el}
                onClick={() => {
                  dispatch(patientsDataActions.updateCurrentTab(el));
                }}
              />
            ))}
          </TabWidget>
          {/* <button className="addButton" onClick={() => setPopUpOpen(true)}>
            +Add Program
          </button> */}
        </div>
        {tabChildrenList[0] === currentTab && (
          <>
            <div className="patientsBasicInfoContainer1">
              {/* <InputTextField1
                disabled={true}
                tabIndex="1"
                element={"PatientId"}
                ref={resetRefs["PatientId"]}
                containerClassName="colSpan3"
                title="PatientId"
                validation={alwaysValidated}
                error_message={error_message["PatientId"]}
                storeData={storeData.bind(null, "PatientId")}
              /> */}
              <InputTextField1
                disabled={type === "View"}
                element={"PatientEHRId"}
                tabIndex="1"
                ref={resetRefs["PatientEHRId"]}
                containerClassName="colSpan3 "
                title="Patient Id"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["PatientEHRId"]}
                storeData={storeData.bind(null, "PatientEHRId")}
              />
              <InputSelectorField2
                disabled={type === "View"}
                tabIndex="1"
                element={"FacilityId"}
                ref={resetRefs["FacilityId"]}
                containerClassName="colSpan3"
                title="Facility"
                superTitle="*"
                autoFocus={true}
                validation={selectorValidateCheck}
                optionsDictionay={FacilityList}
                selectorName={"FacilityName"}
                selectorValue={"FacilityId"}
                error_message={error_message["FacilityId"]}
                storeData={storeData.bind(null, "FacilityId")}
              />
              <InputSelectorField2
                disabled={type === "View"}
                tabIndex="1"
                element={"ProviderId"}
                ref={resetRefs["ProviderId"]}
                containerClassName="colSpan3"
                title="PrimaryProvider/NP"
                superTitle="*"
                validation={selectorValidateCheck}
                optionsDictionay={ProviderList}
                selectorName={"ProviderName"}
                selectorValue={"ProviderId"}
                error_message={error_message["ProviderId"]}
                storeData={storeData.bind(null, "ProviderId")}
              />
              <InputSelectorField2
                disabled={type === "View"}
                element={"PrimaryCoordinator"}
                tabIndex="1"
                ref={resetRefs["PrimaryCoordinator"]}
                containerClassName="colSpan3"
                title="Primary Coordinator"
                superTitle="*"
                validation={selectorValidateCheck}
                optionsDictionay={ClinicianList}
                selectorName={"ClinicianName"}
                selectorValue={"ClinicianId"}
                error_message={error_message["PrimaryCoordinator"]}
                storeData={storeData.bind(null, "PrimaryCoordinator")}
              />
              <InputTextField1
                disabled={type === "View"}
                element={"SSN"}
                tabIndex="1"
                ref={resetRefs["SSN"]}
                containerClassName="colSpan2"
                title="SSN"
                validation={phNoOrEmptyValidationorEmpty}
                charValidate={phNoCharValidation}
                error_message={error_message["SSN"]}
                storeData={storeData.bind(null, "SSN")}
              />
              <InputTextField1
                disabled={type === "View"}
                element={"MedicareEnrolmentDate"}
                tabIndex="1"
                ref={resetRefs["MedicareEnrolmentDate"]}
                containerClassName="colSpan3"
                title="Medicare Enrolment Date"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["MedicareEnrolmentDate"]}
                storeData={storeData.bind(null, "MedicareEnrolmentDate")}
              />
              <DatePickerField1
                disabled={type === "View"}
                element={"DOB"}
                tabIndex="1"
                ref={resetRefs["DOB"]}
                maxValue={new Date()}
                containerClassName="colSpan2"
                title="DOB"
                superTitle="*"
                validation={dateValidateCheck}
                error_message={error_message["DOB"]}
                storeData={storeData.bind(null, "DOB")}
              />
              <InputSelectorField1
                disabled={type === "View"}
                element={"maritalStatus"}
                tabIndex="1"
                ref={resetRefs["maritalStatus"]}
                containerClassName="colSpan2"
                title="Marital Status"
                optionsArray={[
                  "single",
                  "married",
                  "widowed",
                  "divorced",
                  "seperated",
                ]}
                validation={alwaysValidated}
                error_message={error_message["maritalStatus"]}
                storeData={storeData.bind(null, "maritalStatus")}
              />
              {/* <InputTextField1
                disabled={type === "View"}
                element={"patientPhoto"}
                tabIndex="1"
                type="file"
                ref={resetRefs["patientPhoto"]}
                containerClassName="colSpan3"
                title="patientPhoto"
                superTitle="*"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["patientPhoto"]}
                storeData={storeData.bind(null, "patientPhoto")}
              /> */}
              <div className="patienPhoto colSpan3">
                <div className="title">Patient Photo</div>

                <input
                  disabled={type === "View"}
                  type={"file"}
                  tabIndex="1"
                  onChange={handleFileChange}
                />
              </div>
              <InputTextField1
                disabled={type === "View"}
                element={"FirstName"}
                tabIndex="1"
                ref={resetRefs["FirstName"]}
                containerClassName="colSpan3 "
                title="First Name"
                superTitle="*"
                validation={isNotEmpty}
                charValidate={noCharValidateCheck}
                error_message={error_message["FirstName"]}
                storeData={storeData.bind(null, "FirstName")}
              />
              <InputTextField1
                disabled={type === "View"}
                element={"MiddleName"}
                tabIndex="1"
                ref={resetRefs["MiddleName"]}
                containerClassName="colSpan3 "
                title="Middle Name"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["MiddleName"]}
                storeData={storeData.bind(null, "MiddleName")}
              />

              <InputTextField1
                disabled={type === "View"}
                element={"LastName"}
                tabIndex="1"
                ref={resetRefs["LastName"]}
                containerClassName="colSpan3 "
                superTitle="*"
                title="Last Name"
                validation={isNotEmpty}
                charValidate={noCharValidateCheck}
                error_message={error_message["LastName"]}
                storeData={storeData.bind(null, "LastName")}
              />
              <InputSelectorField1
                disabled={type === "View"}
                element={"Mr"}
                tabIndex="1"
                ref={resetRefs["Suffix"]}
                containerClassName="colSpan1 "
                title="Suffix"
                optionsArray={["Mr", "Mrs", "Not preferred to say"]}
                validation={alwaysValidated}
                error_message={error_message["Suffix"]}
                storeData={storeData.bind(null, "Suffix")}
              />
              <InputSelectorField1
                disabled={type === "View"}
                element={"Gender"}
                tabIndex="1"
                ref={resetRefs["Gender"]}
                containerClassName="colSpan2 "
                superTitle="*"
                title="Gender"
                optionsArray={["Male", "Female", "Other", "Unknown"]}
                validation={selectorValidateCheck}
                error_message={error_message["Gender"]}
                storeData={storeData.bind(null, "Gender")}
              />
            </div>
            <div className="seperator-line"></div>
            <div className="patientsBasicInfoContainer2">
              <InputTextField1
                disabled={type === "View"}
                tabIndex="1"
                element={"phone"}
                ref={resetRefs["phone"]}
                containerClassName="colSpan2"
                title="Phone number 1 "
                superTitle="*"
                validation={phNoValidation}
                charValidate={phNoCharValidation}
                error_message={error_message["phone"]}
                storeData={storeData.bind(null, "phone")}
              />
              <InputTextField1
                disabled={type === "View"}
                tabIndex="1"
                element={"homePhone"}
                ref={resetRefs["homePhone"]}
                containerClassName="colSpan2"
                title="Phone number 2"
                validation={phNoOrEmptyValidationorEmpty}
                charValidate={phNoCharValidation}
                error_message={error_message["homePhone"]}
                storeData={storeData.bind(null, "homePhone")}
              />

              <InputTextField1
                disabled={type === "View"}
                element={"workPhone"}
                tabIndex="1"
                ref={resetRefs["workPhone"]}
                containerClassName="colSpan2"
                title="Office Phone"
                validation={phNoOrEmptyValidationorEmpty}
                charValidate={phNoCharValidation}
                error_message={error_message["workPhone"]}
                storeData={storeData.bind(null, "workPhone")}
              />

              <InputTextField1
                disabled={type === "View"}
                element={"email"}
                tabIndex="1"
                ref={resetRefs["email"]}
                containerClassName="colSpan3"
                title="Email "
                validation={alternateEmailValidation}
                charValidate={noCharValidateCheck}
                error_message={error_message["email"]}
                storeData={storeData.bind(null, "email")}
              />
              <InputTextField1
                disabled={type === "View"}
                element={""}
                tabIndex="1"
                ref={resetRefs["emailAlternative"]}
                containerClassName="colSpan3"
                title="Alternate Email "
                validation={alternateEmailValidation}
                charValidate={noCharValidateCheck}
                error_message={error_message["emailAlternative"]}
                storeData={storeData.bind(null, "emailAlternative")}
              />
              <InputSelectorField1
                disabled={type === "View"}
                element={"PreferredCommunication"}
                tabIndex="1"
                ref={resetRefs["PreferredCommunication"]}
                containerClassName="colSpan4"
                title="Preferred Communication "
                optionsArray={["Mobile Phone", "Home Phone", "Work Phone"]}
                validation={alwaysValidated}
                error_message={error_message["PreferredCommunication"]}
                storeData={storeData.bind(null, "PreferredCommunication")}
              />
            </div>
            <div className="seperator-line"></div>

            <div className="patientsBasicInfoContainer2">
              <InputSelectorField1
                disabled={type === "View"}
                tabIndex="1"
                element={"Ethnicity"}
                ref={resetRefs["Ethnicity"]}
                containerClassName="colSpan3"
                title="Ethnicity"
                validation={alwaysValidated}
                optionsArray={[
                  "Hispanic or Latino",
                  "Not Hispanic or Not Latino",
                  "Patient declined to specify",
                  "No Ethnicity ",
                ]}
                error_message={error_message["Ethnicity"]}
                storeData={storeData.bind(null, "Ethnicity")}
              />
              <InputSelectorField1
                disabled={type === "View"}
                tabIndex="1"
                element={"PreferredLanguage"}
                ref={resetRefs["PreferredLanguage"]}
                containerClassName="colSpan3"
                title="Preferred Language"
                optionsArray={["English", "Spanish"]}
                validation={alwaysValidated}
                error_message={error_message["PreferredLanguage"]}
                storeData={storeData.bind(null, "PreferredLanguage")}
              />

              <InputTextField1
                disabled={type === "View"}
                element={"street1"}
                tabIndex="1"
                ref={resetRefs["street1"]}
                containerClassName="colSpan3"
                title="Address Street1"
                superTitle="*"
                validation={isNotEmpty}
                charValidate={noCharValidateCheck}
                error_message={error_message["street1"]}
                storeData={storeData.bind(null, "street1")}
              />

              <InputTextField1
                disabled={type === "View"}
                element={""}
                tabIndex="1"
                ref={resetRefs["street2"]}
                containerClassName="colSpan3"
                title="Address Street2 "
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["street2"]}
                storeData={storeData.bind(null, "street2")}
              />
              <InputTextField1
                disabled={true}
                element={"country"}
                tabIndex="1"
                ref={resetRefs["country"]}
                containerClassName="colSpan3"
                title="Country "
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["country"]}
                storeData={storeData.bind("USA", "country")}
              />
              <InputTextField1
                disabled={type === "View"}
                element={"postalCode"}
                tabIndex="1"
                ref={resetRefs["postalCode"]}
                containerClassName="colSpan3"
                title="Zipcode "
                superTitle="*"
                validation={zipValidity}
                charValidate={zipCharValidate}
                error_message={error_message["postalCode"]}
                storeData={storeData.bind(null, "postalCode")}
              />
              <InputTextField1
                disabled={true}
                element={"state"}
                tabIndex="1"
                ref={resetRefs["state"]}
                containerClassName="colSpan3"
                title="State "
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["state"]}
                storeData={storeData.bind(null, "state")}
              />
              <InputTextField1
                disabled={true}
                element={"city"}
                tabIndex="1"
                ref={resetRefs["city"]}
                containerClassName="colSpan3"
                title="City "
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["city"]}
                storeData={storeData.bind(null, "city")}
              />
            </div>
            <div className="seperator-line"></div>
            <div className="patientsBasicInfoContainer3">
              <InputTextField1
                disabled={type === "View"}
                tabIndex="1"
                element={"emergencyContactName"}
                ref={resetRefs["emergencyContactName"]}
                containerClassName="colSpan3"
                title="Emergency Contact Name"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["emergencyContactName"]}
                storeData={storeData.bind(null, "emergencyContactName")}
              />
              <InputTextField1
                disabled={type === "View"}
                tabIndex="1"
                element={"emergencyContactPhone"}
                ref={resetRefs["emergencyContactPhone"]}
                containerClassName="colSpan3"
                title="Emergency Contact Phone"
                validation={phNoOrEmptyValidationorEmpty}
                charValidate={phNoCharValidation}
                error_message={error_message["emergencyContactPhone"]}
                storeData={storeData.bind(null, "emergencyContactPhone")}
              />
              <InputTextField1
                disabled={type === "View"}
                tabIndex="1"
                element={"emergencyContactRelation"}
                ref={resetRefs["emergencyContactRelation"]}
                containerClassName="colSpan3"
                title="Emergency Contact Relation"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["emergencyContactRelation"]}
                storeData={storeData.bind(null, "emergencyContactRelation")}
              />
            </div>
            <ButtonComponent
              tabIndex="2"
              containerClassName="buttonContainer"
              text="Continue"
              onClick={onClickcheck}
              // disabled={isLoading}
            />
          </>
        )}

        {/* insurance page start */}
        {tabChildrenList[1] === currentTab && (
          <>
            <PatientsInsurance loadedData={selectedData} />
          </>
        )}
        {tabChildrenList[2] === currentTab && (
          <>
            <Program
              onClickFn={onClickFn}
              saveBtnShow={true}
              selectedData={selectedData}
              enrolledData={enrolledData}
              setEnrolledData={setEnrolledData}
              updatePatientfn={updatePatientfn}
              id={id}
              updatedData={updatedData}
              setPopUpOpen={setPopUpOpen}
              updatebtnCall={updatebtnCall}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Patients;
