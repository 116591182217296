import React from "react";
import DropDownContent from "../../../components/DropDownContent";
import DropDownSelectorComponent from "../../../components/DropDownSelector";
import "./rcmAndCcm.scss";

const RcmAndCcm = (props) => {
  const { selectedPatient, onClickSave, ProviderList, ClinicianList } = props;
  return (
    <div className="content">
      <DropDownContent title={"Care Team"}>
        <div className="dropDown">
          <div className="dropComponents">
            <DropDownSelectorComponent
              name={selectedPatient?.ProviderId?.ProviderName}
              placeHolder={" Care Plan Physician: "}
              onClickSave={onClickSave}
              apiName={"ProviderId"}
              optionsDictionay={ProviderList}
              selectorName={"ProviderName"}
              selectorValue={"ProviderId"}
            />
            <DropDownSelectorComponent
              name={selectedPatient?.PrimaryCoordinator?.ClinicianName}
              placeHolder={"Primary Care Coordinator:"}
              onClickSave={onClickSave}
              apiName={"PrimaryCoordinator"}
              optionsDictionay={ClinicianList}
              selectorName={"ClinicianName"}
              selectorValue={"ClinicianId"}
            />
          </div>
        </div>
      </DropDownContent>

      {/* CCM CARE */}
      <DropDownContent title={"CCM Care Team"}>
        <div style={{ paddingBottom: "1rem", display: "flex", gap: "2rem" }}>
          <DropDownSelectorComponent
            name={selectedPatient?.primaryCCMClinician?.ClinicianName}
            placeHolder={"Primary Clinician:"}
            onClickSave={onClickSave}
            apiName={"primaryCCMClinician"}
            optionsDictionay={ClinicianList}
            selectorName={"ClinicianName"}
            selectorValue={"ClinicianId"}
          />
          <DropDownSelectorComponent
            name={selectedPatient?.secondaryCCMClinician?.ClinicianName}
            placeHolder={"Secondary Clinician:"}
            onClickSave={onClickSave}
            apiName={"CCMCareTeam.secondaryClinician"}
            optionsDictionay={ClinicianList}
            selectorName={"ClinicianName"}
            selectorValue={"ClinicianId"}
          />
        </div>
      </DropDownContent>

      {/* RCM CARE */}

      <DropDownContent title={"RPM Care Team"}>
        <div style={{ paddingBottom: "1rem", display: "flex", gap: "2rem" }}>
          <DropDownSelectorComponent
            name={selectedPatient?.primaryRPMClinician?.ClinicianName}
            placeHolder={"Primary Clinician:"}
            onClickSave={onClickSave}
            apiName={"primaryRPMClinician"}
            optionsDictionay={ClinicianList}
            selectorName={"ClinicianName"}
            selectorValue={"ClinicianId"}
          />
          <DropDownSelectorComponent
            name={selectedPatient?.secondaryRPMClinician?.ClinicianName}
            placeHolder={"Secondary Clinician:"}
            onClickSave={onClickSave}
            apiName={"RPMCareTeam.secondaryClinician"}
            optionsDictionay={ClinicianList}
            selectorName={"ClinicianName"}
            selectorValue={"ClinicianId"}
          />
        </div>
      </DropDownContent>
    </div>
  );
};

export default RcmAndCcm;
