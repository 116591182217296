import "./patientsViewPage.scss";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetPatientSelectedProfileMutation } from "../../app/api/patientsApi";
import { useEffect } from "react";

import FloatingNotifications from "../../utilities/FloatingNotifications";
import { generalHandleValueChanges } from "../Careteam/helperfunctions";
import SelectComponent from "../../components/SelectComponent";
import { useUpdatePatientsMutation } from "../../app/api/patientsApi";
import PatientDemographicsComponent from "./PatientDemographicsComponent";
const PatientViewPage = () => {
  const location = useLocation();
  const [id, setId] = useState(location?.state?.id);
  const [selectedProfilefn, { data: Data }] =
    useGetPatientSelectedProfileMutation();
  const [updatePatientfn, { data: updatedData, error: errorUpdating }] =
    useUpdatePatientsMutation();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    if (!id) return;
    selectedProfilefn({
      PatientId: id,
    });
  }, [id, selectedProfilefn]);
  useEffect(() => {
    setSelectedData(Data);
  }, [Data]);

  useEffect(() => {
    if (!updatedData) return;
    FloatingNotifications("success", "Updated successfully");
    setSelectedData(updatedData);
  }, [updatedData]);
  useEffect(() => {
    if (!errorUpdating) return;
    FloatingNotifications("error", "Updation failed");
  }, [errorUpdating]);
  return (
    <div className="ViewPageContainer">
      <p className="Title">Patient Profile</p>
      <div className="tableContainer">
        <div className="tableelement detailsHead">
          <div className="primaryDetails">
            <div className="contact">
              <Icon
                icon="basil:contacts-solid"
                color="darkgray"
                height="100%"
              />
            </div>
            <div className="details">
              <div>
                <p className="name">{selectedData?.FirstName}</p>
                <p className="name">{selectedData?.PatientEHRId}</p>
                <p className="dob">
                  ({selectedData?.Gender}|AGMKLMLK|
                  {Date(selectedData?.DOB).substring(0, 15)})
                </p>
                <p className="cordinator">
                  Primary Coordinator :{"  "}
                  {selectedData?.PrimaryCoordinatorName}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="tableelement">
          <p className="detailelements">Email: {selectedData?.email}</p>
          <p className="detailelements">Phone: {selectedData?.phone}</p>
          <p className="detailelements">Address: {selectedData?.street1}</p>
        </div>
        <div className="tableelement">
          <p className="detailelements">
            Date Added: {selectedData?.createdAt?.slice(0, 10)}
          </p>
          <p className="detailelements">Last Scheduled Appt:</p>
          <p className="detailelements">Next Scheduled Appt:</p>
        </div>
        <div className="btns"></div>
      </div>
      <PatientDemographicsComponent
        selectedData={selectedData}
        id={id}
        updatePatientfn={updatePatientfn}
        updatedData={updatedData}
      />
    </div>
  );
};
export default PatientViewPage;
