import React from "react";
import { useState } from "react";

import { Icon } from "@iconify/react";
import "./program.scss";
import ButtonComponent from "../../components/ButtonComponent";

import { areObjectsEqual } from "../Careteam/helperfunctions";
const Program = ({
  selectedData,
  enrolledData,
  updatePatientfn,
  id,
  onClickFn,
  setPopUpOpen,
  updatebtnCall,
  setEnrolledData,
  saveBtnShow,
}) => {
  let x = 0;
  const [popUpDelete, setPopUpDelete] = useState(false);
  const [dataItemToDelete, setDataItemToDelete] = useState();

  const deletefn = (object) => {
    if (enrolledData) {
      const p = enrolledData?.filter((el) => !areObjectsEqual(object, el));
      setEnrolledData(p);
      return;
    } else {
      const { _id, ...remObject } = object;
      const { enrolledPrograms, ...res } = selectedData;
      const rem = enrolledPrograms?.map((el) => {
        let { _id, ...r } = el;
        return r;
      });
      const p = rem?.filter((el) => !areObjectsEqual(remObject, el));
      updatePatientfn({
        PatientId: id,
        enrolledPrograms: [...p, { ...object, Status: "inactive" }],
      });
    }
  };
  const onClickDeleteBtn = (dataItem) => {
    setPopUpDelete(true);
    setDataItemToDelete(dataItem);
  };
  const onDeleteConfirmed = () => {
    deletefn(dataItemToDelete);
    setPopUpDelete(false);
    setDataItemToDelete();
  };

  return (
    <div className="programContainer">
      {popUpDelete && (
        <div className="popUpDelete">
          <div className="popupContent">
            <p>Are you sure you want to delete this Program?</p>
            <div className="popupActions">
              <button onClick={onDeleteConfirmed}>Delete</button>
              <button onClick={() => setPopUpDelete(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <button className="addButton" onClick={() => setPopUpOpen(true)}>
        +Add Program
      </button>
      <table>
        <tr>
          <th>S.No.</th>
          <th>Enrolled Program Name</th>
          <th> Program Status</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        {(enrolledData ? enrolledData : selectedData?.enrolledPrograms)?.map(
          (el) => (
            <tr>
              <td>{++x}</td>
              <td>{el.ProgramName}</td>
              <td>{el.ProgramStatus}</td>
              <td>{el.Status}</td>
              <td>
                <div className="iconWraptd">
                  <Icon
                    className="icon"
                    icon="uil:edit"
                    color="#222"
                    onClick={() => updatebtnCall(el)}
                  />
                  <Icon
                    className="icon"
                    icon="material-symbols:delete"
                    color="#222"
                    onClick={() => onClickDeleteBtn(el)}
                  />
                </div>
              </td>
            </tr>
          )
        )}
      </table>
      {saveBtnShow && (
        <ButtonComponent
          tabIndex="2"
          containerClassName="buttonContainer"
          text="Save"
          onClick={() => onClickFn()}
          // disabled={isLoading}
        />
      )}
    </div>
  );
};

export default Program;
