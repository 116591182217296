import React, { useEffect } from "react";
import { useState } from "react";
import PopUp from "../patients/patientViewPage/PopUp";
import AddShedule from "../schedule/AddShedule";
import classNames from "classnames";
import { useGetScheduleListMutation } from "../app/api/scheduleCallApi";
import CallTaskListViewTable from "./CallTaskListViewTable";
import ActiveHistoryDropDown from "../components/ActiveHistoryDropDown";
import LoaderComponent from "../components/LoaderComponent";
import "./callTaskList.scss";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const programs = ["CCM", "RPM", "BHI"];
const CallTaskList = () => {
  const [header, setHeader] = useState("CCM");
  const [shedulePopUp, setShedulePopUp] = useState(false);
  const [sheduleData, setSheduleData] = useState([]);
  const [getScheduleListFn, { data: scheduleList, isLoading }] =
    useGetScheduleListMutation();
  const currentMonth = months[new Date().getMonth()];
  const currentYear = new Date().getFullYear();

  const cancelfn = () => {
    setShedulePopUp(false);
  };
  const clickForShedule = () => {
    setShedulePopUp(true);
  };
  useEffect(() => {
    if (!scheduleList) return;
    setSheduleData(scheduleList);
  }, [scheduleList]);
  useEffect(() => {
    getScheduleListFn({ programName: header });
  }, [header]);
  return (
    <div className="callTaskList">
      <LoaderComponent isLoading={isLoading} />
      {shedulePopUp && (
        <PopUp cancelfn={cancelfn} width={"80vw"}>
          <AddShedule
            // sheduleDate={sheduleDate}
            // setSheduleData={setSheduleData}
            cancelfn={cancelfn}
            // sheduleData={sheduleData}
          />
        </PopUp>
      )}
      <div className="RTMtitle">Call Task List</div>
      <div className="nav">
        {programs.map((program) => (
          <div
            className={classNames(
              "navHeader",
              header === program ? "navHeaderSelected" : ""
            )}
            onClick={() => setHeader(program)}
          >
            {program}
          </div>
        ))}
      </div>
      <div className="body">
        <ActiveHistoryDropDown
          type={`Planned Monthly ${header} Shedules,${currentMonth} ${currentYear}`}
          callList={true}
          clickForShedule={clickForShedule}
        >
          <div className="divWrap">
            <ActiveHistoryDropDown type={"Not Called"}>
              <CallTaskListViewTable
                data={sheduleData?.filter((d) => d.callStatus === "Scheduled")}
              />
            </ActiveHistoryDropDown>
            <ActiveHistoryDropDown type={"Incomplete Calls"} open={false}>
              <CallTaskListViewTable
                data={sheduleData?.filter(
                  (d) =>
                    d.callStatus ===
                    ("No Answer" ||
                      "Left Message" ||
                      "Do Not Call" ||
                      "Wrong Number" ||
                      "Cancelled By Patient" ||
                      "Not Interested In Program" ||
                      "Reschedule" ||
                      "Call In Progress")
                )}
              />
            </ActiveHistoryDropDown>
            <ActiveHistoryDropDown
              type={"Completed Calls"}
              open={false}
              data={sheduleData?.filter((d) => d.callStatus === "completed")}
            >
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
          </div>
        </ActiveHistoryDropDown>
        <ActiveHistoryDropDown
          type={`Unplanned Monthly ${header} Shedules,${currentMonth} ${currentYear}`}
          open={false}
        >
          <div className="divWrap">
            <ActiveHistoryDropDown type={"Not Called"} open={false}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
            <ActiveHistoryDropDown type={"Incomplete Calls"} open={false}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
            <ActiveHistoryDropDown type={"Completed Calls"} open={false}>
              <CallTaskListViewTable />
            </ActiveHistoryDropDown>
          </div>
        </ActiveHistoryDropDown>
      </div>
    </div>
  );
};

export default CallTaskList;
