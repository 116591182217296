import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Icon } from "@iconify/react";
import "../sass/selectSearchTime.scss";
import { useEffect, useMemo } from "react";

const SelectSearch = ({
  options,
  optionsDict,
  valuedata,
  fn,
  setvalue,
  name,
  ApiOption,
  Apicallfn,
  selectedPatient,
  decription,
  icdfn,
  min,
  max,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const filteredOptions = useMemo(() => {
    if (ApiOption) {
      return options?.filter((option) =>
        option.full.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (options) {
      return options?.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (optionsDict) {
      return optionsDict?.filter((option) =>
        option[0].toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }, [options, searchTerm, ApiOption, optionsDict]);

  const handleInputChange = (e) => {
    if (e.target.value > max) return;
    if (e.target.value < min) return;
    setSearchTerm(e.target.value);
    fn(name, e.target.value);
  };
  const handleOptionClick = (option, option2) => {
    setSelectedOption(option);
    setSearchTerm(option);
    setIsOpen(false);
    fn(name, option);
  };
  const firstClick = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (!valuedata) return;

    setSelectedOption(valuedata);
    setSearchTerm(valuedata);
  }, [valuedata]);
  return (
    <div className="select-with-search">
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="dropdown">
          <div className="dropFlex">
            <input
              type="number"
              value={searchTerm}
              onChange={handleInputChange}
              onClick={() => firstClick()}
              min={min}
              max={max}
            />
          </div>
          {isOpen && !ApiOption && !optionsDict && (
            <ul className="options">
              {filteredOptions?.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
          {isOpen && ApiOption && !optionsDict && (
            <ul className="options" size="5">
              {filteredOptions?.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option)}>
                  {option.full}
                </li>
              ))}
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

const SelectSearchTime = React.memo(SelectSearch);
export default SelectSearchTime;
