import React from "react";
import { useState, useEffect } from "react";
import SelectWithSearch from "../../../searchandselect component/SearchAndSelect";
import { Icon } from "@iconify/react";
import AddFavComp from "./AddFavComp";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  generalHandleValueChanges,
  generalSaveButton,
  areObjectsEqual,
} from "../../helperfunctions";
import CarePlanErrorMessage from "../../CarePlanErrorMessage";
const goalList = [
  "2-hour postprandial glucose",
  "Ability to walk to my mailbox daily",
  "Acquiring assertion skills",
  "Adherence to appointment schedules - Adhering to appointment schedules and actively participating in their recovery",
  "Adherence to medical treatment for depression - Patient adheres to medicaltreatment (takes their medication, continues therapy etc)",
  "Adherence to medical treatment for SUD/Addiction Disorders - 1.All drugsprescribed are present in screen to prevent diversion 2.Lowest dose and least number of medications needed to prevent relapse",
  "Average pain level",
  "Avoid smoke and air pollution",
  "Body mass index",
  "Clear your airways",
  "Cognitive change - 1.Stating less than a 10% belief in assumption of need for perfection 2.modifying schema of worthlessness:",
  "Completing exposure to all avoided situations",
  "Control your breathing",
  "Coping skills for managing depression - Patient will verbalize to therapist at least 3 coping skills for managing depression",
  "Development of coping skills - Develop Coping skills for recognizing and dealing with symptoms of bipolar depression",
  "Diastolic blood pressure",
  "Drink water every day - Litres",
  "Drinking water",
  "Eat Healthy diet (Beans, Dark green leafy vegetables, Fish, whole grains, Fat free Dairy products)",
  "Eat healthy foods",
  "Eliminating all depressive symptoms (Beck Depression Inventory–II score under 10 for 1 month)",
  "Eliminating intrusive memories of trauma",
  "Eliminating panic attacks",
  "End-state goals - 1.Eliminating all depressive symptoms (Beck Depression Inventory–II score under 10 for 1 month) 2.Engaging in all previously avoided activities 3.Eliminating panic attacks",
  "Engaging in all previously avoided activities",
  "Engaging in one pleasurable/rewarding activity daily",
  "Enhance cognition, mood and behavior",
  "Exercise",
  "Exercise - Aerobic",
  "Exercise - Basketball",
  "Exercise - Bicycling",
  "Exercise - Climbing",
  "Exercise - Running",
  "Exercise - Skipping",
  "Exercise - Soccer",
  "Exercise - Strength Training",
  "Exercise - Swimming",
  "Exercise - Total Step Count",
  "Exercise - Walking",
  "Exercise - Yoga",
  "Exercise regularly",
  "Fasting plasma glucose",
  "Foster a safe environment",
  "HDL cholesterol",
  "Healthy Eating / Diet",
  "Hemoglobin A1C",
  "LDL cholesterol",
  "Learn and Implement coping skills - 1.Learn and practice at least 2 anxiety management techniques with goal of decreasing anxiety symptoms to less than 3 times per week 2.work with therapist/counselor to help expose and extinguish irrational thoughts that contribute to anxiety",
  "Learn coping skills to deal with substance use triggers - 1.AA 2.Group meetings 3.Exercise",
  "Less Isolation - Patient initiates 2 or more social contacts per week for 4 weeks",
  "Limit Alcohol",
  "Low Sodium diet (Greens, Broccoli, Cauliflower)",
  "Maximize function in daily activities",
  "Medication Management for depression - 1.Adherence to Medical treatment 2.Reduction in number of different meds needed to control the symptoms",
  "Medication Management for Schizophrenia - 1.Adherence to medical treatment 2.Smallest number of medications needed to control symptoms",
  "Modifying schema of worthlessness",
  "O2 Saturation",
  "Peak Expiratory Flow Rate",
  "Promote social engagement, as appropriate",
  "Pulse",
  "Quit Smoking",
  "Reduce Overdose and Relapse - 1.MAT 2.Therapy/Meetings",
  "Reduce recreational drugs like Caffeine",
  "Reduced impairment - 1.Bringing grades up to prior level (A’s and B’s) 2.Resuming all household activities 3.Beginning to date 4.Finding appropriate employment",
  "Reducing self-critical ideation",
  "Reduction in Symptoms - Less frequent and less intense hallucinations or symptoms",
  "Reduction of frequency, Intensity and duration of the anxiety - Reduce overall Frequency, Intensity, and duration of the anxiety so that daily functioning is not impaired",
  "Reduction of Manic-Depressive episodes - 1.Improved sleep schedules, exercise schedules 2.Adherence to therapy",
  "Reduction of Panic Attacks by 50% - 1.Panic attacks will be reduced from 6 times per week currently to 3 times per week or less 2.Patient will not experience any panic attacks for 3 consecutive weeks, per self-report",
  "Reporting anxiety below 2 on a scale from 0 to 10 in business meetings",
  "Respiration Rate",
  "Stating less than a 10% belief in assumption of need for perfection",
  "Stress Management",
  "Symptoms relief - 1.Eliminating intrusive memories of trauma 2.Reducing self-critical ideation 3.Reporting anxiety below 2 on a scale from 0 to 10 in business meetings",
  "Systolic blood pressure",
  "Task Completion - 1.Completing exposure to all avoided situations 2.Engaging in one pleasurable/rewarding activity daily 3.Acquiring assertion skills",
  "Temperature",
  "Therapy engagement - 1.Better sleep schedule 2.Exercise routine 3.Doesn’t miss appointments",
  "Triglycerides",
  "Understanding about Illness - Able to understand more about their Illness in order to better participate in their treatment",
  "Uninterrupted sleep",
  "Walk every day - Minutes",
  "Weight",
  "Other",
];
const options = [
  "Body mass index",
  "Walk every day",
  "Quit Smoking",
  "Limit Alcohol",
  "Reduce recreational drugs like Caffeine",
  "Low Sodium diet (Greens, Broccoli, Cauliflower)",
  "Stress Management",
  "Exercise - Walking",
  "Hemoglobin A1C",
  "Drink water every day",
  "Eat Healthy diet (Beans, Dark green leafy vegetables, Fish, whole grains, Fat free Dairy products)",
];
const DiagnosticGoal = (props) => {
  const {
    selectedPatient,
    id,
    updateDatafn,
    Icd,
    changeWindow,
    uniqueId,
    editfn,
  } = props;
  const [goalWindow, setGoalWindow] = useState(false);
  const [arrayForEdit, setArrayForEdit] = useState();
  const addGoalDetail = (noreset) => {
    setGoalWindow((prev) => !prev);
    if (noreset) return;

    resetfn(goal, setGoal, uniqueId);
  };

  const [goalArray, setGoalArray] = useState([]);
  const [goal, setGoal] = useState({
    Goal: "",
    StartDate: "",
    EndDate: "",
    Description: "",
    Diagnosis_id: uniqueId,
    Status: true,
  });

  const arrayForEditFn = (value) => {
    let { _id, ...updatedGoal } = value;

    let r = goalArray?.filter((el) => !areObjectsEqual(el, value));
    let updatedArray = r.map((obj) => {
      const { _id, ...rest } = obj;
      return rest;
    });
    setArrayForEdit(updatedArray);
    setGoal(updatedGoal);
  };
  const deleteObjectFromArray = (object, array, setArray) => {
    let newArray = array?.filter((el) => !areObjectsEqual(el, object));
    setArray(newArray);
    updateDatafn({ PatientId: id, GoalInformation: newArray });
  };

  const saveButton = () => {
    if (!basicValidation(goal)) return;
    setGoalArray((prev) => [...prev, goal]);
    setGoalWindow(false);
  };

  const addDefaultGoalFn = (option, desc) => {
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 16);
    let goal = {
      Goal: option,
      StartDate: formattedDate,
      EndDate: "",
      Description: desc,
      Diagnosis_id: uniqueId,
      Status: true,
    };
    setGoal(goal);
    setGoalArray((prev) => [...prev, goal]);
  };
  useEffect(() => {
    if (selectedPatient) {
      let re = selectedPatient.GoalInformation?.filter(
        (el) => el.Diagnosis_id !== undefined
      );
      setGoalArray(re.filter((el) => el.Diagnosis_id === uniqueId));
    }
  }, [selectedPatient]);

  useEffect(() => {
    if (arrayForEdit) {
      if (!basicValidation(goal)) return;
      setGoalArray([...arrayForEdit, goal]);
      setArrayForEdit();
      setGoalWindow(false);
      return;
    }
    let filteredData = selectedPatient?.GoalInformation?.filter(
      (el) => el.Diagnosis_id !== uniqueId
    );
    if (
      basicValidation(goal) &&
      !areArraysSame(selectedPatient?.GoalInformation, [
        ...filteredData,
        ...goalArray,
      ])
    ) {
      updateDatafn({
        PatientId: id,
        GoalInformation: [...filteredData, ...goalArray],
      });
    }
  }, [goalArray]);

  return (
    <div>
      <p className="title">
        Goals for {"<<"}
        {Icd}
        {">>"}
      </p>

      <div className="addDetailsToggle">
        {goalWindow ? (
          <button className="btnGoalsToggle" onClick={addGoalDetail}>
            {/* <Icon icon="ph:eye-bold" width="" height="10" /> */}
            View Goals
          </button>
        ) : (
          <>
            <button className="btnGoalsToggle" onClick={() => editfn(uniqueId)}>
              Prev
            </button>
            <button
              className="btnGoalsToggle"
              onClick={changeWindow.bind(null, 3)}
            >
              Next
            </button>{" "}
            <button
              className="btnGoalsToggle"
              onClick={() => addGoalDetail(false)}
            >
              {/* <Icon icon="ic:twotone-plus" width="20" height="20" /> */}
              New Goals
            </button>
          </>
        )}
      </div>
      <div
        className="goalDisplay"
        style={{ display: "grid", gridTemplateColumns: "80% 20%" }}
      >
        {goalWindow ? (
          <div className="details-sec">
            <div className="detail-content">
              <label className="details-element">Search Goal*</label>
              <SelectWithSearch
                options={goalList}
                valuedata={goal["Goal"]}
                fn={generalHandleValueChanges}
                setvalue={setGoal}
                name={"Goal"}
              />

              <label className="details-element">Description*</label>
              <textarea
                className="details-element "
                onChange={(event) =>
                  generalHandleValueChanges(event, setGoal, "Description")
                }
                value={goal["Description"]}
              />
            </div>
            <div className="detail-content">
              <label className="details-element">Start Date & Time*</label>
              <input
                className="details-element "
                type="datetime-local"
                id="meeting-time"
                name="meeting-time"
                value={goal?.StartDate?.slice(0, 16)}
                onChange={(event) =>
                  generalHandleValueChanges(event, setGoal, "StartDate")
                }
              />
              <label className="details-element">End Date & Time</label>
              <input
                className="details-element "
                type="datetime-local"
                id="meeting-time"
                name="meeting-time"
                value={goal?.EndDate?.slice(0, 16)}
                onChange={(event) =>
                  generalHandleValueChanges(event, setGoal, "EndDate")
                }
                min={goal.StartDate ?? ""}
              />
            </div>
            <button onClick={saveButton}>Add</button>
          </div>
        ) : (
          <>
            <div className="showGoalsContainer">
              {goalArray && goalArray?.length === 0 ? (
                <CarePlanErrorMessage msg="Patient doesn't have any Goals yet!" />
              ) : (
                goalArray
                  ?.slice()
                  .reverse()
                  .map((el) => (
                    <div className="goalsDisplay">
                      <div className="goalsText">
                        <div className="hash">#</div> {el.Goal}
                      </div>
                      <div className="goalsBtnCont">
                        <div className="goalsBtnWrap">
                          <Icon
                            icon="uil:edit"
                            className="goalsBtn"
                            width="20"
                            height="20"
                            onClick={() => {
                              arrayForEditFn(el);
                              addGoalDetail(true);
                            }}
                          />
                        </div>
                        <div className="goalsBtnWrap">
                          <Icon
                            className="goalsBtn"
                            icon="material-symbols:delete-outline"
                            width="20"
                            height="20"
                            onClick={deleteObjectFromArray.bind(
                              null,
                              el,
                              goalArray,
                              setGoalArray
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  ))
              )}
            </div>
            <AddFavComp
              addDefaultFn={addDefaultGoalFn}
              type={"Goals"}
              code={
                selectedPatient?.Diagnosis?.find(
                  (el) => el.Diagnosis_id === uniqueId
                )?.ICD_Code
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DiagnosticGoal;
