import React, { useEffect, useState } from "react";
import "../sass/selectTimeSpentComp.scss";
import SelectSearchTime from "./SelectTimeComp";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";

const hourList = Array.from({ length: 24 }, (_, i) =>
  String(i).padStart(2, "0")
);
const secList = Array.from({ length: 60 }, (_, i) =>
  String(i).padStart(2, "0")
);
function TimeSpentInput({ value, onChange, setService, flagName }) {
  const [time, setTime] = useState({ hh: "", mm: "", ss: "" });

  const handleTimeChange = (name, newValue) => {
    setTime((prev) => ({ ...prev, [name]: newValue }));
    let temp = { ...time, [name]: newValue };
    console.log(temp);
    const formattedTime = `${temp.hh}:${temp.mm}:${temp.ss}`;
    if (formattedTime !== value) {
      setService((prev) => ({
        ...prev,
        [flagName]: formattedTime,
      }));
    }
  };

  useEffect(() => {
    if (value) {
      const [hh, mm, ss] = value.trim().split(":");
      setTime({ hh, mm, ss });
    }
  }, [value]);
  return (
    <div className="timeInputContainer">
      <SelectSearchTime
        options={hourList}
        valuedata={time.hh}
        fn={handleTimeChange}
        setvalue={setTime}
        name={"hh"}
        min={0}
        max={23}
      />
      :
      <SelectSearchTime
        options={secList}
        valuedata={time.mm}
        fn={handleTimeChange}
        setvalue={setTime}
        name={"mm"}
        min={0}
        max={59}
      />
      :
      <SelectSearchTime
        options={secList}
        valuedata={time.ss}
        fn={handleTimeChange}
        setvalue={setTime}
        name={"ss"}
        min={0}
        max={59}
      />
    </div>
  );
}
export default TimeSpentInput;
