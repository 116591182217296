import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useGetPatientsOptionsQuery } from "../../app/api/patientSelctorOptionApi";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../components/SelectComponent";
import { Icon } from "@iconify/react";
import {
  useGetPatientsAllProfilesQuery,
  useDeletePatientsMutation,
} from "../../app/api/patientsApi";
import { useState, useEffect } from "react";
import PatientInteractionPage from "../../schedule/patientInteractionSelection/PatientInteractionPage";
import { useLocation } from "react-router-dom";
import { generalHandleValueChanges } from "../Careteam/helperfunctions";
import SearchIcon from "@mui/icons-material/Search";
import PopUp from "../patientViewPage/PopUp";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import AddService from "./AddService";
import InputAdornment from "@mui/material/InputAdornment";
import SelectComponent2 from "../../components/SelectCompoent2";
import LoaderComponent from "../../components/LoaderComponent";
import FormControl from "@mui/material/FormControl";
import "./patientsPreviewPage.scss";

const useStyles = {
  TableContainer: {
    border: "0.5px solid #6D6D71",
    boxShadow: "none",
    height: "500px",
    overflowY: "auto",
  },
  tableHead: {
    backgroundColor: "#EEEEEE",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  tableHeaderTitle: {
    fontSize: "calc(6.32px + 0.632 * 1vw)",
    color: "#222222",
    borderRight: "0.5px solid #6D6D71",
    borderBottom: "0.5px solid #6D6D71",
    fontWeight: "600",
  },
  tableBodyContent: {
    fontSize: "calc(6.32px + 0.632 * 1vw)",
    borderRight: "0.5px solid #6D6D71",
    padding: 0,
    paddingLeft: "1rem",
  },
  formControl: {
    "& .MuiInputBase-input": {
      fontSize: "  calc(6.32px + 0.632 * 1vw)",
    },
  },

  labelSelect: {
    fontSize: "  calc(6.32px + 0.632 * 1vw)",
  },

  select: {
    "&.Mui-focused": {
      color: "", // to overwrite the default behaviour
    },
    background: "white",
    color: "#222222",
    borderColor: " #6D6D71",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "10px",
    // paddingTop: '14px',
    // paddingBottom: '15px',
    "&:hover": {},
    "&:focus": {
      borderRadius: "4px",
      background: "white",
    },
  },
};
const dataFiltersInitialState = {
  facility: "",
  provider: "",
  clinician: "",
  program: "All Patients",
  patient: "",
};
const PatientsPreviewPage = (props) => {
  const location = useLocation();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [IdToSent, setIdToSent] = useState(false);
  const [callPatientPopUp, setCallPatientPopUp] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [shedulePopUp, setShedulePopUp] = useState(false);
  const [deletePatientFn, { data: deletePatient, isLoadingDelete }] =
    useDeletePatientsMutation();
  const onClickEditBtn = (value) => {
    navigate("/patients/Add-Patient", { state: { id: value, type: "Edit" } });
  };
  const onClickViewBtn = (value) => {
    navigate("/patients/View-Patient", { state: { id: value } });
  };
  const cancelfnDel = () => {
    setUserToDelete("");
    setShedulePopUp(false);
  };
  const onClickDelete = (user) => {
    setUserToDelete(user);
    setShedulePopUp(true);
  };
  const onClickDeleteConfirmed = () => {
    if (!userToDelete) return;
    deletePatientFn({
      PatientId: userToDelete,
    });
    setShedulePopUp(false);
  };
  const navigate = useNavigate();
  const {
    data: dataFromApi,
    isLoading,
    refetch,
  } = useGetPatientsAllProfilesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  // const {onClickEditBtn,onClickViewBtn}=props
  const [data, setData] = useState([]);

  const [patientDataForService, setPatientDataForService] = useState("");

  const [dataForFilter, setDataForFilter] = useState([]);

  useEffect(() => {
    if (!deletePatient) return;
    refetch();
  }, [deletePatient]);
  useEffect(() => {
    if (!dataFromApi) return;
    setData(dataFromApi);
  }, [dataFromApi]);

  const onClickCarePlan = (value) => {
    navigate("/patients/careplan", { state: { id: value } });
  };
  const onClickRpm = (value) => {
    navigate("/patients/rpm", { state: { id: value } });
  };
  const onClickCall = (value) => {
    setIdToSent(value);
    setCallPatientPopUp(true);
    // navigate("/patients/careplanCall", { state: { id: value } });
  };
  const [dataFilters, setDataFilters] = useState(dataFiltersInitialState);

  const { data: fpcList } = useGetPatientsOptionsQuery();
  const FacilityList =
    fpcList?.FacilityList?.map((facility) => [
      facility.FacilityName,
      facility.FacilityId,
    ]) ?? [];
  FacilityList.unshift(["All", ""]);
  const ProviderList =
    fpcList?.ProviderList?.map((provider) => [
      provider.ProviderName,
      provider.ProviderId,
    ]) ?? [];
  ProviderList.unshift(["All", ""]);
  const ClinicianList =
    fpcList?.ClinicianList?.map((clinician) => [
      clinician.ClinicianName,
      clinician.ClinicianId,
    ]) ?? [];
  ClinicianList.unshift(["All", ""]);

  const cancelfn = () => {
    setPopUpOpen(false);
  };
  const openfn = (patientData) => {
    setPatientDataForService(patientData);
    setPopUpOpen(true);
  };

  useEffect(() => {
    if (!data) return;
    let dummyData = data;
    if (dataFilters.facility !== "") {
      dummyData = dummyData?.filter(
        (data) => data?.FacilityId?._id === dataFilters.facility
      );
    }
    if (dataFilters.provider !== "") {
      dummyData = dummyData?.filter(
        (data) => data?.ProviderId?._id === dataFilters.provider
      );
    }
    if (dataFilters.clinician !== "") {
      dummyData = dummyData?.filter(
        (data) => data?.PrimaryCoordinator?._id === dataFilters.clinician
      );
    }
    if (dataFilters.program !== "All Patients") {
      dummyData = dummyData.filter((x) =>
        x.enrolledPrograms.some(
          (program) => program.programName === dataFilters.program
        )
      );
    }
    if (dataFilters.patient) {
      dummyData = dummyData?.filter((data) =>
        data?.Name?.toLowerCase().includes(dataFilters?.patient?.toLowerCase())
      );
    }
    setDataForFilter(dummyData);
  }, [dataFilters, data]);

  return (
    <div className="container-patients">
      <LoaderComponent isLoading={isLoading} />

      {popUpOpen && (
        <PopUp cancelfn={cancelfn}>
          <AddService
            patientDataForService={patientDataForService}
            cancelfn={cancelfn}
          />
        </PopUp>
      )}
      {shedulePopUp && (
        <PopUp cancelfn={cancelfnDel}>
          <div>
            <p>Are you sure you want to delete this patient </p>
            <div className="buttonsContainer">
              <button onClick={onClickDeleteConfirmed}>Delete</button>
              <button onClick={cancelfnDel}>cancel</button>
            </div>
          </div>
        </PopUp>
      )}
      {callPatientPopUp && (
        <PatientInteractionPage
          // eventData={eventData}
          id={IdToSent}
          setCallPatientPopUp={setCallPatientPopUp}
          // setEventData={setEventData}
        />
      )}
      <div className="pageTitle">All Patients</div>
      <div className="advanceSearchBox">
        <div className="box">
          <p className="head">Program</p>
          <SelectComponent
            OptionsArray={["CCM", "RPM", "BHI", "All Patients"]}
            fn={generalHandleValueChanges}
            valueData={dataFilters.program}
            setvalue={setDataFilters}
            name={"program"}
          />
        </div>
        <div className="box">
          <p className="head">Facility</p>
          <SelectComponent2
            OptionsDict={FacilityList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.facility}
            setvalue={setDataFilters}
            name={"facility"}
          />
        </div>
        <div className="box">
          <p className="head">Physician</p>
          <SelectComponent2
            OptionsDict={ProviderList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.provider}
            setvalue={setDataFilters}
            name={"provider"}
          />
        </div>
        <div className="box">
          <p className="head">Clinician</p>
          <SelectComponent2
            OptionsDict={ClinicianList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.clinician}
            setvalue={setDataFilters}
            name={"clinician"}
          />
        </div>
        <div className="box">
          <p className="head">Patient Name</p>
          <input
            value={dataFilters.patient}
            onChange={(e) => {
              setDataFilters((prev) => ({
                ...prev,
                patient: e.target.value,
              }));
            }}
          />
        </div>
        <div className="box"></div>
      </div>
      <div
        className="formElementsCntainer"
        style={{
          display: "flex",
          gap: "1rem",
          margin: "1rem",
          justifyContent: "end",
        }}
      >
        <IconButton
          style={{
            borderRadius: "4px",
            padding: "1rem",
            fontSize: "  calc(6.32px + 0.632 * 1vw)",
            color: "#fff",
            fontWeight: "600",
            backgroundColor: "#7A5DDA",
          }}
          className="addPatients"
          onClick={() => navigate("/patients/Add-Patient")}
        >
          <Icon icon="uil:plus" />
          Add Patient
        </IconButton>
      </div>
      <TableContainer sx={useStyles.TableContainer} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={useStyles.tableHeaderTitle}>S.no</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Name</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Phone Number
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>SSN</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Preferred Language
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Gender</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Date Of Birth
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Enrolled Program
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataForFilter.map((row, index) => (
              <TableRow key={row.PatientId}>
                <TableCell sx={useStyles.tableBodyContent}>
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    ...useStyles.tableBodyContent,
                    "&:hover": {
                      color: " #7a5dda",
                      cursor: "pointer",
                      textDecoration: "underline #7a5dda 1px",
                    },
                  }}
                  onClick={() => onClickViewBtn(row.PatientId)}
                >
                  {row["Name"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Phone Number"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["SSN"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Preferred Language"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Gender"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Date Of Birth"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["enrolledPrograms"]
                    ?.map((program) => program?.programName)
                    .join(", ")}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  <IconButton onClick={() => onClickCarePlan(row.PatientId)}>
                    <Icon
                      icon="material-symbols:contact-page-outline"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => openfn(row)}>
                    <Icon
                      icon="ph:plus-bold"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => onClickCall(row.PatientId)}>
                    <Icon
                      icon="ic:round-call"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => onClickViewBtn(row.PatientId)}>
                    <Icon
                      icon="gridicons:fullscreen"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => onClickDelete(row.PatientId)}>
                    <Icon
                      icon="material-symbols:delete"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => onClickEditBtn(row.PatientId)}>
                    <Icon
                      icon="material-symbols:edit-square-outline-sharp"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  </IconButton>
                  {row["enrolledPrograms"]?.find(
                    (program) => program.programName === "RPM"
                  ) && (
                    <IconButton onClick={() => onClickRpm(row.PatientId)}>
                      <Icon
                        icon="streamline:health-medical-heart-rate-health-beauty-information-data-beat-pulse-monitor-heart-rate-info"
                        style={{ height: "2rem", width: "2rem" }}
                      />
                    </IconButton>
                  )}{" "}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PatientsPreviewPage;
