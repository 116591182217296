import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useUpdateScheduleMutation } from "../app/api/scheduleCallApi";
import "./eventViewer.scss";
import LoaderComponent from "../components/LoaderComponent";
import FloatingNotifications from "../utilities/FloatingNotifications";
import { Icon } from "@iconify/react";

const EventViewer = ({
  viewEvent,
  closeFn,
  onClickCallPatient,
  onClickReshedule,
}) => {
  const [popUpSave, setPopUpSave] = useState(false);

  const [
    updateSheduleFn,
    {
      data: updateSheduleData,
      isLoading: loadingUpdate,
      error: errorUpdatingShedule,
    },
  ] = useUpdateScheduleMutation();

  const CancelSchedule = () => {
    setPopUpSave(true);
  };

  const CancelScheduleConfirmed = () => {
    updateSheduleFn({
      scheduleId: viewEvent?.scheduleId,
      callStatus: "canceled",
    });
    setPopUpSave(false);
  };
  useEffect(() => {
    if (!updateSheduleData) return;
    FloatingNotifications("success", "Shedule has been canceled");
    closeFn();
  }, [updateSheduleData]);
  useEffect(() => {
    if (!errorUpdatingShedule) return;
    FloatingNotifications("error", "Somethingh went wrong");
  }, [errorUpdatingShedule]);

  return (
    <div className="eventViewContainer">
      <LoaderComponent isLoading={loadingUpdate} />

      <OutsideClickHandler onOutsideClick={closeFn}>
        <div className="eventViewContent">
          <Icon
            onClick={closeFn}
            className="closeIcon"
            icon="iconamoon:close-bold"
            width="26"
            height="26"
          />
          {popUpSave && (
            <div className="popUpDelete">
              <div className="popupContent">
                <p>Are you sure you want to delete this Schedule?</p>
                <div className="popupActions">
                  <button onClick={() => CancelScheduleConfirmed()}>
                    Delete
                  </button>
                  <button onClick={() => setPopUpSave(false)}>Cancel</button>
                </div>
              </div>
            </div>
          )}
          <div className="popchild">
            <p className="title">CCM Schedule Information</p>
            <div className="childgrid">
              <div className="element">
                <div className="displayElement">
                  <p className="head">Patient Name</p>
                  <p className="sub">{viewEvent?.patientId?.PatientName}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Care Plan Physician Name</p>
                  <p className="sub">{viewEvent?.AssigneeName}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Schedule Type</p>
                  <p className="sub">{viewEvent?.scheduleType}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Schedule Date & Time</p>
                  <p className="sub">
                    {viewEvent?.Date}-{viewEvent?.time}
                  </p>
                </div>
                <div className="displayElement">
                  <p className="head">Duration</p>
                  <p className="sub">{viewEvent?.callDuration} Min</p>
                </div>
                <div className="displayElement">
                  <p className="head">Appointment Type</p>
                  <p className="sub">{viewEvent?.appointmentType}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Before Visit Instruction To Patient</p>
                  <p className="sub">{viewEvent?.patientInstructions}</p>
                </div>
              </div>
              <div className="element">
                <div className="displayElement">
                  <p className="head">Facility Name</p>
                  <p className="sub"></p>
                </div>
                <div className="displayElement">
                  <p className="head">Primary Care Coordinator</p>
                  <p className="sub"></p>
                </div>
                <div className="displayElement">
                  <p className="head">Schedule Status</p>
                  <p className="sub">{viewEvent?.callStatus}</p>
                </div>
                <div className="displayElement">
                  <p className="head">Schedule Assigned To</p>
                  <p className="sub">
                    {viewEvent?.serviceProviderProfileId?.serviceProviderName}
                  </p>
                </div>
                <div className="displayElement">
                  <p className="head">Purpose of the Call</p>
                  <p className="sub">{viewEvent?.callPurpose}</p>
                </div>
              </div>
            </div>
            <div className="eventViewButtons">
              <button onClick={onClickCallPatient}>Call Patient</button>
              <button onClick={() => onClickReshedule(viewEvent)}>
                Reschedule
              </button>
              <button onClick={CancelSchedule}>Cancel Schedule</button>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default EventViewer;
