import React, { useState, useEffect, useCallback, useRef } from "react";
import InputTextField1 from "../../widgets/InputTextField1";
import InputTextArea1 from "../../widgets/InputTextArea1";
import InputCheckField1 from "../../widgets/InputCheckField1";
import DatePickerField1 from "../../widgets/DatePickerField1";
import ButtonComponent from "../../components/ButtonComponent";
import FloatingNotifications from "../../utilities/FloatingNotifications";
import {
  useClinicianCreationMutation,
  useUpdateClinicianMutation,
  useAdminClinicianCreationMutation,
  useUpdateAdminClinicianMutation,
} from "../../app/api/clinicianApi";
import TechnicianAlotmentTab from "../../components/TechnicianAlotmentTab";
import InputSelectorField1 from "../../widgets/InputSelectorField1";
import { useGetProviderAccessLevelQuery } from "../../app/api/providerAccessLevelApi";
import {
  isNotEmpty,
  alwaysValidated,
  noCharValidateCheck,
  selectorValidateCheck,
  dateValidateCheck,
  phNoValidation,
  phNoCharValidation,
} from "../../utilities/validationFunction";
import { useGetSelectedClinicianMutation } from "../../app/api/clinicianApi";
import { clinicianDataActions } from "../../app/features/clinicianDataSlice";
import { useSelector, useDispatch } from "react-redux";
import "./clinician.scss";
import { useLocation } from "react-router-dom";
const error_message = {
  role: "Role should not be empty",
  email: "email should not be empty",
  NPI: "NPI should not be empty",
  FirstName: "First name should not be empty",
  MiddleName: "Middle name should not be empty",
  LastName: "Last name should not be empty",
  phone: "Phone no should not be empty",
  TimeZone: "TimeZone should not be empty",
  workPhone: "Work phone should not be empty",
  EffectiveDate: "Effective date should not be empty",
  PrimaryCommunication: "Preferred communication should not be empty",
  Notes: "Notes should not be empty",
  Speciality: "Speciality should not be empty",
  EmploymentType: "Employment type should not be empty",
  LicenseType: "License type should not be empty",
  rpmAlert: "rpmAlert should not be empty",
};

const Clinician = () => {
  const location = useLocation();
  const [id, setId] = useState(location?.state?.id);
  const [type, setType] = useState(location?.state?.type);
  const storedData = localStorage?.getItem("RLH_user");
  const createdDateAndTime = new Date();
  const dispatch = useDispatch();

  const clinicianData = useSelector((state) => state.clinicianData);

  const { data: inputData, isValidData } = clinicianData;
  const [accessDataFacility, setAccessDataFacility] = useState();

  const { data: rawAccessLevelData } = useGetProviderAccessLevelQuery();

  useEffect(() => {
    if (!rawAccessLevelData) return;
    if (selectedClinician) return;
    setAccessDataFacility(rawAccessLevelData);
  }, [rawAccessLevelData]);

  const [createClinician, { data, error, isLoading }] =
    useClinicianCreationMutation();
  const [
    createAdminClinician,
    {
      data: adminClinicianData,
      error: errCreatingAdminClinician,
      isLoading: isLoadingAdmin,
    },
  ] = useAdminClinicianCreationMutation();
  const [
    updateAdminClinician,
    {
      data: AdminupdateData,
      error: AdminupdateError,
      isLoading: AdminupdateIsLoading,
    },
  ] = useUpdateAdminClinicianMutation();
  const [
    updateClinician,
    { data: updateData, error: updateError, isLoading: updateIsLoading },
  ] = useUpdateClinicianMutation();

  const [selectedClinicianCallFn, { data: selectedClinician }] =
    useGetSelectedClinicianMutation();

  const [resetRefs, setResetRefs] = useState({
    role: useRef(null),
    email: useRef(null),
    NPI: useRef(null),
    FirstName: useRef(null),
    MiddleName: useRef(null),
    LastName: useRef(null),
    phone: useRef(null),
    TimeZone: useRef(null),
    workPhone: useRef(null),
    EffectiveDate: useRef(null),
    PrimaryCommunication: useRef(null),
    Notes: useRef(null),
    Speciality: useRef(null),
    EmploymentType: useRef(null),
    LicenseType: useRef(null),
    rpmAlert: useRef(null),
    webAccess: useRef(null),
  });

  const storeData = useCallback((fieldName, value, validityStatus) => {
    dispatch(
      clinicianDataActions.updateData({ fieldName, value, validityStatus })
    );
  }, []);

  const InitData = () => {
    Object.keys(resetRefs).forEach((value) => {
      // if (value === "insuranceCompany") return;
      if (selectedClinician[value]) {
        resetRefs[value].current?.init_data?.(selectedClinician[value]);
      }
    });
  };

  const onClick = () => {
    Object.keys(resetRefs).forEach((value) => {
      console.log(value);
      resetRefs[value].current.submit();
    });

    const valid = Object.keys(isValidData).reduce(
      (prev, curr) => prev && isValidData[curr],
      true
    );
    if (!valid) return;
    let accessDataFacilityUpdated = accessDataFacility.filter(
      (object) => object.Selected === true
    );
    if (inputData.role === "admin clinician") {
      const { role, ...rest } = inputData;
      if (selectedClinician) {
        updateAdminClinician({
          ClinicianId: id,
          ...rest,
        });
      } else {
        createAdminClinician({
          ...rest,
        });
      }
      return;
    }
    if (selectedClinician) {
      updateClinician({
        ClinicianId: id,
        FacilityList: accessDataFacilityUpdated,
        ...inputData,
      });
    } else {
      createClinician({
        ...inputData,
        FacilityList: accessDataFacilityUpdated,
      });
    }
  };
  const onClickReset = () => {
    resetBasicInfo();
  };
  useEffect(() => {
    if (!error && !updateError && errCreatingAdminClinician && AdminupdateError)
      return;
    if (error) {
      FloatingNotifications("error", error?.data?.message);
    } else if (updateError) {
      FloatingNotifications("error", updateError?.data?.message);
    } else if (errCreatingAdminClinician) {
      FloatingNotifications("error", "Error creating admin clinician");
    } else if (AdminupdateError) {
      FloatingNotifications("error", "Error updating admin clinician");
    }
  }, [error, updateError, errCreatingAdminClinician, AdminupdateError]);
  useEffect(() => {
    if (!selectedClinician) return;
    Object.keys(resetRefs).forEach((value) => {
      if (selectedClinician[value]) {
        resetRefs[value]?.current?.init_data?.(selectedClinician[value]);
      }
    });
    setAccessDataFacility(selectedClinician.FacilityList);
  }, [resetRefs, selectedClinician]);

  const resetBasicInfo = () => {
    Object.keys(resetRefs).forEach((value) => {
      resetRefs[value].current.reset_data();
    });
    setAccessDataFacility(rawAccessLevelData);
    resetRefs["EffectiveDate"]?.current?.init_data?.(new Date());
  };

  useEffect(() => {
    if (!data) return;
    resetBasicInfo();
    clinicianDataActions.resetData();
    FloatingNotifications("success", "Clinician creation successful");
  }, [data]);
  useEffect(() => {
    if (!adminClinicianData) return;
    resetBasicInfo();
    clinicianDataActions.resetData();
    FloatingNotifications("success", "Admin clinician creation successful");
  }, [adminClinicianData]);

  useEffect(() => {
    if (!updateData) return;
    FloatingNotifications("success", "Clinician updation successful");
    resetBasicInfo();
  }, [updateData]);

  useEffect(() => {
    if (!id) return;
    console.log("id:", id);

    selectedClinicianCallFn({
      ClinicianId: id,
    });
  }, [id, selectedClinicianCallFn]);

  useEffect(() => {
    if (!selectedClinician) return;
    InitData();
  }, [selectedClinician]);

  useEffect(() => {
    if (selectedClinician) return;
    resetRefs["EffectiveDate"]?.current?.init_data?.(new Date());
    resetRefs["role"]?.current?.init_data?.("clinician");
  }, []);

  return (
    <div className="clinician">
      <div className="clinicianContainer">
        <div className="pageTitle">Clinician</div>
        <div className="clinicianContainer1">
          <InputSelectorField1
            tabIndex="1"
            element={"role"}
            disabled={type === "View"}
            ref={resetRefs["role"]}
            containerClassName="colSpan3"
            title="Role"
            optionsArray={["admin clinician", "clinician"]}
            superTitle="*"
            validation={selectorValidateCheck}
            charValidate={noCharValidateCheck}
            autoFocus={true}
            error_message={error_message["role"]}
            storeData={storeData.bind(null, "role")}
          />

          <InputTextField1
            tabIndex="1"
            element={"FirstName"}
            ref={resetRefs["FirstName"]}
            disabled={type === "View"}
            containerClassName="colSpan3"
            title="First Name"
            superTitle="*"
            validation={isNotEmpty}
            charValidate={noCharValidateCheck}
            error_message={error_message["FirstName"]}
            storeData={storeData.bind(null, "FirstName")}
          />
          <InputTextField1
            tabIndex="1"
            element={"MiddleName"}
            disabled={type === "View"}
            ref={resetRefs["MiddleName"]}
            containerClassName="colSpan3"
            title="Middle Name"
            validation={alwaysValidated}
            charValidate={noCharValidateCheck}
            error_message={error_message["MiddleName"]}
            storeData={storeData.bind(null, "MiddleName")}
          />
          <InputTextField1
            tabIndex="1"
            element={"LastName"}
            disabled={type === "View"}
            ref={resetRefs["LastName"]}
            containerClassName="colSpan3"
            title="Last Name"
            superTitle="*"
            validation={isNotEmpty}
            charValidate={noCharValidateCheck}
            error_message={error_message["LastName"]}
            storeData={storeData.bind(null, "LastName")}
          />
          <InputTextField1
            disabled={type === "View"}
            tabIndex="1"
            element={"NPI"}
            ref={resetRefs["NPI"]}
            containerClassName="colSpan2"
            title="NPI"
            autoFocus={true}
            validation={alwaysValidated}
            charValidate={noCharValidateCheck}
            error_message={error_message["NPI"]}
            storeData={storeData.bind(null, "NPI")}
          />
          <InputTextField1
            element={"email"}
            tabIndex="1"
            ref={resetRefs["email"]}
            disabled={type === "View"}
            containerClassName="colSpan3"
            title="Email"
            superTitle="*"
            validation={isNotEmpty}
            charValidate={noCharValidateCheck}
            error_message={error_message["email"]}
            storeData={storeData.bind(null, "email")}
          />
          <InputTextField1
            element={"workPhone"}
            tabIndex="1"
            ref={resetRefs["workPhone"]}
            disabled={type === "View"}
            containerClassName="colSpan2"
            title="Work Phone"
            validation={alwaysValidated}
            charValidate={phNoCharValidation}
            error_message={error_message["workPhone"]}
            storeData={storeData.bind(null, "workPhone")}
          />
          <InputTextField1
            element={"phone"}
            tabIndex="1"
            ref={resetRefs["phone"]}
            disabled={type === "View"}
            containerClassName="colSpan2"
            title="Mobile Phone"
            validation={alwaysValidated}
            charValidate={phNoCharValidation}
            error_message={error_message["phone"]}
            storeData={storeData.bind(null, "phone")}
          />
          <InputSelectorField1
            disabled={type === "View"}
            element={"TimeZone"}
            tabIndex="1"
            ref={resetRefs["TimeZone"]}
            containerClassName="colSpan2"
            title="TimeZone"
            optionsArray={[
              "Eastern Time",
              "Central Time",
              "Mountain Time",
              "Arizona Time(No Daylight Saving)",
              "Pacific Time",
              "Alaska Time",
              "Hawai Time",
            ]}
            validation={alwaysValidated}
            error_message={error_message["TimeZone"]}
            storeData={storeData.bind(null, "TimeZone")}
          />
          <InputTextField1
            element={"Speciality"}
            tabIndex="1"
            ref={resetRefs["Speciality"]}
            disabled={type === "View"}
            containerClassName="colSpan3"
            title="Speciality"
            validation={alwaysValidated}
            charValidate={noCharValidateCheck}
            error_message={error_message["Speciality"]}
            storeData={storeData.bind(null, "Speciality")}
          />
          <InputSelectorField1
            element={"EmploymentType"}
            tabIndex="1"
            ref={resetRefs["EmploymentType"]}
            disabled={type === "View"}
            containerClassName="colSpan4"
            title="Employment Type"
            optionsArray={["Direct Employee", "Contractual"]}
            superTitle="*"
            validation={selectorValidateCheck}
            charValidate={noCharValidateCheck}
            error_message={error_message["EmploymentType"]}
            storeData={storeData.bind(null, "EmploymentType")}
          />
          <InputSelectorField1
            element={"LicenseType"}
            tabIndex="1"
            ref={resetRefs["LicenseType"]}
            disabled={type === "View"}
            containerClassName="colSpan3"
            title="License Type"
            superTitle="*"
            optionsArray={[
              "Advanced Practice Registered Nurse",
              "Physician Assistant",
              "Registered Nurse",
              "Licensed Clinical Social Worker",
              "Licenced Social worker",
              "Licensed Practical Nurse",
              "Clinical Pharmacists",
              "Medical Assistant",
              "Registered Dietician Nutritionist",
              "Physician Resident",
              "Certified Nursing Assistant",
            ]}
            validation={selectorValidateCheck}
            charValidate={noCharValidateCheck}
            error_message={error_message["LicenseType"]}
            storeData={storeData.bind(null, "LicenseType")}
          />
          <DatePickerField1
            disabled={type === "View"}
            element={"EffectiveDate"}
            tabIndex="1"
            ref={resetRefs["EffectiveDate"]}
            containerClassName="colSpan2"
            title="Effective Date"
            superTitle="*"
            validation={dateValidateCheck}
            error_message={error_message["EffectiveDate"]}
            storeData={storeData.bind(null, "EffectiveDate")}
          />
          {/* <InputTextField1
            element={"Speciality"}
            tabIndex="1"
            ref={resetRefs["Speciality"]}
            containerClassName="colSpan3"
            title="Speciality"
            superTitle="*"
            validation={isNotEmpty}
            charValidate={noCharValidateCheck}
            error_message={error_message["Speciality"]}
            storeData={storeData.bind(null, "Speciality")}
          /> */}
          <InputSelectorField1
            disabled={type === "View"}
            element={"PrimaryCommunication"}
            tabIndex="1"
            ref={resetRefs["PrimaryCommunication"]}
            containerClassName="colSpan4"
            title="Preferred Communication "
            optionsArray={["Mobile Phone", "Work Phone", "email"]}
            superTitle="*"
            validation={selectorValidateCheck}
            error_message={error_message["PrimaryCommunication"]}
            storeData={storeData.bind(null, "PrimaryCommunication")}
          />

          <InputSelectorField1
            element={"rpmAlert"}
            tabIndex="1"
            ref={resetRefs["rpmAlert"]}
            disabled={type === "View"}
            containerClassName="colSpan4"
            title="RPM Alert"
            optionsArray={["All Patients", "Assigned Patients"]}
            superTitle="*"
            validation={selectorValidateCheck}
            error_message={error_message["rpmAlert"]}
            storeData={storeData.bind(null, "rpmAlert")}
          />
        </div>
        <div className="clinicianContainer2">
          {inputData.role !== "admin clinician" && (
            <div className="colSpan1 rowSpan3">
              <TechnicianAlotmentTab
                title="Facility"
                accessData={accessDataFacility}
                setAccessData={setAccessDataFacility}
                idKey={"FacilityId"}
                nameKey={"FacilityName"}
                disabled={type === "View"}
              />
            </div>
          )}
          <InputTextArea1
            element={"Notes"}
            tabIndex="1"
            ref={resetRefs["Notes"]}
            disabled={type === "View"}
            title="Notes"
            heightMultiplier={3}
            validation={alwaysValidated}
            charValidate={noCharValidateCheck}
            error_message={error_message["Notes"]}
            storeData={storeData.bind(null, "Notes")}
          />
          <div></div>
          {/* <InputCheckField1
            element={"mobileAccess"}
            tabIndex="1"
            ref={resetRefs["mobileAccess"]}
            disabled={type === "View"}
            containerClassName="ase"
            title="RPM Alert"
            text={"Enable Mobile Access"}
            validation={alwaysValidated}
            error_message={error_message["mobileAccess"]}
            storeData={storeData.bind(null, "mobileAccess")}
          /> */}
          <InputCheckField1
            element={"webAccess"}
            tabIndex="1"
            ref={resetRefs["webAccess"]}
            disabled={type === "View"}
            containerClassName="ase"
            // title="RPM Alert"
            text={"Enable web Access"}
            validation={alwaysValidated}
            error_message={error_message["webAccess"]}
            storeData={storeData.bind(null, "webAccess")}
          />
          <div className="created">
            <p>createdBy: {storedData}</p>
            <p>createdDate: {createdDateAndTime?.toString().slice(0, 24)}</p>
          </div>
        </div>

        <div className="btnWrap">
          <ButtonComponent
            tabIndex="2"
            containerClassName="buttonContainer"
            text="Save"
            onClick={onClick}
            disabled={type === "View" || isLoading}
          />
          <ButtonComponent
            tabIndex="2"
            containerClassName="buttonContainer"
            text="Reset"
            onClick={onClickReset}
            disabled={type === "View" || isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Clinician;
