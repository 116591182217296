import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import IconButton from "@mui/material/IconButton";
import SelectComponent2 from "../components/SelectCompoent2";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
import "./callTaskListViewTable.scss";
const dataFiltersInitialState = {
  facility: "All",
  provider: "All",
  clinician: "All",
  patient: "",
  scheduleType: "All",
};
const CallTaskListViewTable = ({ data }) => {
  const [dataFilters, setDataFilters] = useState(dataFiltersInitialState);
  const [filteredData, setFilteredData] = useState([]);

  const { data: fpcList } = useGetPatientsOptionsQuery();
  const FacilityList =
    fpcList?.FacilityList?.map((facility) => [
      facility.FacilityName,
      facility.FacilityId,
    ]) ?? [];
  FacilityList.unshift(["All", ""]);
  const ProviderList =
    fpcList?.ProviderList?.map((provider) => [
      provider.ProviderName,
      provider.ProviderId,
    ]) ?? [];
  ProviderList.unshift(["All", ""]);
  const ClinicianList =
    fpcList?.ClinicianList?.map((clinician) => [
      clinician.ClinicianName,
      clinician.ClinicianId,
    ]) ?? [];
  ClinicianList.unshift(["All", ""]);
  useEffect(() => {
    if (!data) return;
    let dummyData = data;
    // if (dataFilters.facility !== "") {
    //   dummyData = dummyData?.filter(
    //     (d) => d?.patientInfo?.facilityInfo?.FacilityId === dataFilters.facility
    //   );
    // }
    // if (dataFilters.provider !== "") {
    //   dummyData = dummyData?.filter(
    //     (d) => d?.patientInfo?.providerInfo?.ProviderId === dataFilters.provider
    //   );
    // }
    // if (dataFilters.clinician !== "") {
    //   dummyData = dummyData?.filter(
    //     (d) =>
    //       d?.patientInfo?.coordinatorInfo?.CoordinatorId ===
    //       dataFilters.clinician
    //   );
    // }
    if (dataFilters.scheduleType !== "") {
      if (dataFilters.scheduleType !== "All") {
        dummyData = dummyData?.filter(
          (d) => d?.scheduleType === dataFilters.scheduleType
        );
      }
    }
    if (dataFilters.patient !== "") {
      dummyData = dummyData?.filter((d) =>
        d?.patientId?.PatientName?.toLowerCase().includes(
          dataFilters?.patient?.toLowerCase()
        )
      );
    }

    setFilteredData(dummyData);
  }, [dataFilters, data]);
  return (
    <div className="ViewTable">
      <div className="advanceSearchBox">
        <div className="box">
          <p className="head">Facility</p>
          <SelectComponent2
            OptionsDict={FacilityList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.facility}
            setvalue={setDataFilters}
            name={"facility"}
          />
        </div>
        <div className="box">
          <p className="head">Physician</p>
          <SelectComponent2
            OptionsDict={ProviderList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.provider}
            setvalue={setDataFilters}
            name={"provider"}
          />
        </div>
        <div className="box">
          <p className="head">Clinician</p>
          <SelectComponent2
            OptionsDict={ClinicianList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.clinician}
            setvalue={setDataFilters}
            name={"clinician"}
          />
        </div>
        <div className="box">
          <p className="head">schedule Type</p>
          <SelectComponent2
            OptionsArray={["All", "Phone call", "In-person", "Video call"]}
            fn={generalHandleValueChanges}
            valueData={dataFilters.scheduleType}
            setvalue={setDataFilters}
            name={"scheduleType"}
          />
        </div>

        <div className="box">
          <p className="head">Patient Name</p>
          <input
            value={dataFilters.patient}
            onChange={(e) => {
              setDataFilters((prev) => ({
                ...prev,
                patient: e.target.value,
              }));
            }}
          />
        </div>
      </div>
      <div className="prevNext">
        {/* <div className="prevBtn">
          <button>Previous</button>
          <button>Next</button>
        </div> */}
      </div>
      <div className="gridBody">
        <div className="gridHeader">
          <div className="colSpan1 gridHead">S.No</div>
          <div className="colSpan1 gridHead">Patient Name</div>
          <div className="colSpan1 gridHead">Patient DOB</div>
          <div className="colSpan1 gridHead">Care Plan Physician</div>
          <div className="colSpan1 gridHead">Care Plan Physician</div>
          <div className="colSpan2 gridHead">Schedule Date & Time</div>
          <div className="colSpan1 gridHead">Schedule Type</div>
          <div className="colSpan2 gridHead">Purpose of the Call</div>
          <div className="colSpan1 gridHead">Schedule Status</div>
          <div className="colSpan2 gridHead">Billable Minutes (HH:MI:SS)</div>
          <div className="colSpan1 gridHead">Action</div>
        </div>
        <div className="gridContent">
          {filteredData?.map((el, index) => (
            <>
              <div className="colSpan1 gridBox">{index + 1}</div>
              <div className="colSpan1 gridBox">
                {el?.patientId?.PatientName}
              </div>
              <div className="colSpan1 gridBox">Patient DOB</div>
              <div className="colSpan1 gridBox">Care Plan Physician</div>
              <div className="colSpan1 gridBox">Care Plan Physician</div>
              <div className="colSpan2 gridBox">
                {el?.StartDateTime?.slice(11, 19)}/
                {el?.StartDateTime.slice(0, 10)}
              </div>
              <div className="colSpan1 gridBox">{el?.scheduleType}</div>
              <div className="colSpan2 gridBox">{el?.callPurpose}</div>
              <div className="colSpan1 gridBox">{el?.callStatus}</div>
              <div className="colSpan2 gridBox">
                Billable Minutes (HH:MI:SS)
              </div>
              <div className="colSpan1 gridBox">
                <IconButton
                // onClick={() => onClickCarePlan(row.PatientId)}
                >
                  <Icon
                    icon="material-symbols:contact-page-outline"
                    style={{ height: "2rem", width: "2rem" }}
                  />
                </IconButton>
                <IconButton
                // onClick={() => onClickCall(row.PatientId)}
                >
                  <Icon
                    icon="ic:round-call"
                    style={{ height: "2rem", width: "2rem" }}
                  />
                </IconButton>
              </div>
            </>
          ))}
        </div>
      </div>
      {/* <div className="prevNext">
        <p>Showing 0 to 0 of 0 entries</p>
        <div className="prevBtn">
          <button>Previous</button>
          <button>Next</button>
        </div>
      </div> */}
    </div>
  );
};

export default CallTaskListViewTable;
