import React, { useEffect, useState } from "react";
import SelectComponent from "../components/SelectComponent";
import TabWidget2 from "../components/TabWidget";
import TabElement2 from "../components/TabElement";
import { useGetBillingHistoryMutation } from "../app/api/billingApi";
import "./billing.scss";
import { Icon } from "@iconify/react";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
import { useCallback } from "react";
import { utils, writeFile } from "xlsx";
import SelectComponent2 from "../components/SelectCompoent2";
import PopUp from "../patients/patientViewPage/PopUp";
import { useAddBulkServiceMutation } from "../app/api/billingApi";
import AddBulkService from "./AddBulkService";
import LoaderComponent from "../components/LoaderComponent";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi";
import AddService from "../patients/parientsPreviewPage/AddService";
import RemarksViewer from "../components/RemarksViewer";
import TimeSpentInput from "../components/SelectTimeSpentComp";

const servicesTypeList = ["Detail View", "Summary View"];
function utcToNormalDateTime(timestamp) {
  const utcDate = new Date(timestamp);

  const year = utcDate.getUTCFullYear();
  const month = utcDate.getUTCMonth() + 1;
  const day = utcDate.getUTCDate();

  const hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();
  const seconds = utcDate.getUTCSeconds();

  const amOrPm = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12;

  const formattedDateTime = `${year}-${String(month).padStart(2, "0")}-${String(
    day
  ).padStart(2, "0")} ${formattedHours}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")} ${amOrPm}`;

  return formattedDateTime;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Billing = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [bulkPopUpOpen, setBulkPopUpOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [patientDataForService, setPatientDataForService] = useState("");

  const [getBillingHistoryFn, { data: billingHistory, isLoading }] =
    useGetBillingHistoryMutation(undefined, {
      refetchOnMountOrArgChange: true,
    });
  const [addBulkServiceFn, { data, error }] = useAddBulkServiceMutation(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [historyToDelete, setHistoryToDelete] = useState(false);
  const [billingServiceData, setBillingServiceData] = useState("");
  const [filteredServiceData, setFilteredServiceData] = useState([]);
  const [servicesType, setServicesType] = useState("Detail View");
  const [editData, setEditData] = useState();

  const servicesTypeHandler = (el) => {
    setServicesType(el);
  };
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const initialState = {
    programName: "CCM",
    serviceName: "Care Plan Entry",
    month: months[currentMonth],
    year: currentYear,
    billOnlyActivePatients: true,
  };
  const dataFiltersInitialState = {
    facility: "",
    provider: "",
    clinician: "",
    patient: "",
    range: "All",
    lowerRange: "",
    higherRange: "",
  };
  const { data: fpcList } = useGetPatientsOptionsQuery();
  const FacilityList =
    fpcList?.FacilityList?.map((facility) => [
      facility.FacilityName,
      facility.FacilityId,
    ]) ?? [];
  FacilityList.unshift(["All", ""]);
  const ProviderList =
    fpcList?.ProviderList?.map((provider) => [
      provider.ProviderName,
      provider.ProviderId,
    ]) ?? [];
  ProviderList.unshift(["All", ""]);
  const ClinicianList =
    fpcList?.ClinicianList?.map((clinician) => [
      clinician.ClinicianName,
      clinician.ClinicianId,
    ]) ?? [];
  ClinicianList.unshift(["All", ""]);
  const [filter, setFilter] = useState(initialState);
  const [dataFilters, setDataFilters] = useState(dataFiltersInitialState);

  const cancelfn = () => {
    setEditData();
    setPopUpOpen(false);
  };
  const cancelfn2 = () => {
    setBulkPopUpOpen(false);
  };
  const delectePopUpcancelFn = () => {
    setHistoryToDelete("");
    setDeletePopUp(false);
  };
  const onClickDelete = (log) => {
    setHistoryToDelete(log);
    setDeletePopUp(true);
  };
  const onClickDeleteConfirmed = () => {
    if (!historyToDelete) return;
    // changeUserStatusFn({
    //   username: hostoryToDelete.email,
    //   userType: hostoryToDelete.userType.toLowerCase(),
    // });
    setDeletePopUp(false);
  };
  const openfn = (patientId, PatientName) => {
    setPatientDataForService({
      Name: PatientName,
      PatientId: patientId,
    });
    setPopUpOpen(true);
  };
  const onHandleExport = useCallback(async () => {
    let data = [];
    filteredServiceData?.forEach((patient) => {
      patient?.services?.forEach((service) => {
        data.push({
          "Patient Name": patient?.patientInfo?.PatientName ?? "",
          "Patient id": patient?.PatientId ?? "",
          DOB: patient?.patientInfo?.DOB ?? "",
          "Medicare Number": patient?.patientInfo?.MedicareNumber ?? "",
          "Patient EHR Id": patient?.patientInfo?.PatientEHRId ?? "",
          "Health Insurance": patient?.patientInfo?.InsuranceName ?? "",
          "Insurance Id": patient?.patientInfo?.InsuranceId ?? "",
          Facility: patient?.patientInfo?.facilityInfo?.FacilityName ?? "",
          "provider Name":
            patient?.patientInfo?.providerInfo?.ProviderName ?? "",
          "ICD Codes":
            patient?.patientInfo?.Diagnosis?.map(
              (e) => `${e.ICD_Description} (${e.Type})`
            ).join(", ") ?? "",
          "provider NPI": patient?.patientInfo?.providerInfo?.NPI ?? "",
          "Primary Care Coordinator":
            patient?.patientInfo?.coordinatorInfo?.CoordinatorName ?? "",
          "Service Date": service?.startTime ?? "",
          "Service Time": service?.timeSpent ?? "",
          "Service Type": service?.serviceType ?? "",
          "Activity Performed By":
            service?.serviceProviderProfile?.FullName ?? "",
          "Time Spent (HH:MI:SS)": service?.acutalTimeSpent ?? "",
        });
      });
    });

    const ws = utils.json_to_sheet(data);

    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, "Data");

    writeFile(wb, `${filter.programName}_${filter.month}_${filter.year}.xlsx`);
  }, [filteredServiceData, filter]);
  const onHandleExportSummary = useCallback(async () => {
    let data = [];
    filteredServiceData?.forEach((patient) => {
      const patientData = {
        "Patient Name": patient?.patientInfo?.PatientName ?? "",
        "Patient id": patient?.PatientId ?? "",
        DOB: patient?.patientInfo?.DOB ?? "",
        "Medicare Number": patient?.patientInfo?.MedicareNumber ?? "",
        "Patient EHR Id": patient?.patientInfo?.PatientEHRId ?? "",
        "Health Insurance": patient?.patientInfo?.InsuranceName ?? "",
        "Insurance Id": patient?.patientInfo?.InsuranceId ?? "",
        Facility: patient?.patientInfo?.facilityInfo?.FacilityName ?? "",
        "provider Name": patient?.patientInfo?.providerInfo?.ProviderName ?? "",
        "ICD Codes":
          patient?.patientInfo?.Diagnosis?.map(
            (e) => `${e.ICD_Description} (${e.Type})`
          ).join(", ") ?? "",
        "provider NPI": patient?.patientInfo?.providerInfo?.NPI ?? "",
        "Primary Care Coordinator":
          patient?.patientInfo?.coordinatorInfo?.CoordinatorName ?? "",

        "Time Spent (HH:MI:SS)": patient?.totalTimeSpent ?? "",
      };

      patient?.summaryInfo?.cpt?.forEach((e, index) => {
        patientData[`code${index + 1}`] = e?.code;
        patientData[`type${index + 1}`] = e?.type;
      });

      data.push(patientData);
    });

    const ws = utils.json_to_sheet(data);

    const wb = utils.book_new();

    utils.book_append_sheet(wb, ws, "Data");

    writeFile(wb, `${filter.programName}_${filter.month}_${filter.year}.xlsx`);
  }, [filteredServiceData, filter]);

  const setFilterDefault = () => {
    setDataFilters({
      facility: "",
      provider: "",
      clinician: "",
      patient: "",
      range: "All",
      lowerRange: "",
      higherRange: "",
    });
    setFilter(initialState);
  };
  const onClickEdit = (log, patientData) => {
    setPatientDataForService({
      Name: patientData?.patientInfo?.PatientName,
      PatientId: patientData?.PatientId,
    });
    setEditData({
      ...log,
      programName: log?.programName,
    });
    setPopUpOpen(true);
  };

  const getBillingHistory = () => {
    getBillingHistoryFn({
      // serviceName: filter.serviceName,
      programName: filter.programName,
      month: filter.month,
      year: filter.year,
    });
    setSelectedData([]);
  };
  const handleCheckBox = (event, elem) => {
    if (event.target.checked) {
      setSelectedData((prev) => {
        if (!prev.includes(elem)) {
          return [...prev, elem];
        }
        return prev;
      });
    } else {
      setSelectedData((prev) => {
        if (prev.includes(elem)) {
          return prev.filter((item) => item !== elem);
        }
        return prev;
      });
    }
  };
  useEffect(() => {
    getBillingHistory();
  }, [filter]);
  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString?.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  useEffect(() => {
    if (!billingServiceData) return;
    let dummyData = billingServiceData;
    if (dataFilters.facility !== "") {
      dummyData = dummyData?.filter(
        (data) =>
          data?.patientInfo?.facilityInfo?.FacilityId === dataFilters.facility
      );
    }
    if (dataFilters.provider !== "") {
      dummyData = dummyData?.filter(
        (data) =>
          data?.patientInfo?.providerInfo?.ProviderId === dataFilters.provider
      );
    }
    if (dataFilters.clinician !== "") {
      dummyData = dummyData?.filter(
        (data) =>
          data?.patientInfo?.coordinatorInfo?.CoordinatorId ===
          dataFilters.clinician
      );
    }
    if (dataFilters.patient) {
      dummyData = dummyData?.filter((data) =>
        data?.patientInfo?.PatientName?.toLowerCase().includes(
          dataFilters?.patient?.toLowerCase()
        )
      );
    }
    if (dataFilters.range !== "") {
      switch (dataFilters.range) {
        case "Greater Than":
          dummyData = dummyData?.filter((data) => {
            if (data?.totalTimeSpent) {
              return (
                timeStringToSeconds(data?.totalTimeSpent) >
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "Less Than":
          dummyData = dummyData?.filter((data) => {
            if (data?.totalTimeSpent) {
              return (
                timeStringToSeconds(data?.totalTimeSpent) <
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "Equal To":
          dummyData = dummyData?.filter((data) => {
            if (data?.totalTimeSpent) {
              return (
                timeStringToSeconds(data?.totalTimeSpent) ===
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "Greater Than or Equal To":
          dummyData = dummyData?.filter((data) => {
            if (data?.totalTimeSpent) {
              return (
                timeStringToSeconds(data?.totalTimeSpent) >=
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "Less Than or Equal To":
          dummyData = dummyData?.filter((data) => {
            if (data?.totalTimeSpent) {
              return (
                timeStringToSeconds(data?.totalTimeSpent) <=
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "In Between":
          dummyData = dummyData?.filter((data) => {
            if (data?.totalTimeSpent && dataFilters?.higherRange) {
              return (
                timeStringToSeconds(data?.totalTimeSpent) >
                  timeStringToSeconds(dataFilters?.lowerRange) &&
                timeStringToSeconds(data?.totalTimeSpent) <
                  timeStringToSeconds(dataFilters?.higherRange)
              );
            } else {
              return false;
            }
          });
          break;

        default:
          break;
      }
    }
    setFilteredServiceData(dummyData);
  }, [dataFilters, billingServiceData]);

  useEffect(() => {
    if (billingHistory) {
      setBillingServiceData(billingHistory);
    }
  }, [billingHistory]);

  useEffect(() => {
    if (selectedData.length > 0) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [selectedData]);
  return (
    <div className="billing">
      <LoaderComponent isLoading={isLoading} />

      {deletePopUp && (
        <PopUp cancelfn={delectePopUpcancelFn}>
          <div>
            <p>
              Are you sure you want to deactivate the user{" "}
              {historyToDelete?.username}
            </p>
            <div className="buttonsContainer">
              <button onClick={onClickDeleteConfirmed}>Deactivate</button>
              <button onClick={delectePopUpcancelFn}>cancel</button>
            </div>
          </div>
        </PopUp>
      )}
      {popUpOpen && (
        <PopUp cancelfn={cancelfn}>
          <AddService
            editData={editData}
            patientDataForService={patientDataForService}
            cancelfn={cancelfn}
            getBillingHistory={getBillingHistory}
          />
        </PopUp>
      )}
      {bulkPopUpOpen && (
        <PopUp cancelfn={cancelfn2}>
          <AddBulkService
            data={selectedData}
            cancelfn={cancelfn2}
            getBillingHistory={getBillingHistory}
          />
        </PopUp>
      )}
      <p className="title">Services</p>

      <div className="advanceSearchBox">
        <div className="box">
          <p className="head">Program</p>
          <SelectComponent
            OptionsArray={["CCM", "RPM", "BHI"]}
            fn={generalHandleValueChanges}
            valueData={filter.programName}
            setvalue={setFilter}
            name={"programName"}
          />
        </div>
        {/* <div className="box">
          <p className="head">Service</p>
          <SelectComponent
            OptionsArray={[
              "Scheduling Care Management Call",
              "Non Complex CCM Service",
              "Complex CCM Service",
              "Complex CCM Add on",
              "Prepare Call Summary Notes",
              "Counsel Patient",
              "E-Mail Communication to Patient",
              "Communicating Physician Appointment Requests",
              "Care Plan Entry",
              "Get Encounter Details From EMR for Care Plan Data Entry",
              "Review Care Plan",
              "Review and Add Notes to Care Summary",
              "Review Medication and Modify Prescriptions",
              "Medium to Complex Medical Decision Making",
              "Referral to Other Physicians",
              "Appointment Scheduling for In Person Visit",
              "Phone Communication to Patient Tracked Manually",
              "Call Summary Recommendations",
              "Communicating Appointment Schedule",
              "Directions for Patient Interaction",
              "Communicate Over Phone",
              "Communicate Over E-Mail",
              "Care Plan Entry and Edit",
              "Phone Call With Physician About Patient",
              "E-Mail Communication With Physician Patient",
              "Call from Pharmacy to Discuss Medication Allergies Reported By Patient",
              "Approve Prescription Refill",
              "Share Care Plan",
              "Obtain Encounter Details for Patient Visits",
              "Phone Call With Physician",
              "E-Mail Communication to Physician",
              "Order Lab Test",
              "Review Lab Reports",
              "Conversation About Patient",
              "E-Mail Communication to Care Givers",
              "Phone Communication With Care Givers",
              "Review of Consultant’s Recommendation",
              "Review of Home Health Care Plan",
              "Durable Medical Equipment Review",
              "Review of PT/OT Plan",
              "Patient Vital Reading Measurement and Review",
              "Patient Diet Analysis",
              "Other",
            ]}
            fn={generalHandleValueChanges}
            valueData={filter.serviceName}
            setvalue={setFilter}
            name={"serviceName"}
          />
        </div> */}
        <div className="box">
          <p className="head">Facility</p>
          <SelectComponent2
            OptionsDict={FacilityList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.facility}
            setvalue={setDataFilters}
            name={"facility"}
          />
        </div>
        <div className="box">
          <p className="head">Physician</p>
          <SelectComponent2
            OptionsDict={ProviderList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.provider}
            setvalue={setDataFilters}
            name={"provider"}
          />
        </div>
        <div className="box">
          <p className="head">Clinician</p>
          <SelectComponent2
            OptionsDict={ClinicianList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.clinician}
            setvalue={setDataFilters}
            name={"clinician"}
          />
        </div>

        <div className="box">
          <p className="head">Patient Name</p>
          <input
            value={dataFilters.patient}
            onChange={(e) => {
              setDataFilters((prev) => ({
                ...prev,
                patient: e.target.value,
              }));
            }}
          />
        </div>
        <div className="box ">
          <p className="head">Bill only Active Patients</p>
          <span className="checkbox">
            <input
              type="checkbox"
              checked={filter.billOnlyActivePatients}
              disabled={true}
              onChange={(e) => {
                setFilter((prev) => ({
                  ...prev,
                  billOnlyActivePatients: e.target.checked,
                }));
              }}
            />
          </span>
        </div>
        <div className="box">
          <p className="head">Month & Year</p>
          <div className="select2">
            <span>
              <SelectComponent
                OptionsArray={months}
                fn={generalHandleValueChanges}
                valueData={filter.month}
                setvalue={setFilter}
                name={"month"}
              />
            </span>
            <span>
              <SelectComponent
                OptionsArray={[2024]}
                fn={generalHandleValueChanges}
                valueData={filter.year}
                setvalue={setFilter}
                name={"year"}
              />
            </span>
          </div>
        </div>
        <div className="box colSpan2">
          <p className="head">Total Time Spent (HH:MI:SS)</p>
          <div className="select2">
            <span>
              <SelectComponent
                OptionsArray={[
                  "All",
                  "Greater Than",
                  "Less Than",
                  "Equal To",
                  "Greater Than or Equal To",
                  "Less Than or Equal To",
                  "In Between",
                ]}
                placeholder="Select Range"
                fn={generalHandleValueChanges}
                valueData={dataFilters.range}
                setvalue={setDataFilters}
                name={"range"}
              />
            </span>
            <span>
              {/* <input
                id="appt-time"
                type="time"
                name="appt-time"
                step="2"
                value={dataFilters?.lowerRange || "00:20:00"}
                // className="viewElement "
                onChange={(event) =>
                  generalHandleValueChanges(event, setDataFilters, "lowerRange")
                }
              /> */}
              <TimeSpentInput
                value={dataFilters?.lowerRange}
                setService={setDataFilters}
                flagName={"lowerRange"}
              />
            </span>
            {dataFilters?.range === "In Between" && (
              <span>
                {/* <input
                  id="appt-time"
                  type="time"
                  name="appt-time"
                  step="2"
                  value={dataFilters?.higherRange}
                  // className="viewElement "
                  onChange={(event) =>
                    generalHandleValueChanges(
                      event,
                      setDataFilters,
                      "higherRange"
                    )
                  }
                /> */}
                <TimeSpentInput
                  value={dataFilters?.higherRange}
                  setService={setDataFilters}
                  flagName={"higherRange"}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="buttons">
        <button onClick={() => setFilterDefault()}>Reset</button>
      </div>
      <div className="servicesBody">
        <TabWidget2>
          {servicesTypeList.map((el) => (
            <TabElement2
              key={`patients_${el}`}
              title={el}
              active={servicesType === el}
              onClick={() => servicesTypeHandler(el)}
            />
          ))}
        </TabWidget2>
        <div className="servicesButtons">
          {servicesType === "Detail View" ? (
            <>
              <button onClick={onHandleExport}>
                Billable Service Detail Report
              </button>
              <button
                disabled={btnDisabled}
                onClick={() => setBulkPopUpOpen(true)}
              >
                + Bulk Add Service
              </button>
            </>
          ) : (
            <button onClick={onHandleExportSummary}>
              Billable Summary Report
            </button>
            // <button>Billable Summary Report</button>
          )}
        </div>
        <div className="billTable">
          {servicesType === "Summary View" && (
            <>
              <p className="note">
                Note : As per the CMS guidelines, CPT 99453 (Device Setup) can
                be billed only once per patient. It will be automatically
                generated in the billing summary report, when the patient takes
                device readings for minimum 16 calendar days in a month for the
                first time. CPT 99454 (Monitoring through Device Readings) can
                be billed only when the patient takes device readings for
                minimum 16 calendar days in a month. Manual entry of readings
                will not be taken into account.
              </p>
              <div className="grid2">
                <div className="tableElement colSpan1">S.No</div>
                <div className="tableElement colSpan2">Patient Name</div>
                <div className="tableElement colSpan1">Facility Name</div>
                <div className="tableElement colSpan2">Care Plan Physician</div>
                <div className="tableElement colSpan2">
                  {`Primary ${filter?.programName} Clinician`}
                </div>
                <div className="tableElement colSpan1">CPT Code</div>
                <div className="tableElement colSpan2">Service Type</div>
                <div className="tableElement colSpan1">
                  Total Time (HH:MI:SS)
                </div>
                <div className="tableElement colSpan1">Remark</div>
              </div>
              {filteredServiceData &&
                filteredServiceData?.map((data, index) => (
                  <div className="grid2">
                    <div className="tableElement colSpan1">{index + 1}</div>
                    <div className="tableElement colSpan2">
                      {" "}
                      {data?.patientInfo?.PatientName}
                    </div>
                    <div className="tableElement colSpan1">
                      {" "}
                      {data?.patientInfo?.facilityInfo?.FacilityName}
                    </div>
                    <div className="tableElement colSpan2">
                      {data?.patientInfo?.providerInfo?.ProviderName}
                    </div>
                    <div className="tableElement colSpan2">
                      {data?.patientInfo?.coordinatorInfo?.CoordinatorName}
                    </div>
                    {/* <div className="tableElement colSpan1">
                      {data?.summaryInfo?.cpt?.map((log) => (
                        <div className="subComponent colSpan1 rowSpan1">
                          {log?.code}
                        </div>
                      ))}
                    </div> */}
                    <div className="subComponent colSpan5">
                      {data?.summaryInfo?.cpt?.map((log) => (
                        <div className="grid6">
                          <div className="subComponent colSpan1 ">
                            {log?.code}
                          </div>
                          <div className="subComponent colSpan2">
                            {log?.type}
                          </div>

                          <div className="subComponent colSpan1">
                            {log?.time}
                          </div>

                          <div className="subComponent colSpan1">
                            <RemarksViewer
                              content={{
                                PatientName: data?.patientInfo?.PatientName,
                                remarks: [
                                  {
                                    remark: log?.remark,
                                    service: log?.serviceName,
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      ))}{" "}
                    </div>
                  </div>
                ))}
            </>
          )}
          {servicesType === "Detail View" && (
            <>
              <div className="grid1">
                <div className="tableElement colSpan1">S.No</div>
                <div className="tableElement colSpan2">Patient Name</div>
                <div className="tableElement colSpan1">Facility Name</div>
                <div className="tableElement colSpan1"> Physician</div>
                <div className="tableElement colSpan1">
                  {`Primary ${filter?.programName} Clinician`}
                </div>
                <div className="tableElement colSpan1">Service Name</div>
                <div className="tableElement colSpan1">Service Type</div>
                <div className="tableElement colSpan1">Service Date & Time</div>
                <div className="tableElement colSpan1">Performed By</div>
                <div className="tableElement colSpan1">
                  Time Spent (HH:MI:SS)
                </div>
                <div className="tableElement colSpan1"> Action</div>
                <div className="tableElement colSpan1"> </div>

                {/* <div className="tableElement colSpan1"></div> */}
              </div>
              {filteredServiceData &&
                filteredServiceData?.map((elem, index) => (
                  <div className="grid1">
                    <div className="tableBodyElement colSpan1">{index + 1}</div>
                    <div className="tableBodyElement colSpan2">
                      {elem?.patientInfo?.PatientName}
                    </div>
                    <div className="tableBodyElement colSpan1">
                      {elem?.patientInfo?.facilityInfo?.FacilityName}
                    </div>
                    <div className="tableBodyElement colSpan1">
                      {elem?.patientInfo?.providerInfo?.ProviderName}
                    </div>
                    <div className="tableBodyElement colSpan1">
                      {elem?.patientInfo?.coordinatorInfo?.CoordinatorName}
                    </div>
                    <div className=" colSpan6">
                      {elem?.services?.map((log) => (
                        <div className="grid3">
                          <div className="subComponent colSpan1">
                            {log?.serviceName}
                          </div>
                          <div className="subComponent colSpan1">
                            {log?.serviceType}
                          </div>
                          <div className="subComponent colSpan1">
                            {utcToNormalDateTime(log?.startTime)}
                          </div>
                          <div className="subComponent colSpan1">
                            {log?.serviceProviderProfile?.FullName}
                          </div>
                          <div className="subComponent colSpan1">
                            {log?.acutalTimeSpent}
                          </div>
                          <div className="subComponentBtn colSpan1">
                            <Icon
                              icon="uil:edit"
                              width="21"
                              height="21 "
                              className="icons"
                              onClick={() => onClickEdit(log, elem)}
                            />
                            {/* <Icon
                              icon="material-symbols:delete-outline"
                              width="24"
                              height="24"
                              className="icons"
                              onClick={() => onClickDelete(log)}
                            /> */}
                          </div>
                        </div>
                      ))}
                      <div className="totalcpmd">
                        <p className="total colSpan4">Total</p>
                        <p className="totalTime colSpan2">
                          {elem?.totalTimeSpent}
                        </p>
                      </div>
                    </div>
                    <div className="tableBodyElementAction colSpan1">
                      <Icon
                        icon="icon-park:plus"
                        width="24"
                        height="24"
                        className="icon"
                        onClick={() =>
                          openfn(
                            elem?.PatientId,
                            elem?.patientInfo?.PatientName
                          )
                        }
                      />{" "}
                      {/* <Icon
                        icon="ic:round-call"
                        width="24"
                        height="24"
                        className="icon"
                      /> */}
                      <input
                        type="checkbox"
                        className="icon"
                        onClick={(event) => handleCheckBox(event, elem)}
                      />
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Billing;
