import { apiCollection } from "./apiCollection";

const dashboardApi = apiCollection.injectEndpoints({
  endpoints: (build) => ({
    dashboardApi: build.mutation({
      query: (payload) => {
        return { url: "dashboard/alldata", method: "POST", body: payload };
      },
      transformErrorResponse: (response, meta, arg) => response.data.message,
    }),
  }),
  overrideExisting: false,
});

export const { useDashboardApiMutation } = dashboardApi;
